import {Divider} from "../../../../stories/components/Divider/src";
import React from "react";
import {ThemeSection} from "../Section";

export function AppearanceWindow() {
    return (
        <div>
            <ThemeSection/>
            <Divider className={'my-4'}/>
        </div>
    )
}