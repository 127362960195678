import React, {ExoticComponent, ReactNode, useState} from "react";
import Modal from "react-modal";
import classNames from "classnames";
import UserAvatar from "../../UserAvatar";
import {Heading, Text} from "../../../stories/components/Text/src";
import {BsChevronRight} from "react-icons/bs";
import {ModalHeader} from "../ModalHeader";
import styles from "./index.module.scss"
import {Divider} from "../../../stories/components/Divider/src";
import BaseModal, {BaseModalProps} from "../BaseModal";
import SimpleScroll from "../../SimpleScroll";

type NavItem = {
    label: string,
    icon: ReactNode,
    window: ReactNode,
    windowClass?: string | string[] | undefined
}
type NavSection = {
    label?: string,
    list: NavItem[]
}

interface Props extends BaseModalProps {
    label?: string
    navData: NavSection[]
}

const NavSection = ({section, index: sectionIndex, onChangeNav, activedIndex}: { section: NavSection; index: number; activedIndex: string; onChangeNav: (index: string) => void; }) => {

    return (
        <div>
            {
                section?.label && (
                    <div className={styles.navSectionLabel}>{section.label}</div>
                )
            }
            {
                section.list.map((item, index) => {
                    const isActived = activedIndex === `${sectionIndex},${index}`

                    return (
                        <div
                            key={index}
                            className={classNames(styles.navItem, isActived && styles.actived)}
                            onClick={() => onChangeNav(`${sectionIndex},${index}`)}
                        >
                            <div className={styles.icon}>{item.icon}</div>
                            <div>{item.label}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export const NavModal = ({
                             label = '',
                             isOpen = false,
                             onRequestClose,
                             navData = [],
                             ...props
                         }: Props) => {
    const [activedIndex, setActivedIndex] = useState('0,0')   //  activedIndex='sectionIndex,itemIndex'
    const sectionIndex = activedIndex.split(',')[0],
        itemIndex = activedIndex.split(',')[1]
    const ActivedItem = navData[parseInt(sectionIndex)].list[parseInt(itemIndex)]

    return (
        <>
            <BaseModal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                className={styles.NavModal}
                contentCenter
                transition={'ease-in-out'}
                {...props}
            >
                <div className={styles.LeftNavBar}>
                    <Heading className={'p-5'} size={'lg'}>{label}</Heading>
                    <div className={styles.NavBox}>
                        {
                            navData.map((section, index) =>
                                <NavSection
                                    key={index}
                                    index={index}
                                    section={section}
                                    activedIndex={activedIndex}
                                    onChangeNav={setActivedIndex}
                                />
                            )
                        }
                    </div>
                </div>
                <div className={styles.Main}>
                    <ModalHeader onRequestClose={onRequestClose}/>
                    <SimpleScroll className={classNames(styles.Body, ActivedItem.windowClass || 'px-10')}>
                        {ActivedItem.window}
                    </SimpleScroll>
                </div>
            </BaseModal>
        </>
    )
}