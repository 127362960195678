import React from "react";
import {TabBar, TabBarItem, TabPanels, Tabs} from "../../../../stories/components/Tabs/src";

export function BillingWindow() {
    return (
        <div>
            <Tabs>
                <TabBar>
                    <TabBarItem>Balance</TabBarItem>
                    <TabBarItem>Invoices</TabBarItem>
                </TabBar>
                <TabPanels>

                </TabPanels>
            </Tabs>
        </div>
    )
}