import axiosInstance from "../lib/axios";

export const list = async (data) => {
    return await axiosInstance.post('/v1/workspace/list', data)
}

export const view = async (data) => {
    return await axiosInstance.post('/v1/workspace/view', data)
}

export const messages = async (data) => {
    return await axiosInstance.post('/v1/workspace/messages', data)
}

export const create = async (data) => {
    return await axiosInstance.post(`/v1/workspace/create`, data)
}