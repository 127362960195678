import React, {FormEvent, useEffect, useState} from "react";
import {ModalHeader} from "../ModalHeader";
import BaseModal, {BaseModalProps} from "../BaseModal";
import styles from "../CreateFolderModal/index.module.scss";
import $api from "../../../api";
import ConfirmPasswordForm from "../ConfirmLoginPasswordModal/ConfirmPasswordForm";
import {motion} from "framer-motion";
import {QRCodeSVG} from "qrcode.react";
import {Spinner} from "../../../stories/components/Spinner/src";
import {BiError} from "react-icons/bi";
import {Input} from "../../../stories/components/Input/src";
import {Button} from "../../../stories/components/Button/src";
import {toast} from "react-hot-toast";
import {useSelector, useDispatch} from "react-redux";
import {setTwoFactor} from "../../../store/reducers/user";
import {BsFillCheckCircleFill, BsFillInfoCircleFill} from "react-icons/bs";
import CopyToClipboard from "react-copy-to-clipboard";

const CONTENT_TYPE = {
    CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
    SETUP_TWO_FACTOR_AUTH: 'SETUP_TWO_FACTOR_AUTH',
    SUCCESS_AND_DISPLAY_RECOVERY_CODES: 'success_and_display_recovery_codes',
}

const CONTENT_LABEL = {
    [CONTENT_TYPE.CONFIRM_PASSWORD]: 'Confirm your password',
    [CONTENT_TYPE.SETUP_TWO_FACTOR_AUTH]: 'Set Up Two-Factor Authentication',
    [CONTENT_TYPE.SUCCESS_AND_DISPLAY_RECOVERY_CODES]: 'Two-Factor Authentication Backup Codes',
}

function SetupTwoFactorAuth({onSuccess, ...props}) {
    const [codeVerifying, setCodeVerifying] = useState(false)
    const [code, setCode] = useState('')
    const [codeErrorMsg, setCodeErrorMsg] = useState('')
    const [qrCodeUrl, setQrCodeUrl] = useState('')
    const [qrCodeStatus, setQrCodeStatus] = useState('')
    const dispatch = useDispatch()
    const onLoadQrCode = async () => {
        try {
            setQrCodeStatus('loading')
            await $api.user.enableTwoFactorAuthentication()
            const res = await $api.user.getTwoFactorAuthenticationQrCode()
            if (!res.url) {
                throw new Error('Failed to load QR code')
            }
            setQrCodeStatus('success')
            setQrCodeUrl(res.url)
        } catch (e) {
            setQrCodeStatus('failed')
        }
    }

    useEffect(() => {
        onLoadQrCode()
    }, [])

    const onVerifyCode = async event => {
        event.preventDefault()
        if (!code || code.length !== 6) {
            setCodeErrorMsg('Invalid two factor code')
            return
        }
        setCodeErrorMsg('')
        setCodeVerifying(true)
        try {
            await $api.user.confirmTwoFactorAuthentication(code)
            const recoveryCodes = await $api.user.getTwoFactorAuthenticationRecoveryCodes()
            onSuccess && onSuccess(recoveryCodes)
            dispatch(setTwoFactor(true))
        } catch (e) {
            setCodeErrorMsg('Invalid two factor code')
        }
        setCodeVerifying(false)
    }

    const onCodeInputChange = event => {
        setCode(event.target.value)
    }

    return (
        <div className={'max-w-[700px] p-8 pb-14 flex gap-8'}>
            <div
                className={'w-[220px] h-[220px] shrink-0 bg-gray-200 flex items-center justify-center rounded'}>
                {qrCodeStatus === 'loading' && (
                    <Spinner size={'sm'} colorScheme={'secondary'}/>
                )}
                {qrCodeStatus === 'success' && (
                    <QRCodeSVG
                        value={qrCodeUrl}
                        size={220}
                    />
                )}
                {qrCodeStatus === 'failed' && (
                    <div className={'flex flex-col items-center p-2'}>
                        <BiError className={'text-rose-600 text-3xl mb-1'}/>
                        <div className={'text-center'}>
                            QR code loading failed, please <button onClick={onLoadQrCode}
                                                                   className={'text-sky-700 cursor-pointer hover:underline'}>try
                            again</button>
                        </div>
                    </div>
                )}
            </div>
            <div>
                <h5>Scan this Barcode</h5>
                Download and install apps like
                <b> Google Authenticator</b>,<b> Microsoft Authenticator </b>etc. on your phone or
                tablet.

                <div className={'mt-2'}>Open the authentication app and:</div>
                <ul className={'list-disc'}>
                    <li>
                        Tap the "+" icon in the top-right of the app
                    </li>
                    <li>
                        Scan the image to the left, using your phone's camera
                    </li>
                </ul>
                <form onSubmit={onVerifyCode} className={'flex gap-2'}>
                    <div className={`w-[150px]`}>
                        <Input
                            type={'text'}
                            name={'code'}
                            placeholder={'000000'}
                            maxLength={6}
                            onChange={onCodeInputChange}
                            value={code}
                        />
                    </div>
                    <Button type={'submit'}
                            loading={codeVerifying}
                    >
                        <div className={'text-bold'}>
                            Verify and Active
                        </div>
                    </Button>
                </form>
                {codeErrorMsg && (
                    <div className={'text-rose-600 p-1 text-sm'}>
                        Invalid code
                    </div>
                )}
            </div>
        </div>
    )
}

function DisplayRecoveryCodes({recoveryCodes, ...props}) {

    const {email} = useSelector((state) => state.user);

    const onDownloadCodes = () => {
        const AppName = process.env.REACT_APP_NAME.toLowerCase()
        const content = `These are your ${process.env.REACT_APP_NAME} recovery codes for account ${email}.  Keep them safe!\n\n` + recoveryCodes.join('\n');
        const fileName = `${AppName}_recovery_codes.txt`;
        const contentType = "text/plain";
        const a = document.createElement("a");
        const file = new Blob([content], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(a.href);
    }

    return (
        <div className={'w-[500px] px-8 flex flex-col gap-2'}>
            <div className="bg-green-100 flex flex-row text-green-700 px-2 py-3 rounded" role="alert">
                <div className="font-bold text-green-600 text-xl px-2">
                    <BsFillCheckCircleFill/>
                </div>
                <div className="flex-1 px-2 sm:inline">
                    Two-factor authentication is turned on.
                </div>
            </div>
            <div className="bg-stone-200 flex flex-row text-gray-700 px-2 py-3 rounded" role="alert">
                <div className="font-bold text-stone-600 text-xl px-2">
                    <BsFillInfoCircleFill/>
                </div>
                <div className="flex-1 px-2 sm:inline">
                    If you lose access to your authentication device, you can use one of the backup codes below to log
                    in to your account. Each code can only be used once. Copy these codes and store them in a safe
                    place.
                </div>
            </div>
            <div className={'flex flex-col items-center gap-4 py-4 pb-12'}>
                <div className={'p-2 font-semibold text-lg w-max border rounded'}>
                    {
                        recoveryCodes.map(code => (
                            <div key={code} className={'px-2 text-center'}>
                                {code}
                            </div>
                        ))
                    }
                </div>
                <div className={'flex flex-row gap-4'}>
                    <Button colorScheme={'secondary'} onClick={onDownloadCodes}>
                        Download Codes
                    </Button>
                    <CopyToClipboard text={recoveryCodes.join('\n')}
                                     onCopy={() => toast.success('Copied to clipboard')}>
                        <Button colorScheme={'secondary'}>
                            Copy Codes
                        </Button>
                    </CopyToClipboard>
                </div>
            </div>
        </div>
    )
}

export default function SetupTwoFactorAuthenticationModal({
                                                               ...props
                                                           }) {
    const [contentType, setContentType] = useState(CONTENT_TYPE.SETUP_TWO_FACTOR_AUTH)
    const [recoveryCodes, setRecoveryCodes] = useState('')

    const onConfirmedPassword = () => {
        setContentType(CONTENT_TYPE.SETUP_TWO_FACTOR_AUTH)
    }

    const onVerified = (recoveryCodes) => {
        setRecoveryCodes(recoveryCodes)
        setContentType(CONTENT_TYPE.SUCCESS_AND_DISPLAY_RECOVERY_CODES)
    }


    return (
        <BaseModal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            className={''}
        >
            <div className={'h-max max-h-80vh min-w-[200px]'}>
                <ModalHeader label={CONTENT_LABEL[contentType]}
                             onRequestClose={props.onRequestClose}/>
                {contentType === CONTENT_TYPE.CONFIRM_PASSWORD && (
                    <div className={'w-[400px]'}>
                        <ConfirmPasswordForm onConfirmed={onConfirmedPassword}/>
                    </div>
                )}

                {contentType === CONTENT_TYPE.SETUP_TWO_FACTOR_AUTH && (
                    <SetupTwoFactorAuth onSuccess={onVerified}/>
                )}

                {contentType === CONTENT_TYPE.SUCCESS_AND_DISPLAY_RECOVERY_CODES && (
                    <DisplayRecoveryCodes recoveryCodes={recoveryCodes}/>
                )}
            </div>
        </BaseModal>
    )
}