import React, {useState} from "react";
import styles from "../index.module.scss";
import {Button} from "../../../../stories/components/Button/src";
import $api from "../../../../api";
import {useDispatch, useSelector} from "react-redux";
import {FiLock} from "react-icons/fi";
import SetupTwoFactorAuthenticationModal from "../../SetupTwoFactorAuthenticationModal";
import {toast} from "react-hot-toast";
import {setTwoFactor} from "../../../../store/reducers/user";

export function TwoFactorAuthenticationSection() {
    const [expand, setExpand] = useState(false)
    const [isDisabling, setIsDisabling] = useState(false)
    const [showSetupModal, setShowSetupModal] = useState(false)
    const dispatch = useDispatch()
    const {enabled_two_factor} = useSelector((state) => state.user);

    const onDisable2FA = async () => {
        setIsDisabling(true)
        try {
            await $api.user.disableTwoFactorAuthentication()
            dispatch(setTwoFactor(false))
            toast.success('Two-factor authentication is turned off')
        } catch (e) {
            toast.error('operation failed')
        }
        setIsDisabling(false)
    }

    return (
        <>
            <div className={styles.ItemRow}>
                <div>
                    <div className={styles.ItemLabel}>
                        Two-Factor Authentication
                    </div>
                    {enabled_two_factor ? (
                        <div className={'text-green-700 flex flex-row items-center gap-1 mb-2'}>
                            <FiLock/>
                            Active
                        </div>
                    ) : (
                        <div className={styles.SubLabel}>
                            Two-Factor Authentication can help protect your account from unauthorized access by
                            requiring
                            you to enter an additional code when you sign in.
                        </div>
                    )}
                </div>
                <Button colorScheme={'secondary'} variant={'outline'} size={'sm'} onClick={() => setExpand(!expand)}>
                    {expand ? 'Collapse' : 'Expand'}
                </Button>
            </div>

            {expand && (
                <div className={'mb-6'}>
                    {enabled_two_factor ? (
                        (
                            <Button colorScheme={'secondary'}
                                    variant={'outline'}
                                    size={'sm'}
                                    loading={isDisabling}
                                    onClick={onDisable2FA}
                            >
                                Disable Two-Factor Authentication
                            </Button>
                        )
                    ) : (
                        <Button colorScheme={'secondary'} variant={'outline'} size={'sm'}
                                onClick={() => setShowSetupModal(true)}>
                            Set Up Two-Factor Authentication
                        </Button>
                    )}
                </div>
            )}
            {showSetupModal&&(
                <SetupTwoFactorAuthenticationModal
                    isOpen={showSetupModal}
                    onRequestClose={() => setShowSetupModal(false)}
                />
            )}
        </>
    )
}