import React, {HTMLAttributes} from "react";
import classNames from "classnames";

interface TabBarItemProps extends HTMLAttributes<HTMLDivElement> {
    colorScheme?: "grey" | 'primary' | 'info' | 'danger' | 'warning'
    size?: 'sm' | 'md' | 'lg'
    variant?: 'solid' | 'outline' | 'line'
    value?: number | string | undefined | null
    children?: any;
    selected?: boolean

    [propName: string]: any
}

export const TabBarItem = ({
                               colorScheme = "primary",
                               size = 'md',
                               value = '',
                               variant = 'line',
                               selected = false,
                               ...props
                           }: TabBarItemProps) => {
    const cls = classNames('TabItem', `size-${size}`, variant+'-'+colorScheme, selected && 'selected', props.className)

    return (
        <div {...props} className={cls}>
            <div className={'content'}>
                {props.children}
            </div>
            <div className={'indicator'}/>
        </div>
    )
}