import React from "react";
import classNames from "classnames";
import {BsCheck,BsCheck2} from "react-icons/bs";
import styles from "./index.module.scss";

interface Props {
    isDone?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl'

}

export default function AsDoneIcon({isDone = false, size = 'md', ...props}: Props) {
    return (
        <div className={classNames(styles.AsDoneIcon, styles['size-' + size],isDone&&styles.isDone)} {...props}>
            <BsCheck2/>
        </div>
    )
}