import React, {useState} from "react";
import Dropdown from "rc-dropdown/es";
import {Button, IconButton} from "../../stories/components/Button/src";
import classNames from "classnames";
import {BsPlus, BsChevronDown, BsUpload, BsSearch, BsSortDown, BsList, BsGrid, BsArrowClockwise} from "react-icons/bs";
import Menu, {Item as MenuItem} from "rc-menu/es";
import {Divider} from "../../stories/components/Divider/src";
import styles from "./index.module.scss"
import {FileIcon} from "../FileIcon";
import {Row} from "../../stories/components/Layout/src";
import {Input} from "../../stories/components/Input/src";
import CreateButton from "./CreateButton";
import UploadButton from "./UploadButton";
import SortButton from "./SortButton";
import DisplayButton from "./DisplayButton";
import DocumentSpacePathBar from "../DocumentSpacePathBar";
import CreateFolderModal from "../Modal/CreateFolderModal";
import DocumentSearchModal from "../Modal/DocumentSearchModal";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import {SearchInput} from "../ui";


const BtnSpace = () => (
    <div style={{width: '15px'}}/>
)

const PathItems = [
    {id: -1, name: 'Team Space'},
    {id: 0, name: 'Client Folders'},
    {id: 1, name: 'XNF836B5NG'},
    {id: 2, name: '2022 Tax Return 2022 Tax Return 2022 Tax Return'},
]

function SearchBtn() {
    const [showSearchModal, setShowSearchModal] = useState(false)

    function onSwitchSearchModal() {
        setShowSearchModal(!showSearchModal)
    }

    return (
        <>
            <SearchInput
                placeholder={'Filter name'}
            />
            <DocumentSearchModal
                isOpen={showSearchModal}
                onRequestClose={onSwitchSearchModal}
            />
        </>
    )
}

export default function DocumentSpaceActionRow({className, ...props}) {
    return (
        <div className={classNames('p-6 pb-2', styles.DocumentSpaceActionRow, className)} {...props}>
            <Row className={'justify-between'}>
                <Row gap={3}>
                    <CreateButton/>
                    <UploadButton/>
                </Row>
                <Row gap={3}>
                    <SortButton/>
                    <DisplayButton/>
                </Row>
            </Row>
            <Row className={'justify-between my-4'}>
                <Row>
                    <DocumentSpacePathBar items={PathItems} size={'lg'}/>
                    <IconButton size={'sm'} colorScheme={'secondary'} variant={'ghost'} title={'刷新当前列表'}>
                        <BsArrowClockwise style={{width: '16px', height: '16px'}}/>
                    </IconButton>
                </Row>
                <SearchBtn/>
            </Row>
        </div>
    )
}