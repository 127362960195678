import React, {useState} from "react";
import {ListHeader} from "./ListHeader";
import {BsFillCaretRightFill, BsFillCaretDownFill, BsChevronRight, BsChevronDown} from "react-icons/bs";
import FolderIcon from "../FileIcon/FolderIcon";
import {Tree} from 'antd';
import {Col, Row} from "../../stories/components/Layout/src";
import {FileIcon} from "../FileIcon";
import classNames from "classnames";
import FileItemContextMenu from "./FileItemContextMenu";
import {useContextMenu} from "react-contexify";
import "./index.scss"

const FILE_ITEM_CONTEXT_MENU_ID = 'FileItemContextMenu'

const ExpendIndentWidth=24

const initTreeData = [
    {id: '0', name: 'Expand to load 1', size: 0, isFolder: true,},
    {id: '1', name: 'Expand to load 2', size: 0, isFolder: true,},
    {id: '2', name: 'Expand to load 2', size: 0, isFolder: true,},
    {id: '3', name: 'Expand to load 2', size: 0, isFolder: true,},
    {id: '4', name: 'Expand to load 2', size: 0, isFolder: true,},
    {id: '5', name: 'Expand to load 2', size: 0, isFolder: true,},
    {id: '6', name: 'example.docx', size: 18464, isFolder: false, isLeaf: true,},
    {id: '7', name: 'example.xlsx', size: 18464, isFolder: false, isLeaf: true,},
    {id: '8', name: 'example.pdf', size: 18464, isFolder: false, isLeaf: true,},
];

const initColsWidth = {
    name: (window.innerWidth * 0.4),    //  默认name列的宽度是屏幕的40%
    size: 200,
    modified_at: 200
}

const updateTreeData = (list, key, children) =>
    list.map((node) => {
        if (node.id === key) {
            return {
                ...node,
                children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    });

const NodeIcon = ({data, ...props}) => {
    if (data.isFolder) {
        return props.expanded ? <FolderIcon/> : <FolderIcon/>
    }
    return <FileIcon filename={data.name}/>
}

const SwitcherIcon = obj => {
    if (obj.isLeaf) {
        return null
    }
    return obj.expanded ? <BsChevronDown/> : <BsChevronRight/>
}

export default function FileList({
                                     height,
                                     ...props
                                 }) {
    const [checkedKeys, setCheckedKeys] = useState([])
    const [treeData, setTreeData] = useState(initTreeData)
    const [colsWidth, setColsWidth] = useState(initColsWidth)
    const {show: onShowContextMenu} = useContextMenu({
        id: FILE_ITEM_CONTEXT_MENU_ID
    });
    const onSelect = (keys, info) => {
        const key = info.node.key
        if (info.nativeEvent.ctrlKey) {
            console.log(checkedKeys)
            let index = checkedKeys.indexOf(key)
            const newCheckedKeys = [...checkedKeys]
            if (index > -1) {
                newCheckedKeys.splice(index, 1)
            } else {
                newCheckedKeys.push(key)
            }
            setCheckedKeys(newCheckedKeys)
        } else {
            setCheckedKeys([key])
        }
        console.log('onSelect', key)
    };

    const onCheck = ({checked, halfChecked}) => {
        setCheckedKeys(checked)
    };

    const onLoadChildData = (node) => {
        const {key, children} = node
        const level = node.level ? node.level + 1 : 1

        return new Promise((resolve) => {
            if (children) {
                resolve();
                return;
            }
            setTimeout(() => {
                setTreeData((origin) =>
                    updateTreeData(origin, key, [
                        {
                            id: `${key}-2`,
                            name: 'Expand Folder',
                            size: 0,
                            isFolder: true,
                            isLeaf: false,
                            level,
                        },
                        {id: `${key}-3`, name: 'Expand', size: 0, isFolder: true, isLeaf: false, level,},
                        {
                            id: `${key}-0`,
                            name: 'Expand to load 1.xlsx',
                            size: 18464,
                            isFolder: false,
                            isLeaf: true,
                            level,
                        },
                        {
                            id: `${key}-1`,
                            name: 'Expand to load 2.txt',
                            size: 18464,
                            isFolder: false,
                            isLeaf: true,
                            level,
                        },
                    ]),
                );
                resolve();
            }, 1000);
        })
    }

    const NodeItem = (nodeData) => {

        const {name, size, modified_at, level} = nodeData

        const indentWidth = nodeData.level ? nodeData.level * ExpendIndentWidth : 0
        let nameWidth = colsWidth.name - indentWidth - 61
        if (nameWidth < 0) nameWidth = 0

        return (
            <Row>
                <Col className={classNames('nameCell')}
                     style={{width: nameWidth + 'px'}}>
                    <a href="">{name}</a>
                </Col>
                <Col className={classNames('modifiedCell')}
                     style={{width: colsWidth.modified_at + 'px'}}>2023-02-23 19:00</Col>
                <Col className={classNames('sizeCell')}
                     style={{width: colsWidth.size + 'px'}}>{size} MB</Col>
            </Row>
        )
    }

    const onRightClick = ({event, node}) => {
        if (!node.checked) {
            setCheckedKeys([node.key])
        }
        onShowContextMenu({
            event,
        })
    }

    return (
        <div className={classNames('px-6 fileList')} style={{height}}>
            <ListHeader
                colsWidth={colsWidth}
                onChangeColsWidth={setColsWidth}
            />
            <Tree
                rootClassName={'mexui-file-tree'}
                // height={300}
                fieldNames={{title: 'name', key: 'id', children: 'children'}}
                loadData={onLoadChildData}
                treeData={treeData}
                titleRender={NodeItem}
                switcherIcon={SwitcherIcon}
                showIcon
                icon={NodeIcon}
                blockNode
                checkable
                checkStrictly
                draggable
                selectable
                onSelect={onSelect}
                checkedKeys={checkedKeys}
                onCheck={onCheck}
                onRightClick={onRightClick}
            />

            <FileItemContextMenu
                id={FILE_ITEM_CONTEXT_MENU_ID}
            />
        </div>
    )
}