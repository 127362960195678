import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import UserAvatar from "../../UserAvatar";
import {Heading, Text} from "../../../stories/components/Text/src";
import {BsChevronRight} from "react-icons/bs";
import {ModalHeader} from "../ModalHeader";
import {NavModal} from "../NavModal";
import {FiUsers} from "react-icons/fi";
import {BsGear, BsCreditCard, BsFolder2, BsGrid} from "react-icons/bs";
import {AppConnectionWindow, BillingWindow, DocumentWindow, MemberManageWindow, SettingWindow} from "./Window";
import {SvgIcon} from "../../../stories/components/SvgIcon/src";
import {useSelector} from "react-redux";

const NavData = [
    {
        label: '',
        list: [
            {
                label: '团队设置',
                icon: <SvgIcon icon={BsGear} size={18}/>,
                window: <SettingWindow/>,
            },
            {
                label: '成员管理',
                icon: <SvgIcon icon={FiUsers} size={18}/>,
                window: <MemberManageWindow/>,
                windowClass: 'px-0'
            },
            {
                label: '文档管理',
                icon: <SvgIcon icon={BsFolder2} size={18}/>,
                window: <DocumentWindow/>,
            },
            {
                label: '账单',
                icon: <SvgIcon icon={BsCreditCard} size={18}/>,
                window: <BillingWindow/>,
            },
            {
                label: '应用连接',
                icon: <SvgIcon icon={BsGrid} size={18}/>,
                window: <AppConnectionWindow/>,
            },

        ]
    },
]

export const TeamSettingModal = ({isOpen = false, onAfterOpen, onRequestClose, ...props}) => {
    const team = useSelector((state) => state.team);

    return (
        <>
            <NavModal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                label={team.name}
                navData={NavData}
            />
        </>
    )
}