import Dropdown from "rc-dropdown/es";
import {Button} from "../../../stories/components/Button/src";
import classNames from "classnames";
import {BsChevronDown, BsGrid, BsList} from "react-icons/bs";
import React from "react";
import Menu, {Item as MenuItem} from "rc-menu/es";


const DisplayMenu = ()=>{

    function onMenuClick({key}: { key: any }) {
        console.log(`${key} selected`);
    }

    return (
        <Menu onSelect={onMenuClick} style={{width: '150px'}}>
            <MenuItem eventKey={'list'}>
                <BsList style={{width: '20px', height: '20px'}}/>
                <div className={'label'}>列表</div>
            </MenuItem>
            <MenuItem eventKey={'grid'}>
                <BsGrid style={{width: '16px', height: '16px', marginLeft: '2px', marginRight: '3px'}}/>
                <div className={'label'}>平铺</div>
            </MenuItem>
        </Menu>
    );
}

export default function DisplayButton(){
    return (
        <Dropdown
            trigger={['click']}
            overlay={DisplayMenu}
        >
            <Button colorScheme={'light'}
                    variant={'outline'}>
                <BsList style={{width: '20px', height: '20px', marginRight: '5px'}}/>
                <BsChevronDown style={{width: '15px', height: '15px'}}/>
            </Button>
        </Dropdown>
    )
}