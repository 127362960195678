import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import UserAvatar from "../components/UserAvatar";
import SimpleScroll from "../components/SimpleScroll";
import {FixedSizeList} from "react-window";
import {useWindowSize} from "react-use";
import {useDispatch, useSelector} from "react-redux";
import ContentLoader from "react-content-loader";
import {CloseButton, SearchInput} from "../components/ui";
import {fetchTeammates, setDetailId, setSearch} from "../store/reducers/teammate";
import {fetchWorkspaces} from "../store/reducers/workspace";
import {Transition} from "@headlessui/react";
import {IoMailOutline} from "react-icons/io5";

const DATA = [
    {id: 0, name: 'Zell Zhu', email: 'zell.zhu@mexgroup.com.au'},
    {id: 1, name: 'Fred Xu', email: 'fred.xu@mexgroup.com.au'},
    {id: 2, name: 'Yoyo.Yu', email: 'youyo@mexgroup.com.au'},
    {id: 3, name: 'Max.Gao', email: 'max.gao@mexgroup.com.au'},
]

function Empty() {
    return (
        <div className={'flex flex-col gap-2 items-center py-16'}>
            <div className={'text-lg font-semibold'}>No results</div>
            <div>You may want to try adjusting your filters.</div>
        </div>
    )
}

function ListSkeleton({}) {
    return (
        <div className={'px-6'}>
            {
                Array.from({length: 5}).map((_, index) => (
                    <div key={index} className={'h-[56px] px-4 border-b'}>
                        <ContentLoader viewBox={`0 0 300 56`} height={56} width={300}>
                            <rect x={0} y="12" rx={5} width={32} height={32}/>
                            <rect x={44} y="18" rx="5" width={200} height={20}/>
                        </ContentLoader>
                    </div>
                ))
            }
        </div>
    )
}

function ListItem({index, style}) {
    const dispatch = useDispatch()
    const {data} = useSelector(state => state.teammate)

    const teammate = data[index]

    return (
        <div className={'px-6'} style={style}>
            <div
                className={'h-full w-full block flex items-center transition-colors border-b px-4 hover:bg-stone-100 hover:dark:bg-stone-800 cursor-pointer'}
                onClick={() => dispatch(setDetailId(teammate.id))}>
                <UserAvatar id={teammate.id} name={teammate.name} src={teammate.avatar_url} size={'sm'}/>
                <div className={'px-3 font-semibold'}>
                    {teammate.name}
                </div>
            </div>
        </div>
    );
}

function Profile({
                     show = false,
                 }) {
    const dispatch = useDispatch()
    const {data: teammates, detailId} = useSelector(state => state.teammate)

    const profile = teammates.find(item => item.id === detailId)

    const ProfileItem = ({icon, label, value, link}) => (
        <div className={'px-6 py-3 flex items-center gap-3 font-medium'}>
            <div className={'w-9 h-9 flex justify-center items-center bg-gray-100 rounded'}>
                {icon}
            </div>
            <div>
                <div className={'mb-0.5 text-gray-600 select-none'}>{label}</div>
                {link ? (
                    <a href={`mailto:${value}`}
                       className={'text-sky-700 hover:underline cursor-pointer'}>{value}</a>
                ) : (
                    <div>{value}</div>
                )}
            </div>
        </div>
    )

    return (
        <Transition
            show={show}
            enter="transition-all duration-150"
            enterFrom="w-0"
            enterTo="w-96"
            leave="transition-all duration-100"
            leaveFrom="w-96"
            leaveTo="w-0"
        >
            {profile && (
                <div className={'w-96 border-l h-[100vh]'}>
                    <div className={'p-2 flex items-center justify-between'}>
                        <div className={'font-semibold px-2'}>Profile</div>
                        <CloseButton onClick={() => dispatch(setDetailId(''))}/>
                    </div>

                    <UserAvatar
                        id={profile.id}
                        name={profile.name}
                        src={profile.avatar_url}
                        size={'custom'}
                        className={'w-64 h-64 rounded-[20px] text-[150px] mx-auto my-2'}
                    />

                    <div className={'px-6 py-2 flex flex-col items-center'}>
                        <div className={'font-semibold text-xl'}>{profile.name}</div>
                    </div>

                    <ProfileItem
                        icon={<IoMailOutline className={'text-xl'}/>}
                        label={'Email Address'}
                        value={profile.email}
                        link={profile.email}
                    />

                </div>
            )}
        </Transition>
    )
}

export default function Teammate() {
    const {data: teammates, status, errMsg, pagination, search, detailId} = useSelector(state => state.teammate)
    const dispatch = useDispatch()

    const onSearchInputChange = (value) => {
        dispatch(setSearch(value))
        if (!value) {
            dispatch(fetchTeammates())
        }
    }

    const onSearch = () => {
        dispatch(fetchTeammates())
    }


    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchTeammates())
        }
    }, []);


    const {height: windowHeight} = useWindowSize();

    return (
        <>
            <div className={'flex overflow-hidden'}>
                <div className={'h-full flex-1'}>
                    <div className={`mx-6 border-b`}>
                        <div
                            className={classNames(`h-16 flex items-center justify-between`)}>
                            <div className={'font-semibold flex items-center'}>
                                Teammates
                            </div>
                        </div>
                        <div className={'pt-2 pb-6'}>
                            <SearchInput
                                variant={'filled'}
                                value={search}
                                onSearch={onSearch}
                                onChangeValue={onSearchInputChange}
                                placeholder={'Search teammate'}
                            />
                        </div>
                    </div>
                    {status === 'loading' && (<ListSkeleton/>)}
                    {status === 'success' && (!teammates.length ? (<Empty/>) : (
                        <FixedSizeList
                            height={windowHeight - 56}
                            itemCount={teammates.length}
                            itemSize={56}
                            style={{height: `calc(100vh - 200px)`, overflowX: 'hidden'}}
                        >
                            {ListItem}
                        </FixedSizeList>

                    ))}
                </div>
                <Profile show={detailId !== ''}/>
            </div>
        </>
    )
}