import React, {Fragment, useMemo} from "react"
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

export interface TextProps extends React.HTMLAttributes<HTMLElement> {
    children?: any;
    markText?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
    line?: number

    [propName: string]: any;
}

export const Text = ({
                         children = '',
                         markText = '',
                         size = 'md',
                         color = '',
                         line = 0,
                         className,
                         ...props
                     }: TextProps) => {
    const classes = [`${UIPrefix}text`, `text-${size}`, line === 1 && 'omitted']
    return (
        <div {...props} className={classNames(classes, className)} style={{...props.style,color}}>
            {children}
        </div>
    )
}
