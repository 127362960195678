import {Button} from "../../stories/components/Button/src";
import React from "react";

export function SubmitButton({type = 'submit', disabled, loading, ...props}) {

    return (
        <Button
            type={type}
            colorScheme={disabled ? 'info' : 'primary'}
            disabled={disabled || loading}
            loading={loading} {...props}
        />
    )
}