import React from "react";
import {ReactComponent as CompanySvg} from "../../assets/company.svg";
import classNames from "classnames";
import "./index.scss";
import {Avatar, AvatarProps} from "../../stories/components/Avatar/src";
import {RiBuilding2Fill} from "react-icons/ri";

const SIZE_CLS = {
    xs: 'w-6 h-6 rounded-sm',
    sm: 'w-8 h-8 rounded-sm',
    md: 'w-12 h-12 rounded',
    lg: 'w-16 h-16 rounded-lg',
    xl: 'w-20 h-20 rounded-xl',
    '2xl': 'w-24 h-24 rounded-2xl',
    '3xl': 'w-28 h-28 rounded-3xl',
    '4xl': 'w-32 h-32 rounded-3xl',
}

export default function TeamLogo({bg = '#007adb', color = '#ffffff', size = 'md', className, ...props}) {

    return (
        <div className={classNames('flex shrink-0 items-center justify-center bg-blue-500',SIZE_CLS[size], className)} {...props}>
            <CompanySvg fill={color} width={'66%'} height={'66%'}/>
        </div>
    )
}