import React from "react";
import styles from "../../stylesheets/components/WorkspaceWindow.module.scss";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast, {Toaster} from 'react-hot-toast';
import classNames from "classnames";

const DATA = [
    {label: 'Account Name', value: 'Auscentury Holding Unit Trust'},
    {label: 'TEN', value: 'xx****327'},
    {label: 'ABN/WPN', value: 'xx****327'},
    {label: 'TEN', value: 'xx****327'},
    {label: 'TEN', value: 'xx****327'},
    {label: 'TEN', value: 'xx****327'},
]

let toastId = null

function InfoItem({label, value, filedType = 'text', ...props}) {
    let canBeCopied = value && value !== '-'

    function onCopied() {
        toastId && toast.dismiss(toastId);
        toastId = toast.success('Copied to the pasteboard.', {
            duration: 2000,
        })
    }

    return (
        <div className={styles.item}>
            <div className={styles.label}>
                {label}
            </div>
            <div className={classNames(styles.value, canBeCopied && styles.copyBox)}>
                {canBeCopied ? (
                    <CopyToClipboard text={value} onCopy={onCopied}>
                        <div>
                            {value}
                        </div>
                    </CopyToClipboard>
                ) : value}
            </div>
        </div>
    )
}

export function Client() {
    return (
        <div className={styles.WorkspaceClientPane}>
            <div className={styles.Body}>
                <div className={styles.clientInfo}>
                    <div className={styles.sectionTitle}>Tax and Company</div>
                    <div className={styles.Section}>
                        <InfoItem label={'Account Name'} value={'Auscentury Holding Unit Trust'}/>
                        <InfoItem label={'TFN'} value={'xx****327'}/>
                        <InfoItem label={'ABN/WPN'} value={'47 742 262 884'}/>
                        <InfoItem label={'ACN'} value={'-'}/>
                        <InfoItem label={'Client Code'} value={'AUSUT32709'}/>
                        <InfoItem label={'Agent'} value={'Mex Associates Pty Ltd'}/>
                    </div>
                    <div className={styles.Section}>
                        <InfoItem label={'Partner'} value={'Zell Zhu'}/>
                        <InfoItem label={'Manager'} value={'Hetch Chen'}/>
                    </div>
                    <div className={styles.sectionTitle}>Contacts</div>
                    <div className={styles.Section}>
                        <InfoItem label={'Name'} value={'Mr Zhuo Chen'}/>
                        <InfoItem label={'Email'} value={'chenzhuosunmy@gmail.com'} filedType={'email'}/>
                        <InfoItem label={'Phone'} value={''}/>
                        <InfoItem label={'Position'} value={''}/>
                        <InfoItem label={'Physical Address'} value={'27 Huntingdon DrWANTIRNA SOUTHVIC 3152'}/>
                        <InfoItem label={'Postal Address'} value={'-'}/>
                    </div>

                    <div className={styles.Section}>
                        <InfoItem label={'Xero Web Url'}
                                  value={'https://practicemanager.xero.com/Client/14207605/Detail'}/>
                        <InfoItem label={'Website'} value={''}/>
                        <InfoItem label={'Note'} value={'Invoice 4993 cancelled per ZellAs repeat with Invoice 5600.'}/>

                    </div>
                </div>
            </div>
        </div>
    )
}