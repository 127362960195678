import React, {ReactNode} from "react";

export interface SvgIconProps extends React.HTMLAttributes<HTMLElement> {
    /**
     * How large should the button be?
     */
    size?: number;
    icon: React.ElementType

    [propName: string]: any;
}

export const SvgIcon = ({
                            size = 20,
                            icon: Icon,
                            ...props
                        }: SvgIconProps) => {

    return (
        <Icon style={{width: `${size}px`, height: `${size}px`}}  {...props}/>
    )
}