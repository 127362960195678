import React, {FormEvent, useEffect, useState} from "react";
import {ModalHeader} from "../ModalHeader";
import BaseModal from "../BaseModal";
import $api from "../../../api";
import ConfirmPasswordForm from "../ConfirmLoginPasswordModal/ConfirmPasswordForm";
import {Spinner} from "../../../stories/components/Spinner/src";
import {useSelector, useDispatch} from "react-redux";
import {BsFillCheckCircleFill, BsFillInfoCircleFill} from "react-icons/bs";
import validator from "validator";
import PasswordStrengthBar from "../../PasswordStrengthBar";
import * as Yup from "yup";
import {Button} from "../../../stories/components/Button/src";
import {toast} from "react-hot-toast";

const PASSWORD_MIN_LENGTH = 6

const PASSWORD_STRENGTH_SCORE = {
    WEAK: 0,
    MODERATE: 36,
    GOOD: 50,
}

const CONTENT_TYPE = {
    CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
    SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
    SUCCESS_AND_DISPLAY_RECOVERY_CODES: 'success_and_display_recovery_codes',
}

const CONTENT_LABEL = {
    [CONTENT_TYPE.CONFIRM_PASSWORD]: 'Verify current password',
    [CONTENT_TYPE.SET_NEW_PASSWORD]: 'Set new password',
    [CONTENT_TYPE.SUCCESS_AND_DISPLAY_RECOVERY_CODES]: 'Two-Factor Authentication Backup Codes',
}

function SetNewPassword({onSuccess, ...props}) {
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
    const [form, setForm] = useState({
        values: {
            password: '',
            password_confirmation: '',
        },
        errors: {}
    })
    const formValidationSchema = Yup.object().shape({
        password: Yup.string().min(6).required('Please fill in your password.'),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });
    const onInputBlur = async (event) => {
        const name = event.target.name
        const value = event.target.value
        if (!value) return
        const newForm = {...form}

        if (name === 'password_confirmation') {
            let errMsg = ''
            if (form.values.password_confirmation !== form.values.password) {
                errMsg = 'The two passwords entered are inconsistent'
            }
            newForm.errors['password_confirmation'] = errMsg
            setForm(newForm)
            return
        }
        try {
            await Yup.object().shape({[name]: formValidationSchema.fields[name]}).validate({[name]: value})
            newForm.errors[name] = ''
            setForm(newForm)
        } catch (err) {
            newForm.errors[err.path] = err.message
            setForm(newForm)
        }
    }

    const onInputChange = event => {
        const name = event.target.name
        const value = event.target.value
        const newForm = {...form}
        newForm.values[name] = value
        setForm(newForm)
        if (name === 'password') {
            let score = 0
            if (value.length >= PASSWORD_MIN_LENGTH) {
                score += validator.isStrongPassword(value, {
                    returnScore: true,
                    minLength: PASSWORD_MIN_LENGTH,
                    pointsPerUnique: 1,
                    pointsPerRepeat: 1,
                    pointsForContainingLower: 10,
                    pointsForContainingUpper: 10,
                    pointsForContainingNumber: 10,
                    pointsForContainingSymbol: 10
                })
            }
            setPasswordStrengthScore(score)
        }
    }
    const onSave = async event => {
        event.preventDefault()
        // console.log('passwordStrengthScore',passwordStrengthScore)
        // if (passwordStrengthScore <= PASSWORD_STRENGTH_SCORE.MODERATE) {
        //     setForm({
        //         ...form, errors: {
        //             password: 'This password is too weak'
        //         }
        //     })
        //     return
        // }
        try {
            await formValidationSchema.validate(form.values, {abortEarly: false})
            setForm({...form, errors: {}})
        } catch (err) {
            const newForm = {...form}
            err.inner.map(e => {
                newForm.errors[e.path] = e.message
            })
            setForm(newForm)
            return
        }
        setError('')
        setSaving(true)
        try {
            await $api.user.updatePassword(form.values)
            toast.success('Password updated')
            onSuccess&&onSuccess()
        } catch (e) {
            setError(e.message)
        }
        setSaving(false)
    }

    return (
        <>
            <form onSubmit={onSave} className={'w-full px-8 pb-14'}>
                <div className={'mb-4'}>
                    <label className="block text-gray-700 mb-2">
                        New Password
                    </label>
                    <input id={'password'} type={'password'} name={'password'}
                           className={`w-full rounded-lg border border-gray-300 hover:border-gray-400 focus:border-yale-700 py-2 px-4 focus:ring ring-yale-100 outline-none ${
                               form.errors.password
                                   ? 'border-red-600 hover:border-red-500'
                                   : ''
                           }`}
                           maxLength={100}
                           onChange={onInputChange}
                           onBlur={onInputBlur}
                           value={form.values.password}
                    />
                    <div className={'px-1'}>
                        <PasswordStrengthBar score={passwordStrengthScore}/>
                    </div>
                    {form.errors.password && (
                        <div className="text-red-600 text-sm">
                            {form.errors.password}
                        </div>
                    )}
                </div>
                <div className={'mb-4'}>
                    <label className="block text-gray-700 mb-2">
                        Confirm New Password
                    </label>
                    <input id={'password_confirmation'} type={'password'} name={'password_confirmation'}
                           className={`w-full rounded-lg border border-gray-300 hover:border-gray-400 focus:border-yale-700 py-2 px-4 focus:ring ring-yale-100 outline-none ${
                               form.errors.password_confirmation
                                   ? 'border-red-600 hover:border-red-500'
                                   : ''
                           }`}
                           maxLength={100}
                           onChange={onInputChange}
                           onBlur={onInputBlur}
                           value={form.values.password_confirmation}
                    />
                    {form.errors.password_confirmation && (
                        <div className="text-red-600 text-sm mt-1">
                            {form.errors.password_confirmation}
                        </div>
                    )}
                </div>
                <div className={'h-1'}/>
                <Button type={'submit'}
                        isBlock={true}
                        loading={saving}
                >
                    <div className={'font-bold'}>
                        Update Password
                    </div>
                </Button>
            </form>
        </>
    )
}

export default function UpdatePasswordModal({
                                                ...props
                                            }) {
    const [contentType, setContentType] = useState(CONTENT_TYPE.CONFIRM_PASSWORD)

    const onConfirmedPassword = () => {
        setContentType(CONTENT_TYPE.SET_NEW_PASSWORD)
    }

    const onUpdatePasswordSuccess = () => {
        props.onRequestClose()
    }


    return (
        <BaseModal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            className={''}
        >
            <div className={'h-max max-h-80vh w-[400px]'}>
                <ModalHeader label={CONTENT_LABEL[contentType]}
                             onRequestClose={props.onRequestClose}/>
                {contentType === CONTENT_TYPE.CONFIRM_PASSWORD && (
                    <ConfirmPasswordForm onConfirmed={onConfirmedPassword}/>
                )}

                {contentType === CONTENT_TYPE.SET_NEW_PASSWORD && (
                    <SetNewPassword onSuccess={onUpdatePasswordSuccess}/>
                )}

            </div>
        </BaseModal>
    )
}