import React from 'react';
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

interface Props extends React.HTMLAttributes<HTMLElement> {
    color?: string

    [propName: string]: any
}

/**
 * Primary UI component for user interaction
 */
export const Divider = ({
                            className = '',
                            color = '',
                            ...props
                        }: Props) => {

    const classes = [`${UIPrefix}divider`,'my-2']

    return (
        <div className={classNames(classes, className)}>
            <div className={'line'} style={color ? {backgroundColor: color} : {}}/>
        </div>
    );
}
