import React, {ReactElement, ReactNode} from "react";
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

interface AvatarGroupProps {
    maxCount?: number
    size?: keyof typeof Indents
    children: ReactElement | ReactElement[]
}

const Indents = {
    xs: 5,
    sm: 8,
    md: 10,
    lg: 14,
    xl: 18,
}

export function AvatarGroup({
                                size = 'md',
                                maxCount = 1,
                                children,
                                ...props
                            }: AvatarGroupProps) {

    const classs = classNames(`${UIPrefix}avatar-group`,)
    const childNodes: ReactElement[] = children instanceof Array ? children : [children]
    const IndentSize = Indents[size]

    return (
        <div className={classs}>
            {childNodes.slice(0, maxCount).map((child, index) => {
                const childProps = {
                    ...child.props,
                    size,
                    rounded: true,
                }
                return (
                    <div
                        key={index}
                        className={'item'}
                        style={{left: -(index * IndentSize) + 'px'}}
                    >
                        {React.cloneElement(child, childProps)}
                    </div>
                )
            })}
            {childNodes.length > maxCount && (
                <div
                    className={'maxInfo'}
                    style={{left: -(maxCount * IndentSize) + (IndentSize * 1.5) + 'px'}}
                >
                    +{childNodes.length - maxCount}
                </div>
            )}
        </div>
    )
}