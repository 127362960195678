import classNames from "classnames";
import styles from "./index.module.scss";
import React, {useState} from "react";
import {BsChat, BsBox, BsTrash, BsFolder2, BsClock, BsChatFill} from "react-icons/bs";
import {FiUsers} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import {ReactComponent as XeroSvg} from "../../assets/app-icon/xero.svg"
import SimpleScroll from "../../components/SimpleScroll";
import {IoCaretForward} from "react-icons/io5";
import {XeroIcon} from "../../components/Icon/XeroIcon";
import {useNavigate} from "react-router-dom";
import * as Accordion from '@radix-ui/react-accordion';
import WorkspaceIcon from "../../components/WorkspaceIcon";
import {useSelector} from "react-redux";
import UserAvatar from "../../components/UserAvatar";
import {UnreadBadge} from "../../components/ui";

const NavItem = ({icon, label, badge = 0, link, className, ...props}) => {
    return (
        <NavLink
            to={link}
            className={({isActive}) => classNames(styles.NavItem, isActive && styles.active, className)}
            {...props}
        >
            <div className={styles.icon}>
                {icon}
            </div>
            <div className={styles.label}>{label}</div>
            <UnreadBadge num={badge} className={'mx-2'}/>
        </NavLink>
    )
}

const SectionTitle = ({children = ''}) => {
    return (
        <div className={classNames(styles.SectionTitle)}>
            {children}
        </div>
    )
}

function PinSection({ className}) {
    const {data: workspaces, status, errMsg, pagination, search} = useSelector(state => state.workspace)

    return (
        <Accordion.Root
            type="single"
            defaultValue={1}
            collapsible
            className={classNames('', className)}>
            <Accordion.Item value={1}>
                <Accordion.Trigger
                    className={'group flex items-center px-4 py-2 opacity-90'}>
                    <div className={'w-6 flex justify-center mr-2'}>
                        <IoCaretForward className={'group-data-[state=open]:rotate-90'}/>
                    </div>
                    <div>Pinned</div>
                </Accordion.Trigger>
                {/*<NavItem icon={<WorkspaceIcon className={'text-lg'}/>} label={workspaces[0].name} link={`/workspace/${workspaces[0].id}`}/>*/}
                <Accordion.Content className={''}>
                    {workspaces.map(workspace => (
                        <NavItem
                            key={workspace.id}
                            icon={<WorkspaceIcon/>}
                            label={workspace.name}
                            link={`/workspace/${workspace.id}`}
                        />
                    ))}
                </Accordion.Content>
            </Accordion.Item>
        </Accordion.Root>
    )
}

export default function NavList({}) {
    const [openWorkspaceMoreMenu, setOpenWorkspaceMoreMenu] = useState(false)
    const navigate = useNavigate();

    return (
        <SimpleScroll className={styles.NavList} autoHide={true}>
            <NavItem icon={<BsChat className={'text-lg'}/>} label={'Workspaces'} link={'/workspace'}/>
            <NavItem icon={<FiUsers className={'text-lg'}/>} label={'Teammates'} link={'/teammate'}/>
            <NavItem icon={<BsFolder2 className={'text-lg'}/>} label={'Docs'} link={'/docs'}/>
            <NavItem icon={<XeroIcon size={18}/>} label={'Xero'} link={'/app/xero'}/>
            {/*<NavItem icon={<AiOutlineDropbox className={'text-lg'}/>} label={'Dropbox'} link={'/app/dropbox'}/>*/}
            {/*<SectionTitle>Apps</SectionTitle>*/}
            {/*<div className={'h-px bg-black/10 my-3'}></div>*/}
            {/*<PinSection/>*/}
            <div style={{height: '30px'}}/>
        </SimpleScroll>
    )
}