import * as React from "react";
import {Button, IconButton} from "../../../stories/components/Button/src";
import {BsXLg} from "react-icons/bs";
import styles from "./index.module.scss"
import {SvgIcon} from "../../../stories/components/SvgIcon/src";
import classNames from "classnames";


interface ModalHeaderProps {
    label?: string
    showCloseBtn?: boolean

    onRequestClose?(event: React.MouseEvent | React.KeyboardEvent): void;
}

export function ModalHeader({
                                label = '',
                                showCloseBtn = true,
                                onRequestClose,
                            }: ModalHeaderProps) {
    return (
        <div className={classNames(styles.ModalHeader, 'px-3')}>
            <div className={classNames('flex-1 px-4 text-lg font-semibold truncate')}>{label}</div>
            {showCloseBtn ? (
                <IconButton variant={'ghost'} colorScheme={'light'} onClick={onRequestClose}>
                    <SvgIcon icon={BsXLg} size={14}/>
                </IconButton>
            ) : (<div/>)}
        </div>
    )
}