import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";
import {useSelector} from "react-redux";


export default function AppNavBar({className, ...props}) {
    const {theme} = useSelector((state) => state.team);

    return (
        <div className={classNames(styles.appNavBar, className)} {...props} data-color={theme}/>
    )
}