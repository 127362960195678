import React, {useEffect, useState} from "react";
import SimpleScroll from "../../../SimpleScroll";
import classNames from "classnames";
import styles from "../index.module.scss";
import {Button} from "../../../../stories/components/Button/src";
import {ReactComponent as TeamGroupSvg} from "../../../../assets/undraw/undraw_team_group.svg"
import {ReactComponent as DecideSvg} from "../../../../assets/undraw/undraw_decide_re_ixfw.svg"
import {useDispatch, useSelector} from "react-redux";
import BaseModal from "../../BaseModal";
import {ModalHeader} from "../../ModalHeader";
import {Input} from "../../../../stories/components/Input/src";
import {Alert} from "../../../../stories/components/Alert/src";
import {Dropdowns, SubmitButton} from "../../../ui";
import * as Yup from "yup";
import Dropdown from "rc-dropdown/es";
import Menu, {Item as MenuItem} from "rc-menu/es";
import {BsThreeDots, BsChevronDown, BsChevronUp} from "react-icons/bs";
import {IoChevronDown} from "react-icons/io5";
import SelectTeamMember from "../../../SelectTeamMember";
import MultiSelectTeamMembers from "../../../MultiSelectTeamMembers";
import HelpTipIcon from "../../../HelpTipIcon";
import UserAvatar from "../../../UserAvatar";
import * as Collapsible from '@radix-ui/react-collapsible';

import {
    fetchTeamGroups,
    fetchSaveTeamGroup,
    fetchDelTeamGroup,
    fetchAddTeamGroupMembers, fetchRemoveTeamGroupMember
} from "../../../../store/reducers/teamGroup";
import ContentLoader from "react-content-loader";
import {toast} from "react-hot-toast";
import ConfirmAlert from "../../../ConfirmAlert";
import {SelectTeamMemberModal} from "../../SelectTeamMemberModal";

const MODAL = {
    CREATE_GROUP: 'CREATE_GROUP',
}

const ACTION_KEYS = {
    EDIT_GROUP: 'EDIT_GROUP',
    ADD_MEMBER: 'ADD_MEMBER',
    DELETE_GROUP: 'DELETE_GROUP',
    REMOVE_MEMBER: 'REMOVE_MEMBER',
}

function Empty() {
    return (
        <div className={classNames(styles.Panel, 'flex flex-col items-center justify-center pb-6')}>
            <TeamGroupSvg className={'h-[160px] mb-10'}/>
            <div className={'mb-6 font-semibold'}>
                Organize your team with member groups
            </div>
            <Button>Create group</Button>
        </div>
    )
}

function CreateTeamGroupModal({group = null, isOpen, onRequestClose}) {
    const dispatch = useDispatch()
    const [errMsg, setErrMsg] = useState('')
    const [form, setForm] = useState({
        name: group?.name || '',
        description: group?.description || '',
    })
    const disabled = !form.name.trim()
    const [submitting, setSubmitting] = useState(false)

    const formValidationSchema = Yup.object().shape({
        name: Yup.string().max(80).required('Please fill in your group name.'),
    });

    const onSubmit = async (event) => {
        event.preventDefault()
        setErrMsg('')
        setSubmitting(true)
        try {
            await formValidationSchema.validate(form, {abortEarly: true})
            await dispatch(fetchSaveTeamGroup({id: group?.id, ...form})).unwrap()
            toast.success('The update was successful')
            onRequestClose && onRequestClose()
        } catch (err) {
            setErrMsg(err.message)
        }
        setSubmitting(false)
    }

    const onInputChange = event => {
        const name = event.target.name
        const value = event.target.value
        const newForm = {...form}
        newForm[name] = value
        setForm(newForm)
    }

    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName={'bg-black/60'}
        >
            <ModalHeader label={group ? `Edit ${group.name}` : 'Create a group'} onRequestClose={onRequestClose}/>
            <div className={'mx-10 py-4 w-[400px]'}>
                <Alert variant={'outline'} colorScheme={'danger'}>
                    {errMsg}
                </Alert>
                <form onSubmit={onSubmit} className={'flex flex-col gap-3'}>
                    <div>
                        <label htmlFor="name" className={'pb-2 mt-2'}>Name</label>
                        <Input
                            id={'name'}
                            name={'name'}
                            placeholder={'e.g. Marketing'}
                            maxLength={50}
                            value={form.name}
                            onChange={onInputChange}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="description"
                            className={'w-full pb-2 mt-2'}>
                            Description <span className={'text-stone-500 text-sm'}>(option)</span>
                        </label>
                        <Input
                            id={'description'}
                            name={'description'}
                            placeholder={''}
                            maxLength={255}
                            value={form.description}
                            onChange={onInputChange}
                        />
                    </div>

                    <div className={'flex items-center justify-end gap-4 py-6'}>
                        <Button variant={'outline'} colorScheme={'secondary'} onClick={onRequestClose}>Cancel</Button>
                        <SubmitButton disabled={disabled}
                                      loading={submitting}>
                            {group ? 'Save' : 'Create Group'}
                        </SubmitButton>
                    </div>
                </form>
            </div>
        </BaseModal>
    )
}

function MembersModal({group, isOpen, onRequestClose}) {
    const members = []

    return (
        <BaseModal
            onRequestClose={onRequestClose}
            isOpen={isOpen}
            overlayClassName={'bg-black/60'}
        >
            <ModalHeader label={'Developer group'} onRequestClose={onRequestClose}/>
            <div className={'w-[400px] px-6'}>

                <DecideSvg className={'w-[220px] mx-auto mb-10'}/>
            </div>
        </BaseModal>
    )
}


function ListSkeleton() {
    return (
        <>
            {
                Array.from({length: 3}).map((_, index) => (
                    <ContentLoader key={index} viewBox="0 0 300 52" height={52} width={300}>
                        <rect y="20" rx="5" width={200} height={12}/>
                    </ContentLoader>
                ))
            }
        </>
    )
}

export function TeamGroupPanel() {
    const {data: groups, status, errMsg} = useSelector(state => state.teamGroup)
    const [showModal, setShowModal] = useState('')
    const [actionGroup, setActionGroup] = useState(null)
    const [actionMember, setActionMember] = useState(null)
    const [addingMember, setAddingMember] = useState(false)
    const [removingMember, setRemovingMember] = useState(false)
    const [deletingGroup, setDeletingGroup] = useState(false)

    const dispatch = useDispatch()


    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchTeamGroups())
        }
    }, [])

    const onDelGroup = async () => {
        try {
            setDeletingGroup(true)
            await dispatch(fetchDelTeamGroup(actionGroup.id)).unwrap()
            toast.success('The group has been deleted')
            setShowModal('')
        } catch (e) {
            toast.error('Group deletion failed')
        }
        setDeletingGroup(false)
    }

    const onAddMember = async (member_ids) => {
        try {
            setAddingMember(true)
            await dispatch(fetchAddTeamGroupMembers({group_id: actionGroup.id, member_ids})).unwrap()
            toast.success('Added')
            setShowModal('')
        } catch (e) {
            toast.error('failed')
        }
        setAddingMember(false)
    }

    const onRemoveMember = async () => {
        try {
            setRemovingMember(true)
            await dispatch(fetchRemoveTeamGroupMember({group_id: actionGroup.id, member_id:actionMember.id})).unwrap()
            toast.success('The group member has been removed')
            setShowModal('')
        } catch (e) {
            console.log('e',e.message)
            toast.error('Group member deletion failed')
        }
        setRemovingMember(false)
    }

    return (
        <div>
            <SimpleScroll className={classNames('py-4', styles.Panel)}>
                <div className={'py-2'}>
                    <Button onClick={() => {
                        setActionGroup(null)
                        setShowModal(ACTION_KEYS.EDIT_GROUP)
                    }}>
                        Create new group
                    </Button>
                </div>

                {status === 'loading' && (<ListSkeleton/>)}
                {status === 'success' && groups.map((group, i) => (
                    <Collapsible.Root key={i} className="py-2 border-b last:border-0 relative">
                        <div className={'flex items-center gap-4'}>
                            <div className={'flex-1 py-2 flex items-center gap-4'}>
                                <div className={'font-semibold w-2/3'}>{group.name}</div>
                                {group.member_count > 0 ? (
                                    <Collapsible.Trigger asChild>
                                        <div
                                            className={'px-1 flex items-center gap-1 text-stone-500 group select-none'}>
                                            <div>{group.member_count} members</div>
                                            <IoChevronDown
                                                className={'group-data-[state=open]:rotate-180'}/>
                                        </div>
                                    </Collapsible.Trigger>
                                ) : (
                                    <div
                                        className={'px-1 text-stone-500 select-none'}>{group.member_count} members</div>
                                )}
                            </div>
                        </div>
                        <Collapsible.Content>
                            <div className={'pb-2'}>
                                {group.members?.map((member, index) => (
                                    <div
                                        key={member.id}
                                        className={'py-1.5 px-2 flex items-center group hover:bg-stone-100 rounded transition-colors'}>
                                        <UserAvatar
                                            id={member.id}
                                            name={member.name}
                                            src={member.avatar_url}
                                            size={'xs'}
                                        />
                                        <div className={'font-semibold text-stone-700 mx-3 flex-1'}>{member.name}</div>
                                        <button className={'px-2 text-danger hidden group-hover:block hover:underline'}
                                                onClick={() => {
                                                    setActionGroup(group)
                                                    setActionMember(member)
                                                    setShowModal(ACTION_KEYS.REMOVE_MEMBER)
                                                }}>
                                            Remove
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </Collapsible.Content>
                        <div className={'absolute right-3 top-3 flex gap-8 items-center'}>
                            <Dropdown
                                trigger={['click']}
                                placement={'bottomRight'}
                                overlay={(
                                    <Menu onClick={({key}) => {
                                        setShowModal(key)
                                        setActionGroup(group)
                                    }}>
                                        <MenuItem eventKey={ACTION_KEYS.EDIT_GROUP}>
                                            Edit
                                        </MenuItem>
                                        <MenuItem eventKey={ACTION_KEYS.ADD_MEMBER}>
                                            Add member
                                        </MenuItem>
                                        <MenuItem eventKey={ACTION_KEYS.DELETE_GROUP} className={'text-danger'}>
                                            Delete
                                        </MenuItem>
                                    </Menu>
                                )}>
                                <button
                                    className={'rounded p-1 border border-transparent hover:border-stone-300 hover:bg-white text-xl'}>
                                    <BsThreeDots/>
                                </button>
                            </Dropdown>
                        </div>
                    </Collapsible.Root>
                ))}
            </SimpleScroll>

            {showModal === ACTION_KEYS.REMOVE_MEMBER && (
                <ConfirmAlert
                    isOpen={true}
                    onRequestClose={() => setShowModal('')}
                    confirmColorScheme={'danger'}
                    confirmText={'Remove'}
                    confirming={removingMember}
                    onConfirm={onRemoveMember}>
                    Remove <b>{actionMember.name}</b> from the <b>{actionGroup.name}</b> group
                </ConfirmAlert>
            )}

            {showModal === ACTION_KEYS.DELETE_GROUP && (
                <ConfirmAlert
                    label={`Delete ${actionGroup.name}`}
                    isOpen={true}
                    onRequestClose={() => setShowModal('')}
                    confirmColorScheme={'danger'}
                    confirmText={'Delete'}
                    confirming={deletingGroup}
                    onConfirm={onDelGroup}
                />
            )}

            {showModal === ACTION_KEYS.ADD_MEMBER && (
                <SelectTeamMemberModal
                    isOpen={true}
                    overlayClassName={'bg-black/60'}
                    onRequestClose={() => setShowModal('')}
                    submitting={addingMember}
                    onAdd={onAddMember}
                />
            )}

            {showModal === ACTION_KEYS.EDIT_GROUP && (
                <CreateTeamGroupModal
                    isOpen={true}
                    onRequestClose={() => setShowModal('')}
                    group={actionGroup}
                />
            )}

        </div>
    )
}