import React from "react";
import styles from "../../stylesheets/components/WorkspaceWindow.module.scss";
import DocumentSpaceActionRow from "../../components/DocumentSpaceActionRow";
import FileList from "../../components/FileList";
import classNames from "classnames";

export function Docs() {
    return (
        <div className={classNames(styles.WorkspaceWindowFile)}>
            <div className={classNames(styles.Body)}>
                <DocumentSpaceActionRow className={''}/>
                <FileList height={'calc(100vh - 260px)'} />
            </div>
        </div>
    )
}