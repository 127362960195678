import React, {FormEvent, useState} from "react";
import {Input} from "../../../stories/components/Input/src";
import {Button} from "../../../stories/components/Button/src";
import $api from "../../../api";
import {FiExternalLink} from "react-icons/fi";

interface Props {
    onConfirmed?: () => void
}

export default function ConfirmPasswordForm({
                                                onConfirmed,
                                                ...props
                                            }: Props) {

    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onSubmit = async (event: FormEvent) => {
        event.preventDefault()
        // @ts-ignore
        const password = event.target.elements.password.value
        if (!password || !password.trim()) {
            setErrorMessage('Please filled password')
            return
        }
        setErrorMessage('')
        setIsSubmitting(true)
        try {
            await $api.user.confirmPassword(password)
            onConfirmed && onConfirmed()
        } catch (e) {
            setErrorMessage('Password verification failed')
        }
        setIsSubmitting(false)
    }

    return (
        <div className={'p-6'}>
            <form onSubmit={onSubmit}>
                <Input
                    type={'password'}
                    name={'password'}
                    autoFocus
                    placeholder={'Password'}
                    isError={errorMessage !== ''}
                />
                <div className={'text-red-600 py-2 px-1 text-sm'}>
                    {errorMessage}
                </div>
                <div className={'text-neutral-500 pb-2 px-1 text-sm'}>
                    This is your {process.env.REACT_APP_NAME} password, not the one you use for Apple, Google or third
                    party sign in.
                    <div>
                        If you forget your current password, you can
                        <a href={process.env.REACT_APP_FORGOT_PASSWORD_URL}
                           target={'_blank'}
                           className={'w-max cursor-pointer mx-1 text-blue-600 hover:underline flex flex-row items-center'}>
                            reset it
                            <FiExternalLink className={'mx-1'}/>
                        </a>
                    </div>
                </div>
                <div className={'mt-2 flex justify-end'}>
                    <Button
                        isBlock
                        type={'submit'}
                        colorScheme={'yale'}
                        loading={isSubmitting}>
                        Confirm Password
                    </Button>
                </div>
            </form>
        </div>
    )
}