import React, {useState} from "react";
import BaseModal from "../BaseModal";
import {ModalHeader} from "../ModalHeader";
import classNames from "classnames";
import {Button} from "../../../stories/components/Button/src";
import {Row} from "../../../stories/components/Layout/src";
import * as yup from "yup";
import $api from "../../../api";
import {useSelector} from "react-redux";
import {ReactComponent as MailSentSvg} from "../../../assets/undraw/undraw_mail_sent_re_0ofv.svg"
import {Input} from "../../../stories/components/Input/src";

const INVITE_STATUS = {
    DEFAULT: 'DEFAULT',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
}

export default function InviteTeamMemberModal({isOpen, onRequestClose, ...props}) {
    const team = useSelector(state => state.team)
    const [confirming, setConfirming] = useState(false)
    const [email, setEmail] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [inviteStatus, setInviteStatus] = useState(INVITE_STATUS.DEFAULT)
    const emailValidator = yup.string().required('Email is required').email()

    const onInvite = async (e) => {
        e.preventDefault()
        setConfirming(true)
        setErrMsg('')
        try {
            await emailValidator.validate(email)
            const member = await $api.team.inviteMember({
                team_id:team.id, email
            })
            setInviteStatus(INVITE_STATUS.SUCCESS)
        } catch (e) {
            setErrMsg(e.message)
        }
        setConfirming(false)
    }

    return (
        <BaseModal isOpen={isOpen} className={classNames('w-[400px] bg-stone-100')}
                   onRequestClose={onRequestClose} {...props}>
            {inviteStatus === INVITE_STATUS.DEFAULT && (
                <div>
                    <ModalHeader label={'Invite member'} onRequestClose={onRequestClose}/>
                    <form onSubmit={onInvite} className={'py-6'}>
                        <div className={'px-6'}>
                            <Input
                                placeholder={"name@work-email.com"}
                                value={email}
                                maxLength={100}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <div className={'py-3 px-1 text-rose-600'}>
                                {errMsg}
                            </div>
                        </div>
                        <div className={'px-6 my-2 justify-center'}>
                            <Button type={'submit'}
                                    loading={confirming}
                                    onClick={onInvite}
                                    isBlock={true}>
                                Invite
                            </Button>
                        </div>
                    </form>
                </div>
            )}

            {inviteStatus === INVITE_STATUS.SUCCESS && (
                <div>
                    <ModalHeader label={''} onRequestClose={onRequestClose}/>
                    <div className={'flex flex-col items-center px-6'}>
                        <MailSentSvg className={'w-[120px] h-[120px]'}/>
                        <div className={'py-4 text-lg font-semibold'}>
                            An invitation email has been sent to the {email}
                        </div>
                        <div className={'h-12'}>

                        </div>
                    </div>
                </div>
            )}

        </BaseModal>
    )
}