import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import $api from "../../api";

export const fetchTeamGroups = createAsyncThunk('fetchTeamGroups', async (_, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    try {
        return await $api.team.getGroups({
            team_id
        })
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchSaveTeamGroup = createAsyncThunk('fetchSaveTeamGroup', async (data = {}, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    try {
        return await $api.team.saveGroup({team_id, ...data})
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchAddTeamGroupMembers = createAsyncThunk('fetchAddTeamGroupMembers', async ({group_id, member_ids}, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    try {
        return await $api.team.addGroupMembers({team_id, group_id, member_ids})
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchRemoveTeamGroupMember = createAsyncThunk('fetchRemoveTeamGroupMember', async ({group_id, member_id}, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    try {
        return await $api.team.removeGroupMember({team_id, group_id, member_id})
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchDelTeamGroup = createAsyncThunk('fetchDelTeamGroup', async (id, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    try {
        return await $api.team.delGroup({team_id, id})
    } catch (err) {
        return rejectWithValue(err)
    }
})

const initialState = {
    status: 'idle',
    errMsg: null,
    data: [],
}

const teamGroupSlice = createSlice({
    name: "teamGroup",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //  获取组
        builder.addCase(fetchTeamGroups.pending, (state, action) => {
            state.status = 'loading';
        }).addCase(fetchTeamGroups.fulfilled, (state, action) => {
            state.data = action.payload
            state.status = 'success';
        }).addCase(fetchTeamGroups.rejected, (state, action) => {
            state.status = 'failed';
            state.errMsg = action.payload.message;
        });
        //  保存组
        builder.addCase(fetchSaveTeamGroup.fulfilled, (state, action) => {
            const group = action.payload
            const newData = [...state.data]
            const index = newData.findIndex(item => item.id === group.id)
            if (index > -1) {
                newData.splice(index, 1, group)
            } else {
                newData.push(group)
            }
            state.data = newData;
        });
        //  删除组
        builder.addCase(fetchDelTeamGroup.fulfilled, (state, action) => {
            const deleted_group_id = action.meta.arg
            const newData = [...state.data]
            const index = newData.findIndex(item => item.id === deleted_group_id)
            if (index < 0) return
            newData.splice(index, 1)
            state.data = newData;
        });
        //  添加组成员
        builder.addCase(fetchAddTeamGroupMembers.fulfilled, (state, action) => {
            const {group_id} = action.meta.arg
            const members = action.payload
            const newData = [...state.data]
            const index = newData.findIndex(item => item.id === group_id)
            if (index < 0) return
            newData.splice(index, 1, {
                ...newData[index],
                members,
                member_count: members.length,
            })
            state.data = newData
        });
        //  移除组成员
        builder.addCase(fetchRemoveTeamGroupMember.fulfilled, (state, action) => {
            const {group_id, member_id} = action.meta.arg
            const newData = [...state.data]
            const index = newData.findIndex(item => item.id === group_id)
            if (index < 0) return
            const group = newData[index]
            const deletedMemberIndex = group.members?.findIndex(item => item.id === member_id)
            if (deletedMemberIndex < 0) return
            group.member_count -= 1
            group.members?.splice(deletedMemberIndex, 1)
            newData.splice(index, 1, group)
            state.data = newData;
        });
    },
});

export const {} = teamGroupSlice.actions;
export default teamGroupSlice.reducer;