import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";
import $api from "../../../../api";
import classNames from "classnames";
import styles from "../index.module.scss";
import {Switch} from "../../../../stories/components/Switch/src";
import {Input} from "../../../../stories/components/Input/src";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Button, IconButton} from "../../../../stories/components/Button/src";
import {SvgIcon} from "../../../../stories/components/SvgIcon/src";
import {BsFiles, BsThreeDots} from "react-icons/bs";
import {Spinner} from "../../../../stories/components/Spinner/src";
import {Row} from "../../../../stories/components/Layout/src";
import UserAvatar from "../../../UserAvatar";
import {Heading, Text} from "../../../../stories/components/Text/src";
import {Tag} from "../../../../stories/components/Tag/src";
import {Divider} from "../../../../stories/components/Divider/src";
import SimpleScroll from "../../../SimpleScroll";
import BaseModal from "../../BaseModal";
import {ModalHeader} from "../../ModalHeader";
import ConfirmAlert from "../../../ConfirmAlert";
import InviteTeamMemberModal from "../../InviteTeamMemberModal";
import {SearchInput} from "../../../ui";
import ContentLoader from "react-content-loader";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Dropdown from "rc-dropdown/es";
import Menu, {Item as MenuItem} from "rc-menu/es";
import {TransferTeamOwnershipModal} from "../../TransferTeamOwnershipModal";
import {useTeamMembers} from "../../../../hooks";

const MEMBER_ACTION_KEYS = {
    AS_MEMBER: 'AS_MEMBER',
    AS_ADMIN: 'AS_ADMIN',
    AS_OWNER: 'AS_OWNER',
    DEACTIVATE: 'DEACTIVATE',
}

function ListSkeleton() {
    return (
        <>
            {
                Array.from({length: 5}).map((_, index) => (
                    <div key={index} className={'h-[56px] border-b'}>
                        <ContentLoader viewBox="0 0 300 56" height={56} width={300}>
                            <rect x={12} y={12} rx="4" width={32} height={32}/>
                            <rect x={56} y="12" rx="5" width="80" height="12"/>
                            <rect x={56} y="32" rx="5" width="200" height="12"/>
                        </ContentLoader>
                    </div>
                ))
            }
        </>
    )
}

function InviteLinkLoader() {
    return (
        <ContentLoader viewBox="0 0 736 72" width={736} height={72}>
            <rect x="0" y="12" rx="5" width="200" height="22"/>
            <rect x="0" y="50" rx="5" width="400" height="18"/>
            <rect x="670" y="20" rx="15" width="60" height="25"/>
        </ContentLoader>
    )
}

export function TeamMemberPanel() {
    let toastId = null
    const {team} = useSelector(state => state)
    const [loadingInviteLink, setLoadingInviteLink] = useState(false)
    const [loadingMember, setLoadingMember] = useState(false)
    const [inviteLinkEnabled, setInviteLinkEnabled] = useState(true)
    const [inviteLink, setInviteLink] = useState('')
    const [switchingInviteLink, setSwitchingInviteLink] = useState(false)
    const [resetInviteLinkAlert, setShowResetInviteLinkAlert] = useState(false)
    const [resettingInviteLink, setResettingInviteLink] = useState(false)
    const [showInviteMemberModal, setShowInviteMemberModal] = useState(false)

    const [showActionAlert, setShowActionAlert] = useState()
    const [actionMember, setActionMember] = useState(null)
    const dispatch = useDispatch()
    const [submittingActionAlert, setSubmittingActionAlert] = useState('')

    const {status: loadMemberStatus, members, setMembers, search, setSearch, errMsg: loadMemberErrMsg, onLoadTeamMembers} = useTeamMembers()


    function onCopied() {
        toastId && toast.dismiss(toastId);
        toastId = toast.success('Copied to the pasteboard.', {
            duration: 2000,
        })
    }

    async function onResetInviteLink() {
        setResettingInviteLink(true)
        try {
            const {link} = await $api.team.inviteLinkReset(team.id)
            setInviteLink(link)
            setShowResetInviteLinkAlert(false)
            toast.success('The invitation link has been reset.')
        } catch (e) {
            toast.error('An error occurred in the invite link reset.')
        }
        setResettingInviteLink(false)
    }

    async function onInviteLinkEnableChange() {
        setSwitchingInviteLink(true)
        try {
            const {enabled, link} = await $api.team.inviteLinkSwitch(team.id)
            setInviteLinkEnabled(enabled)
            setInviteLink(link)
        } catch (e) {

        }
        setSwitchingInviteLink(false)
    }

    async function onLoadInviteLink() {
        setLoadingInviteLink(true)
        try {
            const {enabled, link} = await $api.team.inviteLink(team.id)
            setInviteLinkEnabled(enabled)
            setInviteLink(link)
        } catch (e) {

        }
        setLoadingInviteLink(false)
    }

    async function onQueryMember(params) {
        setLoadingMember(true)
        try {

        } catch (e) {

        }
        setLoadingMember(false)
    }

    async function onSearch(value) {
    }

    async function onAsMember() {
        try {
            setSubmittingActionAlert(MEMBER_ACTION_KEYS.AS_MEMBER)
            const newMembers = [...members]
            const index = newMembers.findIndex(item => item.id === actionMember.id)
            await $api.team.asMember({team_id: team.id, user_id: actionMember.user_id})
            newMembers.splice(index, 1, {...actionMember, is_admin: false})
            setMembers(newMembers)
            toast.success(`${actionMember.name} has been set up as a team regular member`)
            setShowActionAlert('')
        } catch (e) {
            toast.error('Failed to set up regular member')
        }
        setSubmittingActionAlert(false)
    }

    async function onAsAdmin() {
        try {
            setSubmittingActionAlert(MEMBER_ACTION_KEYS.AS_ADMIN)
            const newMembers = [...members]
            const index = newMembers.findIndex(item => item.id === actionMember.id)
            await $api.team.asAdmin({
                team_id: team.id,
                user_id: actionMember.user_id
            })
            newMembers.splice(index, 1, {...actionMember, is_admin: true})
            setMembers(newMembers)
            toast.success(`${actionMember.name} has been set up as a team admin`)
            setShowActionAlert('')
        } catch (e) {
            toast.error('Failed to set up administrator')
        }
        setSubmittingActionAlert(false)
    }

    useEffect(() => {

    }, [])

    return (
        <>
            <SimpleScroll className={classNames(styles.Panel)}>
                {loadingInviteLink ? (<InviteLinkLoader/>) : (
                    <div className={classNames('py-4')}>
                        <div className={'flex'}>
                            <div className={'flex-1'}>
                                <div className={styles.ItemLabel}>
                                    Invite link
                                </div>
                                <div className={styles.SubLabel}>
                                    <div>
                                        Share this link to invite people to join the current team.
                                    </div>
                                </div>
                            </div>
                            {switchingInviteLink && (
                                <Spinner className={'m-2'}/>
                            )}
                            <Switch
                                loading={switchingInviteLink}
                                checked={inviteLinkEnabled}
                                onCheckedChange={onInviteLinkEnableChange}
                            />
                        </div>
                        {inviteLinkEnabled && (
                            <div className={''}>
                                <div className={styles.SubLabel}>
                                    <div>
                                        You can <span
                                        className={'cursor-pointer hover:underline text-blue-800'}
                                        onClick={() => setShowResetInviteLinkAlert(true)}>reset the link</span> to
                                        generate a new link.
                                    </div>
                                </div>
                                <Input
                                    variant={'filled'}
                                    className={classNames('mt-4')}
                                    defaultValue={inviteLink}
                                    readOnly
                                    renderSuffix={(
                                        <CopyToClipboard text={inviteLink} onCopy={onCopied}>
                                            <IconButton variant={'ghost'} colorScheme={'secondary'} tip={'Copy link'}>
                                                <SvgIcon icon={BsFiles} size={17}/>
                                            </IconButton>
                                        </CopyToClipboard>
                                    )}
                                />
                            </div>
                        )}
                    </div>
                )}

                {/*<Divider my={'sm'}/>*/}

                <div className={classNames(styles.ItemRow, 'border-b')}>
                    <Button onClick={() => setShowInviteMemberModal(true)}>Add Member</Button>
                    <div>
                        <SearchInput placeholder={'Filter by name or email'} value={search}
                                     onChange={e => setSearch(e.target.value)} onSearch={() => onLoadTeamMembers()}/>
                    </div>
                </div>

                {loadMemberStatus === 'loading' && (<ListSkeleton/>)}
                {loadMemberStatus === 'success' && (
                    <div>
                        {
                            members.map((member, index) => (
                                <div key={member.id}
                                     className={'h-14 flex items-center px-3 hover:bg-stone-100 border-b transition-colors'}>
                                    <UserAvatar id={member.id} name={member.name} src={member.avatar_url} size={'sm'}/>
                                    <div className={'px-3 flex-1'}>
                                        <div className={'flex gap-2 items-center'}>
                                            <div
                                                className={'font-semibold leading-6 text-gray-900 mr-2'}>{member.name}</div>
                                            {member.is_owner && (
                                                <Tag>Owner</Tag>
                                            )}
                                            {member.is_admin && (
                                                <Tag>Admin</Tag>
                                            )}
                                        </div>
                                        <div className={'truncate text-sm leading-5 text-gray-500'}>{member.email}</div>
                                    </div>
                                    <Dropdown
                                        trigger={['click']}
                                        placement={'bottomRight'}
                                        overlay={(
                                            <Menu onClick={({key}) => {
                                                setActionMember(member)
                                                setShowActionAlert(key)
                                            }}>
                                                {(team.self.is_owner && member.is_owner) && (
                                                    <MenuItem eventKey={MEMBER_ACTION_KEYS.AS_OWNER}>
                                                        Transfer Ownership
                                                    </MenuItem>
                                                )}
                                                {(team.self.is_owner && !member.is_admin) && (
                                                    <MenuItem eventKey={MEMBER_ACTION_KEYS.AS_ADMIN}>
                                                        As an admin
                                                    </MenuItem>
                                                )}
                                                {(team.self.is_owner && member.is_admin && !member.is_owner) && (
                                                    <MenuItem eventKey={MEMBER_ACTION_KEYS.AS_MEMBER}>
                                                        As a member
                                                    </MenuItem>
                                                )}
                                                {((team.self.is_admin && !member.is_admin) || (team.self.is_owner && member.is_admin && !member.is_owner)) && (
                                                    <MenuItem eventKey={MEMBER_ACTION_KEYS.DEACTIVATE}
                                                              className={'text-danger'}>
                                                        <div>Deactivate</div>
                                                    </MenuItem>
                                                )}
                                            </Menu>
                                        )}>
                                        <button
                                            className={'rounded p-1 hover:border hover:border-stone-300 hover:bg-white text-xl'}>
                                            <BsThreeDots/>
                                        </button>
                                    </Dropdown>
                                </div>
                            ))
                        }
                    </div>
                )}
            </SimpleScroll>

            {actionMember && showActionAlert === MEMBER_ACTION_KEYS.AS_OWNER && (
                <TransferTeamOwnershipModal isOpen={true} onRequestClose={() => setShowActionAlert('')}/>
            )}

            {actionMember && showActionAlert === MEMBER_ACTION_KEYS.AS_ADMIN && (
                <ConfirmAlert
                    isOpen={true}
                    onRequestClose={() => setShowActionAlert('')}
                    confirming={submittingActionAlert === MEMBER_ACTION_KEYS.AS_ADMIN}
                    onConfirm={onAsAdmin}>
                    Set <b>{actionMember.name}</b> as an <b>admin</b> for the team
                </ConfirmAlert>
            )}

            {actionMember && showActionAlert === MEMBER_ACTION_KEYS.AS_MEMBER && (
                <ConfirmAlert
                    isOpen={true}
                    onRequestClose={() => setShowActionAlert('')}
                    confirming={submittingActionAlert === MEMBER_ACTION_KEYS.AS_MEMBER}
                    onConfirm={onAsMember}>
                    Set <b>{actionMember.name}</b> as an <b>regular member</b> for the team
                </ConfirmAlert>
            )}

            <ConfirmAlert isOpen={resetInviteLinkAlert}
                          label={'Reset invite link'}
                          confirmText={'Reset invite link'}
                          confirmColorScheme={'danger'}
                          onRequestClose={() => setShowResetInviteLinkAlert(false)}
                          confirming={resettingInviteLink}
                          onConfirm={onResetInviteLink}>
                To reset the team invitation link, please note that generating a new link will render the previous
                one invalid.
            </ConfirmAlert>

            {showInviteMemberModal && (
                <InviteTeamMemberModal
                    isOpen={showInviteMemberModal}
                    onRequestClose={() => setShowInviteMemberModal(false)}
                />
            )}
        </>
    )
}