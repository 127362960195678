import React from "react";
import {IconButton} from "../../stories/components/Button/src";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import {GoMention} from "react-icons/go";
import {Tooltip} from "../../stories/components/Tooltip/src";

export default function MentionBtn({...props}) {
    return (
        <div className={'IconBtn'} {...props}>
            <Tooltip content={'Mention Someone'}>
            <IconButton variant={'ghost'} size={'sm'} colorScheme={'secondary'}>
                <SvgIcon icon={GoMention}/>
            </IconButton>
            </Tooltip>
        </div>
    )
}