import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import $api from "../../api";

export const fetchWorkspaceInfo = createAsyncThunk('fetchWorkspaceInfo', async (data = {}, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    try {
        return await $api.workspace.view(data)
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchWorkspaceMessages = createAsyncThunk('fetchWorkspaceMessages', async (data = {}, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    try {
        return await $api.workspace.messages(data)
    } catch (err) {
        return rejectWithValue(err)
    }
})

const initialState = {
    workspaces: {},
    workspaceRequests: {},
    messages: {},
    members: {},
}

const persistenceSlice = createSlice({
    name: "persistence",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchWorkspaceInfo.fulfilled, (state, action) => {
            const {workspace_id} = action.meta.arg
            state.workspaces[workspace_id] = action.payload
        });
        builder.addCase(fetchWorkspaceMessages.fulfilled, (state, action) => {
            const {workspace_id} = action.meta.arg
            state.messages[workspace_id] = action.payload
        });
    }
})

export const {} = persistenceSlice.actions;

export default persistenceSlice.reducer;