import * as user from "./user"
import * as team from "./team"
import * as workspace from "./workspace"

const $api = {
    user,
    team,
    workspace,
}

export default $api