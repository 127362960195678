import Dropdown from "rc-dropdown/es";
import {Button} from "../../../stories/components/Button/src";
import classNames from "classnames";
import {BsChevronDown, BsSortDown} from "react-icons/bs";
import React from "react";
import Menu, {Item as MenuItem} from "rc-menu/es";
import {Divider} from "../../../stories/components/Divider/src";

const SortMenu = () => {
    function onSortMenuClick({key}: { key: any }) {
        console.log(`${key} selected`);
    }

    return (
        <Menu onSelect={onSortMenuClick} style={{width: '150px'}}>
            <MenuItem eventKey={'name'}>
                <div className={'label'}>名称</div>
            </MenuItem>
            <MenuItem eventKey={'size'}>
                <div className={'label'}>大小</div>
            </MenuItem>
            <MenuItem eventKey={'modified_at'}>
                <div className={'label'}>修改时间</div>
            </MenuItem>
            <Divider/>
            <MenuItem>
                <div className={'label'}>升序</div>
            </MenuItem>
            <MenuItem>
                <div className={'label'}>降序</div>
            </MenuItem>
        </Menu>
    );
}

export default function SortButton() {
    return (
        <Dropdown
            trigger={['click']}
            overlay={SortMenu}
        >
            <Button colorScheme={'light'}
                    variant={'outline'}
                    className={classNames( 'px-2')}
                    renderPrefix={<BsSortDown style={{width: '20px', height: '20px'}}/>}
                    renderSuffix={<BsChevronDown style={{width: '16px', height: '16px'}}/>}
            >
                排序
            </Button>
        </Dropdown>
    )
}