import React from "react";
import {BsChevronRight, BsArrowClockwise} from "react-icons/bs";
import {Row} from "../../stories/components/Layout/src";
import classNames from "classnames";
import styles from "./index.module.scss"
import {Button, IconButton} from "../../stories/components/Button/src";

function PathItem({item, showSeparator = true}) {
    return (
        <div className={styles.PathItem}>
            <div className={classNames(styles.label)}
                 title={item.name}
            >
                {item.name}
            </div>
            {showSeparator && (
                <div className={styles.separator}>
                    <BsChevronRight/>
                </div>
            )}
        </div>
    )
}

export default function DocumentSpacePathBar({items, size = 'md', ...props}) {

    return (
        <Row className={classNames(styles.DocumentSpacePathBar,styles[size],props.className)}>
            {items.map((item, index) =>
                <PathItem
                    key={index}
                    item={item}
                    showSeparator={index < items.length - 1}
                />
            )}
        </Row>
    )
}