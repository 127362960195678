import {ModalHeader} from "./ModalHeader";
import SelectTeamMember from "../SelectTeamMember";
import {ErrorMsg} from "../ui";
import {Input} from "../../stories/components/Input/src";
import {Button} from "../../stories/components/Button/src";
import BaseModal from "./BaseModal";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import $api from "../../api";
import toast from "react-hot-toast";
import {setOwner} from "../../store/reducers/team";


export function TransferTeamOwnershipModal({
                                               isOpen,
                                               onRequestClose,
                                           }) {
    const {team} = useSelector(state => state)
    const [selectedNewTeamOwner, setSelectedNewTeamOwner] = useState(null)
    const [loginPassword, setLoginPassword] = useState('')
    const [passwordErrMsg, setPasswordErrMsg] = useState('');
    const [submitting, setSubmitting] = useState(false)
    const dispatch = useDispatch();

    async function onAsOwner() {
        try {
            setSubmitting(true)
            setPasswordErrMsg('')
            await $api.team.asOwner( {
                team_id:team.id,
                user_id: selectedNewTeamOwner.user_id,
                password: loginPassword
            })
            dispatch(setOwner(selectedNewTeamOwner.user_id))
            toast.success(`${selectedNewTeamOwner.name} has been set up as a team owner`)
            onRequestClose && onRequestClose()
        } catch (err) {
            if (err.code === 1004) {
                setPasswordErrMsg(err.message)
            } else {
                toast.error('Failed to set up team owner')
            }
        }
        setSubmitting(false)
    }

    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <ModalHeader label={'Transfer Ownership'} onRequestClose={onRequestClose}/>
            <div className={'w-[500px] h-[400px] flex flex-col gap-3 px-12 py-4'}>
                <div className={'px-4 py-3 bg-stone-100 rounded-lg mt-2'}>
                    The new team owner will have ultimate authority over the <b>{team.name}</b> team.
                </div>
                <div className={''}>
                    <div className={'py-2'}>New Team Owner</div>
                    <SelectTeamMember value={selectedNewTeamOwner} onChange={setSelectedNewTeamOwner}/>
                </div>
                <div>
                    <div className={'py-2'}>Login Password</div>
                    <Input type={"password"}
                           value={loginPassword}
                           onChange={e => setLoginPassword(e.target.value)}
                    />
                    <ErrorMsg className={''}>{passwordErrMsg}</ErrorMsg>
                </div>
                <Button className={'my-6'}
                        colorScheme={(selectedNewTeamOwner && loginPassword) ? 'primary' : 'info'}
                        onClick={onAsOwner}
                        disabled={!selectedNewTeamOwner || !loginPassword}
                        loading={submitting}
                >
                    Transfer Team Ownership
                </Button>
            </div>
        </BaseModal>
    )
}