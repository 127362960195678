import styles from "../index.module.scss";
import UserAvatar from "../../../UserAvatar";
import {Button} from "../../../../stories/components/Button/src";
import React, {createRef, useEffect, useRef, useState} from "react";
import {Col, Row} from "../../../../stories/components/Layout/src";
import Dropdown from "rc-dropdown/es";
import Menu, {Item as MenuItem} from "rc-menu/es";
import {SvgIcon} from "../../../../stories/components/SvgIcon/src";
import {BsUpload} from "react-icons/bs";
import {Avatar} from "../../../../stories/components/Avatar/src";
import classNames from "classnames";
import {useDropzone} from 'react-dropzone';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {toast} from "react-hot-toast";
import {Alert} from "../../../../stories/components/Alert/src";
import api from "../../../../lib/axios";
import {Spinner} from "../../../../stories/components/Spinner/src";
import {useSelector, useDispatch} from "react-redux";
import {setAvatarUrl, uploadAvatar} from "../../../../store/reducers/user";
import $api from "../../../../api";
import ContentLoader, {Code} from "react-content-loader";
import MyAvatar from "../../../MyAvatar";

const Mode = {
    default: '',
    choose: 'ChooseSystem',
    upload: 'Upload',
    tooBigError: 'tooBigError',
}

const MaxUploadSize = 5120000 //  bytes

function Label() {
    return (
        <div className={styles.ItemLabel}>Avatar</div>
    )
}

function TooBigError({onCancel, onReselectImage}) {
    return (
        <>
            <Label/>
            <Alert showIcon={false}>
                <div className={styles.TooBigErrorBox}>
                    <div className={styles.emoji}>
                        😟
                    </div>
                    <div className={styles.tips}>
                        That’s a very nice image, but it’s a bit too big. Try one that’s smaller 5MB.
                    </div>
                    <Row className={'my-4'}>
                        <Button variant={'solid'}
                                colorScheme={'primary'}
                                size={'sm'}
                                onClick={onReselectImage}
                        >
                            Try a Different Image
                        </Button>
                        <Button variant={'outline'}
                                colorScheme={'light'}
                                size={'sm'}
                                className={'mx-4'}
                                onClick={onCancel}>
                            Cancel
                        </Button>
                    </Row>
                </div>
            </Alert>

        </>
    )
}


function ChooseSystemAvatar({onCancel}) {
    const {avatar_url} = useSelector((state) => state.user);
    const [checkedSystemAvatarUrl, setCheckedSystemAvatarUrl] = useState('')
    const [systemAvatars, setSystemAvatars] = useState([])
    const [loadSystemAvatarsStatus, setLoadSystemAvatarsStatus] = useState('')
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState('')
    const dispatch = useDispatch();

    useEffect(() => {
        onLoadSystemAvatars()
    }, [])

    const onSave = async () => {
        if (!checkedSystemAvatarUrl) {
            setError('Please select an avatar')
            return
        }
        setSaving(true)
        try {
            const {avatar_url} = await $api.user.setSystemAvatar(checkedSystemAvatarUrl)
            dispatch(setAvatarUrl(avatar_url))
            toast.success('Avatar has been updated');
            onCancel()
        } catch (e) {
            setError(e.message)
        }
        setSaving(false)
    }
    const onLoadSystemAvatars = async () => {
        setLoadSystemAvatarsStatus('loading')
        try {
            const avatars = await $api.user.getSystemAvatars()
            setLoadSystemAvatarsStatus('success')
            setSystemAvatars(avatars)
        } catch (e) {
            setLoadSystemAvatarsStatus('failed')
        }
    }

    return (
        <>
            <Label/>
            <div className={styles.ItemRow} style={{paddingTop: 0}}>
                <UserAvatar id={1} src={checkedSystemAvatarUrl || avatar_url} name={'Fred'} size={'2xl'}/>
                <Row style={{justifyContent: 'flex-end'}}>
                    <Button variant={'outline'} colorScheme={'secondary'} size={'sm'}
                            onClick={onCancel}>Cancel</Button>
                    <Button className={'ms-4'} size={'sm'} loading={saving} onClick={onSave}>Save</Button>
                </Row>
            </div>
            <div className={classNames('w-full text-sm mt-2 flex flex-row justify-between')}>
                <div className={'text-stone-600'}>Choose from system</div>
                {error && (<div className={'text-rose-500'}>{error}</div>)}
            </div>
            <div className={'min-h-[60px]'}>
                {loadSystemAvatarsStatus === 'loading' && (
                    <div className={'py-2'}>
                        <ContentLoader
                            width={'100%'}
                            viewBox="0 0 1000 100"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                            style={{width: '100%'}}
                        >
                            {new Array(12).fill(0).map((v, k) => (
                                <rect key={k} x={(k * 80)} y="0" rx="5" ry="5" width="60" height="60"/>
                            ))}
                        </ContentLoader>
                    </div>
                )}
                {loadSystemAvatarsStatus === 'success' && (
                    <div className={styles.ChooseAvatarGird}>
                        {systemAvatars.slice(0, 12).map((url, index) => (
                            <div key={index}
                                 className={classNames(styles.Option, checkedSystemAvatarUrl === url && styles.Actived)}
                                 onClick={() => setCheckedSystemAvatarUrl(url)}
                            >
                                <Avatar src={url} size={'lg'}/>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

function CropImage({image, onReselectImage, onCancel}) {
    const cropperRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)
    const dispatch = useDispatch();

    const onSave = async () => {
        setSubmitting(true)
        const cropper = cropperRef.current?.cropper;
        cropper.getCroppedCanvas({
            width: 300,
            height: 300,
        }).toBlob(async blob => {
            const formData = new FormData();
            formData.append('avatar', blob, 'avatar.png');
            $api.user.uploadAvatar(formData).then(res => {
                dispatch(setAvatarUrl(res.avatar_url))
                toast.success('Avatar has been updated');
                onCancel()
            }).catch(err => {
                toast.error('Avatar upload failed');
            }).finally(() => {
                setSubmitting(false)
            })
        }, 'image/png');
    }

    return (
        <>
            <Label/>
            <div className={styles.ItemRow} style={{paddingTop: 0, alignItems: 'normal'}}>

                <div>
                    <Cropper
                        style={{height: 260, width: 260}}
                        zoomTo={0.5}
                        zoomable={false}
                        zoomOnTouch={false}
                        zoomOnWheel={false}
                        wheelZoomRatio={false}
                        cropBoxResizable={false}
                        responsive={true}
                        center={false}
                        dragMode={'none'}
                        initialAspectRatio={1}
                        preview="#userAvatarCropPreview"
                        src={URL.createObjectURL(image)}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        ref={cropperRef}
                        onInitialized={(instance) => {

                        }}
                        guides={true}
                    />
                    <Button variant={'solid'}
                            colorScheme={'secondary'}
                            size={'sm'}
                            className={'my-sm'}
                            isBlock
                            onClick={onReselectImage}
                    >
                        Try a Different Image
                    </Button>
                </div>
                <Col style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <div id={'userAvatarCropPreview'} className={classNames('', styles.userAvatarCropPreview)}/>
                    <Row style={{marginTop: '30px'}}>
                        <Button variant={'outline'}
                                colorScheme={'secondary'}
                                size={'sm'}
                                className={'me-4'}
                                onClick={onCancel}>
                            Cancel
                        </Button>

                        <Button size={'sm'} disabled={submitting} onClick={onSave}>
                            {submitting && (<Spinner size={'xs'} colorScheme={'white'} style={{marginRight: '4px'}}/>)}
                            Save
                        </Button>
                    </Row>
                </Col>
            </div>
        </>
    )
}

export function AvatarSection() {
    const [mode, setMode] = useState('')
    const [image, setImage] = useState(null)

    const {getRootProps, getInputProps, open: onOpenFileManager, acceptedFiles} = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        multiple: false,
        accept: {
            'image/*': ['.jpg', '.jpeg', '.png'],
        },
        // maxSize:1,  //  bytes
        onDrop: acceptedFiles => {
            if (acceptedFiles?.length === 1) {
                let image = acceptedFiles[0]
                if (image.size > MaxUploadSize) {
                    setMode(Mode.tooBigError)
                    return
                }
                setImage(image)
                setMode(Mode.upload)
            }
        }
    });

    function onChangeAvatarMenuClick({key}) {
        switch (key) {
            case Mode.choose:
                setMode(Mode.choose)
                break
            case Mode.upload:
                onOpenFileManager()
                break
        }
    }


    function onCancel() {
        setMode('')
    }

    if (mode === Mode.tooBigError) return <TooBigError onReselectImage={onOpenFileManager} onCancel={onCancel}/>
    if (mode === Mode.choose) return <ChooseSystemAvatar onCancel={onCancel}/>
    if (mode === Mode.upload) return (<CropImage
        image={image}
        onReselectImage={onOpenFileManager}
        onCancel={onCancel}/>)

    return (
        <>
            <Label/>
            <div className={styles.ItemRow} style={{paddingTop: 0, paddingBottom: '5px'}}>
                <MyAvatar size={'xl'}/>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                </div>

                <Dropdown
                    placement={'bottomRight'}
                    trigger={['click']}
                    overlay={(
                        <Menu onClick={onChangeAvatarMenuClick}>
                            <MenuItem eventKey={Mode.choose}>
                                <div className={'label'}>Choose from system</div>
                            </MenuItem>
                            <MenuItem eventKey={Mode.upload}>
                                <div className={'label'}>Upload local image</div>
                            </MenuItem>
                        </Menu>
                    )}
                >
                    <Button colorScheme={'secondary'} variant={'outline'} size={'sm'}>
                        Change Avatar
                    </Button>
                </Dropdown>
            </div>
        </>
    )
}