import React, {useState} from "react";
import styles from "../index.module.scss";
import {Button} from "../../../../stories/components/Button/src";
import {Input} from "../../../../stories/components/Input/src";
import {Row} from "../../../../stories/components/Layout/src";
import UpdatePasswordModal from "../../UpdatePasswordModal";

function Header() {
    return (
        <>
            <div className={styles.ItemLabel}>
                Password
            </div>
            <div className={styles.SubLabel}>
                If you forget your current password, you can <a className={'mx-1'}>reset it.</a>
            </div>
        </>
    )
}

export function LoginPasswordSection() {
    const [confirmedPassword, setConfirmedPassword] = useState(false)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState('')
    const [showConfirmPasswordModal, setShowConfirmPasswordModal] = useState(false)

    const onSave = (event) => {
        event.preventDefault()
    }

    const Preview = (
        <>
            <div>
                <Header/>
            </div>
            <Button
                colorScheme={'secondary'}
                variant={'outline'}
                size={'sm'}
                onClick={() => setShowConfirmPasswordModal(true)}
            >
                Change Password
            </Button>
            <UpdatePasswordModal
                isOpen={showConfirmPasswordModal}
                onRequestClose={() => setShowConfirmPasswordModal(false)}
                onConfirmed={() => setConfirmedPassword(true)}
            />
        </>
    )

    const Form = (
        <div>
            <Header/>

            <div className={'pt-2 max-w-[200px]'}>
                <form onSubmit={onSave}>
                    <Input placeholder={'Current Password'} type={'password'} className={'mb-4'}/>
                    <Input placeholder={'New Password'} type={'password'} className={'mb-4'}/>
                    <Row>
                        <Button className={'me-4'} size={'sm'}>Save</Button>
                        <Button type={'submit'} variant={'outline'} colorScheme={'secondary'} size={'sm'}
                                onClick={() => setConfirmedPassword(false)}>Cancel</Button>
                    </Row>
                </form>
            </div>
        </div>
    )

    return (
        <div className={styles.ItemRow}>
            {confirmedPassword ? Form : Preview}
        </div>
    )
}