import React, {ReactNode, useState} from 'react';
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

interface InputProps extends React.HTMLAttributes<HTMLElement> {
    /**
     * Optional One or more button variant combinations.
     */
    colorScheme?: 'light' | 'secondary' | 'tertiary';
    /**
     * Optional One or more button variant combinations.
     */
    variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
    /**
     * Optional Defines HTML button type attribute.
     */
    type?: 'text' | 'email' | 'number' | 'password' | 'search' | 'date' | 'datetime-local';
    /**
     * Optional How large should the button be?
     */
    size?: 'sm' | 'md' | 'lg';
    /**
     * Optional Defines HTML input value attribute.
     */
    value?: string;
    /**
     * Optional Defines HTML input defaultValue attribute.
     */
    defaultValue?: string;
    /**
     * Optional Defines HTML input placeholder attribute.
     */
    placeholder?: string;
    /**
     * Optional Defines HTML input disabled attribute.
     */
    disabled?: boolean;
    readOnly?: boolean;
    isError?: boolean;
    autoFocus?: boolean;

    renderPrefix?: ReactNode
    renderSuffix?: ReactNode

    /**
     * Optional blur handler
     */
    onChange?: (event: any) => void;
    /**
     * Optional focus handler
     */
    onFocus?: (event: any) => void;
    /**
     * Optional blur handler
     */
    onBlur?: (event: any) => void;
    /**
     * Optional changeText handler
     */
    onChangeText?: (text: string) => void;

    [propName: string]: any;
}

/**
 * Primary UI component for user interaction
 */
export const Input = ({
                          variant = 'outline',
                          colorScheme = 'light',
                          isError = false,
                          className = '',
                          renderPrefix = null,
                          renderSuffix = null,
                          rounded = false,
                          size = 'md',
                          ...props
                      }: InputProps) => {
    const cls = classNames([`${UIPrefix}form-input`, `variant-${variant}`, `size-${size}`, rounded && 'rounded', isError && 'error', className])

    return (
        <div className={cls}>
            {renderPrefix && <div className={'prefix'}>{renderPrefix}</div>}
            <input
                style={{
                    paddingLeft: renderPrefix ? 0 : undefined,
                    paddingRight: renderSuffix ? 0 : undefined,
                }}
                {...props}
            />
            {renderSuffix && <div className={'suffix'}>{renderSuffix}</div>}
        </div>
    );
}
