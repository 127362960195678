import BaseModal from "./BaseModal";
import React, {useState} from "react";
import {ModalHeader} from "./ModalHeader";
import {Input, TextArea} from "../../stories/components/Input/src";
import {ErrorMsg, SubmitButton} from "../ui";
import * as Yup from "yup";
import {fetchSaveTeamGroup} from "../../store/reducers/teamGroup";
import {toast} from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import WorkspaceIcon from "../WorkspaceIcon";
import {fetchCreateWorkspace} from "../../store/reducers/workspace";
import {useNavigate} from "react-router";

export function CreateWorkspaceModal({onRequestClose, isOpen}) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const team = useSelector(state => state.team)
    const [submitting, setSubmitting] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [form, setForm] = useState({
        name: '',
        description: '',
    })
    const disabled = !form.name.trim()

    const formValidationSchema = Yup.object().shape({
        name: Yup.string().max(80).required('Please fill workspace name.'),
    });

    const onInputChange = event => {
        const name = event.target.name
        const value = event.target.value
        const newForm = {...form}
        newForm[name] = value
        setForm(newForm)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setErrMsg('')
        setSubmitting(true)
        try {
            await formValidationSchema.validate(form, {abortEarly: true})
            const result = await dispatch(fetchCreateWorkspace(form)).unwrap()
            toast.success('The workspace is created successfully')
            navigate(`/workspace/${result.id}`);
            onRequestClose && onRequestClose()
        } catch (err) {
            setErrMsg(err.message)
        }
        setSubmitting(false)
    }

    return (
        <BaseModal onRequestClose={onRequestClose} isOpen={isOpen} className={'w-[460px]'}>
            <ModalHeader label={'Create a workspace'} onRequestClose={onRequestClose}/>
            <form onSubmit={onSubmit} className={'px-8 flex flex-col gap-4 py-4'}>
                <div>
                    <label htmlFor="name" className={'py-2 font-medium'}>Name</label>
                    <Input
                        id={'name'}
                        name={'name'}
                        maxLength={50}
                        renderPrefix={<WorkspaceIcon className={'mx-2 text-stone-600'}/>}
                        onChange={onInputChange}
                    />
                    <ErrorMsg>{errMsg}</ErrorMsg>
                </div>
                <SubmitButton loading={submitting} disabled={disabled} className={'my-4'}>
                    Create
                </SubmitButton>
            </form>
        </BaseModal>
    )
}