import React, {useEffect, useRef, useState} from "react";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import {BsEmojiSmile, BsFolderPlus, BsCursorFill} from "react-icons/bs";
import {GoMention} from "react-icons/go";
import {Button, IconButton} from "../../stories/components/Button/src";
import EmojiPickerBubbleModal from "../BubbleModal/EmojiPickerBubbleModal";
import "./index.scss"
import EmojiBtn from "./EmojiBtn";
import MentionBtn from "./MentionBtn";
import FileBtn from "./FileBtn";
import {MentionsInput, Mention} from 'react-mentions';
import mentionCls from './mention.module.scss'
import UserAvatar from "../UserAvatar";
import RecordBtn from "./RecordBtn";

const users = [
    {
        id: 'zellzhu',
        display: 'Zell Zhu',
        username: 'zellzhu',
    },
    {
        id: 'maxgao',
        display: 'Max Gao',
        username: 'maxgao',
    },
    {
        id: 'fredmanxu',
        display: 'Fred Xu',
        username: 'fredmanxu',
    },
    {
        id: 'user1',
        display: 'User 1',
        username: 'user1',
    },
]

function BtnSeparator() {
    return (
        <div className={'BtnSeparator'}/>
    )
}


function MentionSuggestionItem(entry, search, highlightedDisplay, index, focused) {

    return (
        <div className={mentionCls.MentionSuggestionItem}>
            <UserAvatar id={entry.id} name={entry.display} size={'xs'}/>
            <div className={mentionCls.name}>
                {entry.display}
            </div>
        </div>
    )
}

export default function ChatMessageInputer() {
    const [inputValue, setInputValue] = useState('')
    let inputRef = useRef(null)

    function onFocusInput(event) {
        inputRef.current.focus()
    }

    function onBlur(event) {

    }

    function onSend(event) {

    }

    function onInputChange(event) {
        let value = event.target.value
        setInputValue(value)
    }

    function onEmojiSelect(emoji) {
        setInputValue(inputValue + emoji.native)
        onFocusInput()
        console.log(emoji)
    }

    function onOpenMentionSuggestion() {
        setInputValue(inputValue + '@')
        onFocusInput()
    }

    let container

    return (
        <div className={'ChatMessageInputer'} ref={el => {
            container = el
        }}>

            <div className={'Header'}>
                <EmojiBtn onSelect={onEmojiSelect}/>
                <MentionBtn onClick={onOpenMentionSuggestion}/>
                <FileBtn/>
                <RecordBtn/>
                {/*<BtnSeparator/>*/}
            </div>

            <MentionsInput
                inputRef={inputRef}
                value={inputValue}
                onBlur={onBlur}
                onChange={onInputChange}
                a11ySuggestionsListLabel={"Suggested mentions"}
                classNames={mentionCls}
                placeholder={'Type message'}
                suggestionsPortalHost={container}
                forceSuggestionsAboveCursor={true}

            >
                <Mention
                    trigger="@"
                    data={users}
                    markup="@__display__"
                    regex={/@(\S+)/}
                    displayTransform={(username) => `@${username}`}
                    renderSuggestion={MentionSuggestionItem}
                    appendSpaceOnAdd
                />
            </MentionsInput>

            <div className={'Footer'} onClick={onFocusInput}>
                <div>

                </div>
                <Button colorScheme={'success'}
                        className={'sendBtn'}
                        renderPrefix={<SvgIcon icon={BsCursorFill} size={20}/>}
                        onClick={onSend}
                >
                    Send
                </Button>
            </div>
        </div>
    )
}