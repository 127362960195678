import React, {useState} from "react";
import ReactModal from "react-modal";
import {ModalHeader} from "../ModalHeader";
import {Input} from "../../../stories/components/Input/src";
import {ErrorText} from "../../../stories/components/Text/src";
import classNames from "classnames";
import {Row} from "stories/components/Layout";
import {Button} from "../../../stories/components/Button";
import {toast} from "react-hot-toast";
import BaseModal from "../BaseModal";
import styles from "./index.module.scss"

interface Props extends ReactModal.Props {
    onRequestClose: () => void
}

export default function CreateFolderModal({
                                              isOpen,
                                              onAfterOpen,
                                              onRequestClose,
                                              style,
                                              ...props
                                          }: Props) {

    const [value, setValue] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    //  " * : < > ? / \ |
    var regex = /[\" \* \: \< \> \? \/ \\ \|]+/;

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let text = event.target.value
        setValue(text)
        if (regex.test(text)) {
            setErrorMessage('文件夹名称不能包含: " * : < > ? / \\ |')
        } else {
            setErrorMessage('')
        }
    }

    const onSubmit = () => {
        if (!value || !value.trim()) {
            setErrorMessage('请输入文件夹名称')
            return
        }
        if (regex.test(value)) return
        setErrorMessage('')
        setIsSubmitting(true)
        setTimeout(() => {
            toast.success("文件夹已创建", {duration: 2000})
            onRequestClose()
        }, 1000)
    }

    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.Modal}
        >
            <ModalHeader label={'新建文件夹'} onRequestClose={onRequestClose}/>
            <div className={classNames('px-6', 'mt-6')}>
                <Input
                    autoFocus
                    placeholder={'文件夹名称'}
                    isError={errorMessage !== ''}
                    onChange={onInputChange}
                />

                <div style={{height: '30px'}}>
                    {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
                </div>

                <Row className={classNames('py-4', 'mb-4')} style={{justifyContent: 'flex-end'}}>
                    <Button
                        loading={isSubmitting}
                        onClick={onSubmit}
                    >
                        创建
                    </Button>
                </Row>
            </div>
        </BaseModal>
    )
}