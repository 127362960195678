import Dropdown from "rc-dropdown/es";
import {Button} from "../../../stories/components/Button/src";
import classNames from "classnames";
import {BsChevronDown, BsPlus} from "react-icons/bs";
import React, {useState} from "react";
import Menu, {Item as MenuItem} from "rc-menu/es";
import FolderIcon from "../../FileIcon/FolderIcon";
import {Divider} from "../../../stories/components/Divider/src";
import {FileIcon} from "../../FileIcon";
import CreateFolderModal from "../../Modal/CreateFolderModal";
import {SvgIcon} from "../../../stories/components/SvgIcon/src";


const CreateMenu = (onCreateFolder: () => void) => {

    function onCreateMenuClick({key}: { key: any }) {
        switch (key) {
            case "folder":
                onCreateFolder()
                break;
        }
    }

    return (
        <Menu onClick={onCreateMenuClick} style={{width: 'max-content'}}>
            <MenuItem eventKey={'folder'}>
                <FolderIcon/>
                <div className={'label'}>文件夹</div>
            </MenuItem>
            <Divider my={'xs'}/>
            <MenuItem eventKey={'word'}>
                <FileIcon filename={'new.docx'}/>
                <div className={'label'}>Word 文档</div>
            </MenuItem>
            <MenuItem eventKey={'excel'}>
                <FileIcon filename={'new.xlsx'}/>
                <div className={'label'}>Excel 工作簿</div>
            </MenuItem>
            <MenuItem eventKey={'ppt'}>
                <FileIcon filename={'new.pptx'}/>
                <div className={'label'}>PowerPoint 演示文稿</div>
            </MenuItem>
            <MenuItem eventKey={'markdown'}>
                <FileIcon filename={'new.md'}/>
                <div className={'label'}>笔记</div>
            </MenuItem>
        </Menu>
    );
}

export default function CreateButton() {
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)

    function onSwitchCreateFolderModal() {
        setShowCreateFolderModal(!showCreateFolderModal)
    }

    return (
        <>
            <Dropdown
                trigger={['click']}
                overlay={CreateMenu(onSwitchCreateFolderModal)}
            >
                <Button
                        renderPrefix={<SvgIcon icon={BsPlus} size={26}/>}
                        renderSuffix={<SvgIcon icon={BsChevronDown} size={16}/>}
                >
                    新建
                </Button>
            </Dropdown>

            {
                showCreateFolderModal&&(
                    <CreateFolderModal
                        isOpen={showCreateFolderModal}
                        onRequestClose={onSwitchCreateFolderModal}
                    />
                )
            }

        </>
    )
}