import React from "react";
import {ReactComponent as PhotoSvg} from '../../assets/file-icon/photo.svg'
import {ReactComponent as PdfSvg} from '../../assets/file-icon/pdf.svg'
import {ReactComponent as XlsxSvg} from '../../assets/file-icon/xlsx.svg'
import {ReactComponent as CsvSvg} from '../../assets/file-icon/csv.svg'
import {ReactComponent as DocxSvg} from '../../assets/file-icon/docx.svg'
import {ReactComponent as GenericfileSvg} from '../../assets/file-icon/genericfile.svg'
import {ReactComponent as HtmlSvg} from '../../assets/file-icon/html.svg'
import {ReactComponent as TxtSvg} from '../../assets/file-icon/txt.svg'
import {ReactComponent as CodeSvg} from '../../assets/file-icon/code.svg'
import {ReactComponent as AccdbSvg} from '../../assets/file-icon/accdb.svg'
import {ReactComponent as PptxSvg} from '../../assets/file-icon/pptx.svg'
import {ReactComponent as ZipSvg} from '../../assets/file-icon/zip.svg'
import {ReactComponent as OneOcSvg} from '../../assets/file-icon/onetoc.svg'
import {ReactComponent as FormSvg} from '../../assets/file-icon/form.svg'
import {ReactComponent as VideoSvg} from '../../assets/file-icon/video.svg'
import {ReactComponent as AudioSvg} from '../../assets/file-icon/audio.svg'
import {ReactComponent as EmailSvg} from '../../assets/file-icon/email.svg'
import {ReactComponent as ExeSvg} from '../../assets/file-icon/exe.svg'
import {ReactComponent as FontSvg} from '../../assets/file-icon/font.svg'
import {ReactComponent as ApkSvg} from '../../assets/file-icon/apk.svg'
import {ReactComponent as SysfileSvg} from '../../assets/file-icon/sysfile.svg'
import * as mime from 'mime'

export const IconSizes = {
    sm: 18,
    md: 24,
    lg: 32,
    xl: 48
}

interface Props {
    filename: string
    size?: keyof typeof IconSizes
}

export const FileIcon = ({
                             filename = '1.txt',
                             size = 'md',
                         }: Props) => {
    let styles = {width: IconSizes[size], height: IconSizes[size]}
    const extension = getExtension(filename)

    switch (extension) {
        case "pdf":
            return <PdfSvg style={styles}/>
        case "doc":
        case "docx":
            return <DocxSvg style={styles}/>
        case "xlsx":
            return <XlsxSvg style={styles}/>
        case "html":
            return <HtmlSvg style={styles}/>
        case "csv":
            return <CsvSvg style={styles}/>
        case "accdb":
            return <AccdbSvg style={styles}/>
        case "pptx":
            return <PptxSvg style={styles}/>
        case "form":
            return <FormSvg style={styles}/>
        case "oneoc":
            return <OneOcSvg style={styles}/>
        case "zip":
        case "rar":
        case "tar":
        case "tz":
            return <ZipSvg style={styles}/>
        case "js":
        case "php":
        case "java":
            return <CodeSvg style={styles}/>
        case "apk":
            return <ApkSvg style={styles}/>
    }
    const {type} = getMimeType(filename)

    switch (type) {
        case "image":
            return <PhotoSvg style={styles}/>
        case "video":
            return <VideoSvg style={styles}/>
        case "audio":
            return <AudioSvg style={styles}/>
        case "message":
            return <EmailSvg style={styles}/>
        case "text":
            return <TxtSvg style={styles}/>
        case "application":
            return <ExeSvg style={styles}/>
        case "font":
            return <FontSvg style={styles}/>
    }

    return <GenericfileSvg style={styles}/>
}

function getExtension(filename: string): string {
    if (filename?.lastIndexOf(".") === -1) {
        return ''
    }
    var ext = filename?.substring(filename.lastIndexOf(".") + 1) || ''
    return ext.toLowerCase()
}

interface MimeTypeProps {
    type?: string,
    subType?: string
}

function getMimeType(filepath: string): MimeTypeProps {

    if (!filepath) {
        return {type: '', subType: ''}
    }
    let mimeType = mime.getType(filepath) || ''

    if (!mimeType) {
        return {type: '', subType: ''}
    }
    let type = mimeType.split('/')
    return {type: type[0], subType: type[1]}
}