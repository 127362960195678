import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import $api from "../../api";

const initialState = {
    id: '',
    name: '',
    avatar_url: '',
    settings: {},
    teams: [],
    enabled_two_factor: false,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setAvatarUrl: (state, action) => {
            state.avatar_url = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setTwoFactor: (state, action) => {
            state.enabled_two_factor = action.payload
        },
        setUserInfo: (state, action) => {
            const user = action.payload
            for (let key of Object.keys(user)) {
                if (Object.keys(initialState).indexOf(key)) {
                    state[key] = user[key]
                }
            }
        },
    },
    extraReducers: (builder) => {

    },
});
export const {
    setName,
    setAvatarUrl,
    setUserInfo,
    setTwoFactor,
} = userSlice.actions;
export default userSlice.reducer;