import Dropdown from "rc-dropdown/es";
import {Button} from "../../../stories/components/Button/src";
import classNames from "classnames";
import {BsChevronDown, BsFilterLeft} from "react-icons/bs";
import React from "react";
import Menu, {Item as MenuItem} from "rc-menu/es";
import {SvgIcon} from "../../../stories/components/SvgIcon/src";


const MenuList = () => {

    function onUploadMenuClick({key}: { key: any }) {
        console.log(`${key} selected`);
    }

    return (
        <Menu onSelect={onUploadMenuClick} style={{width: '150px'}}>
            <MenuItem eventKey={'all'}>
                <div className={'label'}>全部任务</div>
            </MenuItem>
            <MenuItem eventKey={'involved'}>
                <div className={'label'}>我参与的</div>
            </MenuItem>
            <MenuItem eventKey={'created'}>
                <div className={'label'}>我创建的</div>
            </MenuItem>
        </Menu>
    );
}

export default function FilterMenuButton() {
    return (
        <Dropdown
            trigger={['click']}
            overlay={MenuList}
        >
            <Button colorScheme={'light'}
                    variant={'ghost'}
                    className={classNames('px-4')}
                    renderPrefix={<SvgIcon icon={BsFilterLeft}/>}
            >
                全部任务
            </Button>
        </Dropdown>
    )
}