import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import UserAvatar from "../../UserAvatar";
import {Heading, Text} from "../../../stories/components/Text/src";
import {UserSettingModal} from "../UserSettingModal";
import {TeamSettingModal} from "../TeamSettingModal";
import {Divider} from "../../../stories/components/Divider/src";
import {Row} from "../../../stories/components/Layout/src";
import BaseModal from "../BaseModal";
import {TeamListContent} from "./TeamListContent";
import {BsChevronRight} from "react-icons/bs";
import styles from "./index.module.scss"
import $api from "../../../api";
import {toast} from "react-hot-toast";
import MyAvatar from "../../MyAvatar";
import {useSelector} from "react-redux";
import {TeamListModal} from "../TeamListModal";

const DefaultContentId = 'DefaultContentId'
const TeamListModalId = 'TeamListModalId'
const UserSettingModalId = 'UserSettingModal'
const TeamSettingModalId = 'TeamSettingModalId'

export const ProfileModal = ({isOpen = false, onAfterOpen, onRequestClose, ...props}) => {
    const [contentId, setContentId] = useState(DefaultContentId)
    const [showModalId, setShowModalId] = React.useState('');
    const {team,user} = useSelector((state) => state);

    const onLogout = async () => {
        onRequestClose()
        toast.loading('Logging out')
        try {
            await $api.user.logout()
            window.location.href = process.env.REACT_APP_LOGIN_URL
        } catch (e) {
            toast.error('operation failed')
        }
    }


    function DefaultContent({onRequestClose}) {

        return (
            <div>
                <Row className={classNames('p-2 pb-4')}>
                    <MyAvatar size={'lg'}/>
                    <div className={'px-2'} style={{flex: 1, width: 0}}>
                        <Heading size={'lg'} className={'mb-1'}>{user.name}</Heading>
                        <Text size={'sm'} line={1}>{team.name}</Text>
                    </div>
                </Row>
                <div className={classNames(styles.item)}
                     onClick={() => {
                         setShowModalId(UserSettingModalId)
                         onRequestClose()
                     }}>
                    设置
                </div>
                <div className={classNames(styles.item)}
                     onClick={() => {
                         setShowModalId(TeamListModalId)
                         onRequestClose()
                     }}>
                    <div className={'flex-1'}>
                        我的团队
                    </div>
                </div>
                {team.self.is_admin && (
                    <div className={classNames(styles.item)}
                         onClick={() => {
                             setShowModalId(TeamSettingModalId)
                             onRequestClose()
                         }}>
                        团队设置
                    </div>
                )}
                <Divider className={'my-2 mx-1'}/>
                <div className={classNames(styles.item)}
                     onClick={onLogout}>
                    退出登录
                </div>
            </div>
        )
    }

    return (
        <>
            <BaseModal
                isOpen={isOpen}
                onAfterOpen={onAfterOpen}
                onRequestClose={onRequestClose}
                isOverlay={false}
                contentCenter={false}
                className={styles.ProfileModal}
                overlayClassName={styles.ProfileModalOverlay}
                transition={''}
            >
                <DefaultContent onRequestClose={onRequestClose}/>
            </BaseModal>
            <UserSettingModal
                isOpen={showModalId === UserSettingModalId}
                onRequestClose={() => setShowModalId('')}
            />
            <TeamSettingModal
                isOpen={showModalId === TeamSettingModalId}
                onRequestClose={() => setShowModalId('')}
            />
            <TeamListModal
                isOpen={showModalId === TeamListModalId}
                onRequestClose={() => setShowModalId('')}
            />
        </>
    )
}