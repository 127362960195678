import React from "react";
import styles from "./index.module.scss"
import {BsFillChatFill} from "react-icons/bs";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import {ReactComponent as LogoSvg} from "../../assets/logo.svg"
import Logo from "../Logo";
import {useMatch} from "react-router";

export default function AppWelcome() {

    return (
        <div className={styles.AppWelcome}>
            <Logo width={120} bg={'#e9e9e9'} color={'#fff'}/>
        </div>
    )
}