import React, {useState} from "react";
import styles from "../../UserSettingModal/index.module.scss";
import HelpTipIcon from "../../../HelpTipIcon";
import classNames from "classnames";
import {Button} from "../../../../stories/components/Button/src";
import {Input} from "../../../../stories/components/Input/src";
import {Row} from "../../../../stories/components/Layout/src";
import TeamLogo from "../../../TeamLogo";
import Dropdown from "rc-dropdown/es";
import Menu, {Item as MenuItem} from "rc-menu/es";
import {SvgIcon} from "../../../../stories/components/SvgIcon/src";
import {IoChevronDown} from "react-icons/io5";
import {useDispatch, useSelector} from "react-redux";
import $api from "../../../../api";
import {fetchChangeTheme, setTheme} from "../../../../store/reducers/team";
import {toast} from "react-hot-toast";

const THEME_OPTIONS = ['green', 'cyan', 'orange', 'blue', 'pink', 'indigo', 'teal']

function ColorItem({theme}) {
    return (
        <div className={'flex items-center gap-3'}>
            <div className={classNames(styles.ThemeColorTag, styles[theme])}/>
            <div>{theme}</div>
        </div>
    )
}

export function TeamThemeSection() {
    const {team} = useSelector((state) => state);
    const dispatch = useDispatch()

    const onChange = async ({key: theme}) => {
        try {
            await dispatch(fetchChangeTheme(theme))
        } catch (e) {
            toast.error('Change theme failed')
        }
    }

    return (
        <div className={styles.ItemRow}>
            <div>
                <div className={styles.ItemLabel}>
                    Theme
                </div>
                <div className={styles.SubLabel}>
                    Sets the theme color for the current team.
                </div>
            </div>
            <Dropdown
                placement={'bottomRight'}
                trigger={['click']}
                overlay={(
                    <Menu onClick={onChange}>
                        <MenuItem eventKey={'default'}>
                            <ColorItem theme={'default'}/>
                        </MenuItem>
                        {THEME_OPTIONS.map((theme, index) => (
                            <MenuItem key={index} eventKey={theme}>
                                <ColorItem theme={theme}/>
                            </MenuItem>
                        ))}
                    </Menu>
                )}>
                <Button colorScheme={'secondary'} variant={'outline'} size={'sm'}
                        renderSuffix={<SvgIcon icon={IoChevronDown} size={18} color={'#999'}/>}>
                    <ColorItem theme={team.theme || 'default'}/>
                </Button>
            </Dropdown>
        </div>
    )
}