import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import UserAvatar from "../../components/UserAvatar";
import {ProfileModal} from "../../components/Modal/ProfileModal";
import {useSelector} from "react-redux";
import MyAvatar from "../../components/MyAvatar";

export default function ProfileSection({className, onClickCallback, ...props}) {
    const [showProfileModal, setShowProfileModal] = React.useState(false);
    const {user, team} = useSelector((state) => state);

    function onChangeShowProfileModal() {
        setShowProfileModal(!showProfileModal);
        onClickCallback && onClickCallback()
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    return (
        <>
            <div className={classNames(styles.ProfileSection, className)}
                 onClick={onChangeShowProfileModal}>
                <MyAvatar/>
                <div className={styles.content}>
                    <div className={styles.title}>{user.name}</div>
                    <div className={styles.subTitle}>{team.name}</div>
                </div>
            </div>
            <ProfileModal
                isOpen={showProfileModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={onChangeShowProfileModal}
            />
        </>
    )
}