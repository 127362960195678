import React from "react";
import classNames from "classnames";
import {Avatar, AvatarProps} from "../stories/components/Avatar/src";
import {ReactComponent as JingSvg} from "../assets/jing.svg";
import {BiCube} from "react-icons/bi";

interface Props extends AvatarProps {

}

export default function WorkspaceIcon({
                                          className,
                                          ...props
                                      }: Props) {
    // return (<BiCube className={className}/>)
    return (
        <JingSvg className={className}/>
    )
}
