import React from "react";
import classNames from "classnames";

export function ErrorMsg({className, children, ...props}) {
    if (!children) return null
    return (
        <div className={classNames('text-danger text-sm py-1', className)} {...props}>
            {children}
        </div>
    )
}