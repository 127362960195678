export default function PasswordStrengthBar({score}) {
    let Indicator = null, text = null
    if (score === 0) {
        Indicator = (
            <>
                <div
                    className={`flex-1 h-[4px] rounded-full bg-gray-200`}></div>
                <div className={`flex-1 h-[4px] rounded-full bg-gray-200`}></div>
                <div className={`flex-1 h-[4px] rounded-full bg-gray-200`}></div>
            </>
        )
    } else if (score <= 36) {
        text = (<span className={'text-rose-500'}>Very weak</span>)
        Indicator = (
            <>
                <div
                    className={`flex-1 h-[4px] rounded-full bg-rose-500`}></div>
                <div className={`flex-1 h-[4px] rounded-full bg-gray-200`}></div>
                <div className={`flex-1 h-[4px] rounded-full bg-gray-200`}></div>
            </>
        )
    } else if (score <= 50) {
        text = (<span className={'text-yellow-500'}>Moderate</span>)

        Indicator = (
            <>
                <div
                    className={`flex-1 h-[4px] rounded-full bg-yellow-500`}></div>
                <div className={`flex-1 h-[4px] rounded-full bg-yellow-500`}></div>
                <div className={`flex-1 h-[4px] rounded-full bg-gray-100`}></div>
            </>
        )
    } else {
        text = (<span className={'text-green-500'}>Good</span>)
        Indicator = (
            <>
                <div
                    className={`flex-1 h-[4px] rounded-full bg-green-500`}></div>
                <div className={`flex-1 h-[4px] rounded-full bg-green-500`}></div>
                <div className={`flex-1 h-[4px] rounded-full bg-green-500`}></div>
            </>
        )
    }

    return (
        <div className={' pt-2 h-6'}>
            <div className={'w-full flex flex-row gap-1'}>
                {Indicator}
            </div>
            {/*<div className={'text-end py-1'}>{text}</div>*/}
        </div>
    )
}