import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import $api from "../../api";

export const fetchTeam = createAsyncThunk('fetchTeam', async (team_id, thunkAPI) => {
    const {rejectWithValue} = thunkAPI
    try {
        return await $api.team.getInfo(team_id)
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchChangeTheme = createAsyncThunk('fetchChangeTheme', async (theme, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    try {
        return await $api.team.setTheme({team_id, theme})
    } catch (err) {
        return rejectWithValue(err)
    }
})

const initialState = {
    id: '',
    name: '',
    icon_url: '',
    cover_url: '',
    members: [],
    groups: [],
    self: {},
    invite: {},
    theme: '',
}

const teamSlice = createSlice({
    name: "team",
    initialState,
    reducers: {
        setTeam: (state, action) => {

        },
        setInvite: (state, action) => {
            state.name = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setTheme: (state, action) => {
            state.theme = action.payload
        },
        setMembers: (state, action) => {
            state.members = action.payload
        },
        setOwner: (state, action) => {
            const userId = action.payload
            const newMembers = [...state.members]
            const currentOwnerIndex = newMembers.findIndex(item => item.is_owner)
            newMembers.splice(currentOwnerIndex, 1, {
                ...newMembers[currentOwnerIndex],
                is_owner: false
            })
            const newOwnerIndex = newMembers.findIndex(item => item.user_id === userId)
            newMembers.splice(newOwnerIndex, 1, {
                ...newMembers[newOwnerIndex],
                is_owner: true
            })
            console.log('newMembers', newMembers)
            state.members = newMembers
        },
        setAdmin: (state, action) => {

        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTeam.fulfilled, (state, action) => {
            const team = action.payload
            for (let key of Object.keys(initialState)) {
                if (Object.keys(team).indexOf(key) > -1) {
                    state[key] = team[key]
                }
            }
        });
        builder.addCase(fetchChangeTheme.pending, (state, action) => {
            state.theme = action.meta.arg
        });
        builder.addCase(fetchChangeTheme.rejected, (state, action) => {
            state.theme = ''
        });
    },
});

export const {
    setTeam,
    setName,
    setTheme,
    setOwner,
    setAdmin,
    setMembers,
    setGroups,
} = teamSlice.actions;
export default teamSlice.reducer;