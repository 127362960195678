import React, {ReactNode, Fragment} from 'react';
import classNames from "classnames";
import {Button, ButtonProps} from "./Button";
import Tippy from '@tippyjs/react';

interface IconButtonProps extends ButtonProps {
    tips?: string
}

/**
 * Primary UI component for user interaction
 */
export const IconButton = ({
                               className,
                               tips,
                               ...props
                           }: IconButtonProps) => {
    const Btn = (
        <Button className={classNames(className, 'square')} {...props}/>
    )
    if (!tips) return Btn
    return (
        <Tippy content={tips} animation={''} maxWidth={240}>
            {Btn}
        </Tippy>
    );
};
