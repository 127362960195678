import React, {useState} from "react";
import styles from "../index.module.scss";
import HelpTipIcon from "../../../HelpTipIcon";
import classNames from "classnames";
import {Button} from "../../../../stories/components/Button/src";
import {Input} from "../../../../stories/components/Input/src";
import {Row} from "../../../../stories/components/Layout/src";
import {Checkbox} from "../../../../stories/components/Checkbox/src";
import TimezoneSelect from "react-timezone-select";

const Options=[

]


export function TimeZoneSection() {
    const [auto, setAuto] = useState(true)
    const [selectedTimezone, setSelectedTimezone] = useState({})

    function onCheckboxChange(){
        setAuto(!auto)
    }

    return (
        <div className={styles.ItemRow}>
            <div>
                <div className={styles.ItemLabel}>
                    Time zone
                </div>
                <div>
                    <Checkbox checked={auto} onChange={onCheckboxChange}>
                        Set time zone automatically
                    </Checkbox>
                </div>
            <div className={'my-4'}>
                <TimezoneSelect
                    isDisabled={auto}
                    value={selectedTimezone}
                    onChange={setSelectedTimezone}
                />
            </div>

                <div className={styles.SubLabel}>
                    Platform uses your time zone to send summary and notification emails, and for reminders.
                </div>
            </div>

        </div>
    )
}