import React from "react";
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

interface TextAreaProps extends React.HTMLAttributes<HTMLTextAreaElement> {
    variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
    resize?: 'none' | 'x' | 'y' | 'both';

    [propName: string]: any
}

export const TextArea = ({
                             variant = 'outline',
                             colorScheme = 'light',
                             isError = false,
                             rows = 3,
                             className,
                             style,
                             ...props
                         }: TextAreaProps) => {
    // const resizeCls = resize === 'both' ? 'resize' : `resize-${resize}`
    const cls = classNames([`${UIPrefix}form-textarea`, `variant-${variant}`, className])

    return (
        <textarea
            className={cls}
            rows={rows}
            style={{resize:'none',...style}}
            {...props}
        />
    )
}