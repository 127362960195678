import BaseModal from "./BaseModal";
import React, {useRef, useState} from "react";
import {ModalHeader} from "./ModalHeader";
import {useSelector} from "react-redux";
import UserAvatar from "../UserAvatar";
import {Checkbox} from "../../stories/components/Checkbox/src";
import {BsSquare, BsFillCheckSquareFill} from "react-icons/bs";
import classNames from "classnames";
import {Button} from "../../stories/components/Button/src";
import {IoClose} from "react-icons/io5"
import {SubmitButton} from "../ui";

export function SelectTeamMemberModal({onRequestClose, isOpen, submitting, onAdd, ...props}) {
    const {members} = useSelector(state => state.team)
    const [memberIds, setMemberIds] = useState([])
    const [selectedMembers, setSelectedMembers] = useState([])

    const onChange = (member) => {
        const index = memberIds.indexOf(member.id)
        const newValues = [...memberIds], newSelectedMembers = [...selectedMembers]
        if (index > -1) {
            newValues.splice(index, 1)
            newSelectedMembers.splice(index, 1)
        } else {
            newValues.push(member.id)
            newSelectedMembers.push(member)
        }
        setMemberIds(newValues)
        setSelectedMembers(newSelectedMembers)
    }

    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            {...props}>
            <div className={'flex'}>
                <div className={'w-[300px] border-r'}>
                    <div className={classNames('p-4 font-semibold truncate')}>Select members</div>
                    <div className={'h-[476px] overflow-y-auto'}>
                        {members.map((member, index) => (
                            <div
                                key={member.id}
                                className={'h-10 shrink-0 px-4 flex items-center gap-3 w-full cursor-pointer hover:bg-stone-100'}
                                onClick={() => onChange(member)}
                            >
                                <div className={'w-4'}>
                                    {memberIds.indexOf(member.id) > -1 ?
                                        <BsFillCheckSquareFill className={'text-primary-600'}/> :
                                        <BsSquare className={'text-gray-400'}/>}
                                </div>

                                <div className={'flex items-center gap-2'}>
                                    <UserAvatar id={member.user_id} src={member.avatar_url} name={member.name}
                                                size={'xs'}/>
                                    <div className={'font-semibold'}>{member.name}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={'w-[320px] bg-stone-100'}>
                    <div className={'p-4 font-semibold'}>Selected {memberIds.length}</div>
                    <div className={'flex flex-col gap-1 px-4 h-[400px] overflow-y-auto'}>
                        {selectedMembers.map((member, index) => (
                            <div key={member.id} className={'h-10 shrink-0 flex items-center bg-white rounded border'}>
                                <div className={'px-2'}>
                                    <UserAvatar
                                        id={member.user_id}
                                        src={member.avatar_url}
                                        name={member.name}
                                        size={'xs'}
                                    />
                                </div>
                                <div className={'font-semibold flex-1'}>{member.name}</div>
                                <div
                                    className={'hover:text-black text-stone-500 cursor-pointer px-1.5 h-full flex items-center text-lg'}
                                    onClick={() => onChange(member)}>
                                    <IoClose/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={'p-5 flex items-center justify-end gap-4'}>
                        <Button variant={'ghost'} colorScheme={'secondary'} onClick={onRequestClose}>Cancel</Button>
                        <SubmitButton
                            disabled={memberIds.length === 0}
                            loading={submitting}
                            onClick={() => onAdd && onAdd(memberIds)}
                        >
                            Add
                        </SubmitButton>
                    </div>
                </div>
            </div>
        </BaseModal>
    )
}