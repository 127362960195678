import React from "react";
import {Divider} from "../../../../stories/components/Divider/src";
import {DesktopNotificationSection, EmailPushNotificationSection, MobilePushNotificationSection} from "../Section";

export function NotificationWindow() {
    return (
        <div>
            <DesktopNotificationSection/>
            <MobilePushNotificationSection/>
            <Divider className={'my-4'}/>
            <EmailPushNotificationSection/>
            <Divider className={'my-4'}/>

        </div>
    )
}