import React from 'react';
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

interface ColProps extends React.HtmlHTMLAttributes<HTMLElement> {
    children?: any;
}

export function Col({
                        children = null,
                        className,
                        ...props
                    }: ColProps) {
    const classes = [`${UIPrefix}col`]
    return (
        <div
            className={classNames(classes, className)}
            {...props}
        >
            {children}
        </div>
    )
}