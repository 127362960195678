import {createSlice} from "@reduxjs/toolkit";
import {LOAD_STATUS} from "../../constants";
import {fetchWorkspaceInfo, fetchWorkspaceMessages} from "./persistence";

const initialState = {
    workspaces: {},
    messages: {},
};

const loadStatusSlice = createSlice({
    name: "loadStatus",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //  加载工作区
        builder.addCase(fetchWorkspaceInfo.pending, (state, action) => {
            const {workspace_id} = action.meta.arg
            if (state.workspaces[workspace_id]) return
            state.workspaces[workspace_id] = LOAD_STATUS.LOADING
        });
        builder.addCase(fetchWorkspaceInfo.fulfilled, (state, action) => {
            const {workspace_id} = action.meta.arg
            state.workspaces[workspace_id] = LOAD_STATUS.SUCCESS
        });
        builder.addCase(fetchWorkspaceInfo.rejected, (state, action) => {
            const {workspace_id} = action.meta.arg
            state.workspaces[workspace_id] = LOAD_STATUS.ERROR
        });
        //  加载工作区消息
        builder.addCase(fetchWorkspaceMessages.pending, (state, action) => {
            const {workspace_id} = action.meta.arg
            if (state.messages[workspace_id]) return
            state.messages[workspace_id] = LOAD_STATUS.LOADING
        });
        builder.addCase(fetchWorkspaceMessages.fulfilled, (state, action) => {
            const {workspace_id} = action.meta.arg
            state.messages[workspace_id] = LOAD_STATUS.SUCCESS
        });
        builder.addCase(fetchWorkspaceMessages.rejected, (state, action) => {
            const {workspace_id} = action.meta.arg
            state.messages[workspace_id] = LOAD_STATUS.ERROR
        });
    }
});

export const {
    setWorkspace,
} = loadStatusSlice.actions;
export default loadStatusSlice.reducer;