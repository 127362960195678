import React from "react";
import styles from "./index.module.scss"
import AppNavBar from "./index";
import NavList from "./NavList";
import ProfileSection from "./ProfileSection";


export default function LeftNavBar() {
    return (
        <AppNavBar className={styles.LeftNavBar}>
            <ProfileSection/>
            <NavList/>
        </AppNavBar>
    )
}