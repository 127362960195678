import React from "react";
import styles from "../index.module.scss";
import {Switch} from "../../../../stories/components/Switch/src";

export function MobilePushNotificationSection() {

    return (
        <div className={styles.ItemRow}>
            <div>
                <div className={styles.ItemLabel}>
                    Mobile push notifications
                </div>
                <div className={styles.SubLabel}>
                    Receive push notifications on mentions and comments via your mobile app.
                </div>
            </div>
            <Switch
            />
        </div>
    )
}