import React from "react";
import BaseModal, {BaseModalProps} from "../../components/Modal/BaseModal";
import NavList from "./NavList";
import styles from "./index.module.scss"
import AppNavBar from "./index";

export default function NavBarDrawer({className,overlayClassName,...props}) {

    return (
        <>
            <BaseModal {...props}
                       className={styles.NavBarDrawerContent}
                       overlayClassName={styles.NavBarDrawer}
                       isOverlay={false}
                       contentCenter={false}
                       transition={''}>
               <AppNavBar>
                   <NavList/>
               </AppNavBar>
            </BaseModal>
        </>
    )
}