import React, {memo, useEffect, useState} from "react";
import {Button, IconButton} from "../stories/components/Button/src";
import {IoChevronDown, IoArrowBackOutline} from "react-icons/io5";
import classNames from "classnames";
import styles from "../stylesheets/components/WorkspaceWindow.module.scss";
import {Outlet, useNavigate, useParams} from "react-router";
import * as Popover from '@radix-ui/react-popover';
import WorkspaceIcon from "../components/WorkspaceIcon";
import {BsThreeDots, BsPersonPlus} from "react-icons/bs";
import ContentLoader from "react-content-loader";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {fetchWorkspaceInfo} from "../store/reducers/persistence";
import {LOAD_STATUS} from "../constants";


function Skeleton() {

    return (
        <div>
            <ContentLoader viewBox={'0 0 300 100'} height={100} width={300}>
                <rect x={24} y="24" rx="5" width={160} height="12"/>
                <rect x={24} y="56" rx="5" width="260" height="12"/>
            </ContentLoader>
        </div>
    )
}

function LoadError() {
    return (
        <div className={'w-full h-full flex items-center justify-center'}>
            <div className={'text-lg text-gray-700 font-semibold'}>
                未知的工作区
            </div>
        </div>
    )
}

function TabBar() {
    let {workspaceId} = useParams();
    const workspace = 1

    if (!workspace) {
        return (
            <div className={'h-10 px-4 flex items-center border-b'}>
                <ContentLoader viewBox={`0 0 ${320} 32`}
                               height={32}
                               width={320}>
                    <rect rx="5" width={70} height={30}/>
                    <rect x={80} rx="5" width={70} height={30}/>
                    <rect x={160} rx="5" width={70} height={30}/>
                    <rect x={240} rx="5" width={70} height={30}/>
                </ContentLoader>
            </div>
        )
    }

    function Item({to, label}) {

        return (
            <NavLink to={to}
                     className={({isActive}) => classNames(styles.TabItem, isActive && styles.actived)}
                     end>
                <div className={styles.label}>
                    {label}
                </div>
                <div className={styles.indicator}/>
            </NavLink>
        )
    }

    return (
        <div className={styles.TabBar}>
            <Item to={''} label={'Message'}/>
            <Item to={'docs'} label={'Docs'}/>
            <Item to={'task'} label={'Task'}/>
            <Item to={'client'} label={'Client'}/>
        </div>
    )
}

function Header() {
    const navigate = useNavigate();
    let {workspaceId} = useParams();
    const workspace = useSelector(state => state.persistence.workspaces[workspaceId])

    if (!workspace) {
        return (
            <div className={'h-16 px-4 flex items-center'}>
                <ContentLoader viewBox={`0 0 ${180} 20`}
                               height={20}
                               width={180}>
                    <rect rx="5" width={180} height={20}/>
                </ContentLoader>
            </div>
        )
    }

    return (
        <div className={styles.Header}>
            {/*<IconButton variant={'ghost'} colorScheme={'secondary'} rounded={true} className={'mr-2'}*/}
            {/*            onClick={() => navigate('/workspace')}>*/}
            {/*    <IoArrowBackOutline className={'text-lg'}/>*/}
            {/*</IconButton>*/}
            <Popover.Root>
                <Popover.Trigger asChild>
                    <button
                        className={'flex items-center gap-2 px-2 py-2 rounded hover:bg-black/5 transition-colors'}>
                        <div className={'font-bold'}>{workspace.name}</div>
                        <IoChevronDown className={'group-data-[state=open]:rotate-180'}/>
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        className="rounded p-4 w-[460px] bg-white shadow border"
                        align={'start'}
                        sideOffset={5}>
                        <div className="flex flex-col">
                            <div className={'flex justify-between'}>
                                <div className={'flex items-center gap-2 p-2'}>
                                    <WorkspaceIcon className={'text-xl'}/>
                                    <div className={'font-medium text-lg'}>{workspace.name}</div>
                                </div>
                                <IconButton variant={'ghost'} colorScheme={'light'}>
                                    <BsThreeDots className={'text-xl'}/>
                                </IconButton>
                            </div>
                            <div className={'pl-2'}>
                                <div className={'pr-10 leading-5 flex flex-col gap-1'}>
                                    <div className={''}>{workspace.description}</div>
                                    <div className={'text-sm text-stone-600'}>{workspace.created_at}</div>
                                </div>
                            </div>
                        </div>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    )
}

export function WorkspaceWindow() {
    const dispatch = useDispatch()
    let {workspaceId} = useParams()
    const loadStatus = useSelector(state => state.loadStatus.workspaces[workspaceId])

    const onLoadInfo = async () => {
        await dispatch(fetchWorkspaceInfo({workspace_id: workspaceId}))
    }

    useEffect(() => {
        onLoadInfo()
    }, [workspaceId])

    if (!loadStatus) return null
    if (loadStatus === LOAD_STATUS.ERROR) return <LoadError/>
    if (loadStatus === LOAD_STATUS.LOADING) return <Skeleton/>

    return (
        <div className={classNames(styles.WorkspaceWindowPage)}>
            <Header/>
            <TabBar/>
            <div className={styles.Body}>
                <Outlet/>
            </div>
        </div>
    )
}