import React, {HTMLAttributes, useContext} from "react";
import classNames from "classnames";
import {TabsContext} from "./Tabs";
import {UIPrefix} from "../../../constants";

interface TabPanelsProps extends HTMLAttributes<HTMLDivElement> {
    colorScheme?: "grey" | "red" | "orange" | "yellow" | "green" | "teal" | "blue" | "cyan" | "purple" | "pink"
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    variant?: 'solid' | 'outline' | 'ghost'

    [propName: string]: any
}

export const TabPanels = ({
                              colorScheme = "grey",
                              size = 'xs',
                              variant = 'outline',
                              ...props
                          }: TabPanelsProps) => {
    const cls = classNames(`${UIPrefix}tabPanels`, `size-${size}`, `${colorScheme}-${variant}`, props.className)
    const {selectedIndex} = useContext(TabsContext)!

    return (
        <div {...props} className={cls}>
            {
                React.Children.toArray(props.children).map((child: any, index: number) => {
                    if (index !== selectedIndex) return null
                    return child
                })
            }
        </div>
    )

}