import React, {useState} from "react";
import styles from "../../UserSettingModal/index.module.scss";
import HelpTipIcon from "../../../HelpTipIcon";
import classNames from "classnames";
import {Button} from "../../../../stories/components/Button/src";
import {Input} from "../../../../stories/components/Input/src";
import {Row} from "../../../../stories/components/Layout/src";
import TeamLogo from "../../../TeamLogo";

function Label() {
    return (
        <div className={styles.ItemLabel}>
            Icon
        </div>
    )
}

export function TeamIconSection() {
    const [isEditMode, setIsEditMode] = useState(false)

    return (
        <div className={styles.ItemRow}>
            <div>
                <Label/>
                <TeamLogo size={'lg'}/>
            </div>
        </div>
    )
}