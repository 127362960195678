import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import $api from "../../api";

export const fetchTeammates = createAsyncThunk('fetchTeammates', async (params, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    const {search} = getState().teammate
    try {
        return await $api.team.getMembers({
            team_id,
            search
        })
    } catch (err) {
        return rejectWithValue(err)
    }
})

const initialState = {
    status: 'idle',
    errMsg: null,
    search: '',
    data: [],
    detailId:'',
    pagination: {}
}

const teammateSlice = createSlice({
    name: "teammate",
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.search = action.payload
        },
        setDetailId: (state, action) => {
            state.detailId = action.payload
        },
    },
    extraReducers: (builder) => {
        //  获取工作区
        builder.addCase(fetchTeammates.pending, (state, action) => {
            state.status = 'loading';
        }).addCase(fetchTeammates.fulfilled, (state, action) => {
            state.data = action.payload
            state.status = 'success';
        }).addCase(fetchTeammates.rejected, (state, action) => {
            state.status = 'failed';
            state.errMsg = action.payload.message;
        });
    },
});

export const {
    setSearch,
    setDetailId,
} = teammateSlice.actions;
export default teammateSlice.reducer;