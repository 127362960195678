import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import $api from "../../api";

export const fetchWorkspaces = createAsyncThunk('fetchWorkspaces', async (params, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    const {search} = getState().workspace
    try {
        return await $api.workspace.list({
            search,
            team_id
        })
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchCreateWorkspace = createAsyncThunk('fetchCreateWorkspace', async (form = {}, thunkAPI) => {
    const {rejectWithValue, getState} = thunkAPI
    const {id: team_id} = getState().team
    try {
        return await $api.workspace.create({
            team_id,
            ...form
        })
    } catch (err) {
        return rejectWithValue(err)
    }
})

const initialState = {
    status: 'idle',
    errMsg: null,
    search: '',
    data: [],
    pagination: {},
}

const workspaceSlice = createSlice({
    name: "workspace",
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.search = action.payload
        },
    },
    extraReducers: (builder) => {
        //  获取工作区
        builder.addCase(fetchWorkspaces.pending, (state, action) => {
            state.status = 'loading';
        }).addCase(fetchWorkspaces.fulfilled, (state, action) => {
            state.data = action.payload
            state.status = 'success';
        }).addCase(fetchWorkspaces.rejected, (state, action) => {
            state.status = 'failed';
            state.errMsg = action.payload.message;
        });
        //  创建工作区
        builder.addCase(fetchCreateWorkspace.fulfilled, (state, action) => {
            const workspace = action.payload
            state.data.unshift(workspace)
        })
    },
});

export const {
    setSearch,
} = workspaceSlice.actions;
export default workspaceSlice.reducer;