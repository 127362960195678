export default function Logo({
                                 bg = '#1b3862',
                                 color = '#ffffff',
                                 ...props
                             }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" fill={bg} {...props}>
            <rect className="cls-1" width="120" height="120" rx="60"/>
            <path className="cls-2"
                  fill={color}
                  d="M37.06,73.58H30.52v-16A4.25,4.25,0,0,0,22,57c0,.5,0,1,0,1.48,0,4.77,0,9.53,0,14.3v.81H15.45v-.69c0-5.18,0-10.35,0-15.52a10.27,10.27,0,0,1,6.09-9.76,10.17,10.17,0,0,1,11.37,1.24,1.06,1.06,0,0,0,1.53.1,12.92,12.92,0,0,1,5.18-2.35A10.82,10.82,0,0,1,51.9,55.1a12.71,12.71,0,0,1,.22,2.3c0,5.15,0,10.3,0,15.45v.7H45.61V57.66a4.43,4.43,0,0,0-3.06-4.45,4.28,4.28,0,0,0-5.47,4.23c0,1.35,0,2.7,0,4.05,0,3.76,0,7.51,0,11.27Z"/>
            <path className="cls-2"
                  fill={color}
                  d="M61.86,56.69H72.92v6.6h-11a4.79,4.79,0,0,0,.29.65,5.75,5.75,0,0,0,5,2.76H78v1.47c0,1.48,0,3,0,4.45,0,.48-.15.61-.63.61q-5,0-10.06,0c-6.69,0-10.74-4.12-12.36-9.63a13.42,13.42,0,0,1,6.59-15.52,11,11,0,0,1,5.2-1.3c3.57,0,7.14,0,10.7,0,.42,0,.57.14.56.56,0,1.8,0,3.6,0,5.4,0,.5-.18.61-.64.61-3.46,0-6.92,0-10.38.05A5.61,5.61,0,0,0,61.86,56.69Z"/>
            <path className="cls-2"
                  fill={color}
                  d="M104.54,73.22a15.21,15.21,0,0,1-12.12-6,15.23,15.23,0,0,1-12.14,6V71.48c0-1.38,0-2.75,0-4.12,0-.47.16-.64.59-.71a11.28,11.28,0,0,0,4-1.3,6.08,6.08,0,0,0,3-7.3,7,7,0,0,0-4.74-4.21c-.73-.25-1.51-.32-2.26-.51-.2,0-.51-.26-.51-.4,0-2,0-4,0-6.15a15.33,15.33,0,0,1,12.13,6,14.82,14.82,0,0,1,7.41-5.22,15.11,15.11,0,0,1,4.67-.73c0,.27,0,.5,0,.73,0,1.65,0,3.31,0,5,0,.68-.25.82-.83.88a8.5,8.5,0,0,0-5.22,2.4,5.76,5.76,0,0,0,.08,8.53,8.63,8.63,0,0,0,5.21,2.3c.57.05.75.23.74.81,0,1.7,0,3.39,0,5.09Z"/>
        </svg>
    )
}