import React, {useState} from "react";
import styles from "../index.module.scss";
import HelpTipIcon from "../../../HelpTipIcon";
import classNames from "classnames";
import {Button} from "../../../../stories/components/Button/src";
import {Input} from "../../../../stories/components/Input/src";
import {Row} from "../../../../stories/components/Layout/src";

export function DestroyAccountSection() {

    return (
        <div className={styles.ItemRow}>
            <div>
                <div className={styles.ItemLabel}>
                    Delete account
                </div>
                <div className={styles.SubLabel}>
                    Dranger zone !
                </div>

            </div>
            <Button colorScheme={'danger'} size={'sm'} variant={'outline'}>
                Delete
            </Button>
        </div>
    )
}