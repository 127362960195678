import axiosInstance from "../lib/axios";

export const inviteInfo = async (team_id) => {
    return await axiosInstance.post('/v1/team/invite-link', {team_id})
}

export const inviteLink = async (team_id) => {
    return await axiosInstance.post('/v1/team/invite-link', {team_id})
}

export const asMember = async (data) => {
    return await axiosInstance.post(`/v1/team/asMember`, data)
}

export const asAdmin = async (data) => {
    return await axiosInstance.post(`/v1/team/asAdmin`, data)
}

export const asOwner = async (data) => {
    return await axiosInstance.post(`/v1/team/asOwner`, data)
}

export const setName = async (data) => {
    return await axiosInstance.post(`/v1/team/set-name`, data)
}

export const getMembers = async (data) => {
    return await axiosInstance.post('/v1/team/members', data)
}

export const getGroups = async (data) => {
    return await axiosInstance.post('/v1/team/groups', data)
}

export const getGroupMembers = async (data = {}) => {
    return await axiosInstance.post('/v1/team/group/members', data)
}

export const saveGroup = async (data) => {
    return await axiosInstance.post(`/v1/team/group/save`, data)
}

export const removeGroupMember = async (data) => {
    return await axiosInstance.post(`/v1/team/group/member/remove`, data)
}

export const addGroupMembers = async (data) => {
    return await axiosInstance.post(`/v1/team/group/member/add`, data)
}

export const delGroup = async (data) => {
    return await axiosInstance.post(`/v1/team/group/del`, data)
}

export const setTheme = async (data) => {
    return await axiosInstance.post(`/v1/team/set-theme`, data)
}

export const getInfo = async (team_id) => {
    return await axiosInstance.post('/v1/team/info', {team_id})
}

export const inviteMember = async (data) => {
    return await axiosInstance.post(`/v1/team/invite-member`, data)
}

export const inviteLinkSwitch = async (team_id) => {
    return await axiosInstance.post(`/v1/team/invite-link/switch`,{team_id})
}

export const inviteLinkReset = async (team_id) => {
    return await axiosInstance.post(`/v1/team/invite-link/reset`,{team_id})
}