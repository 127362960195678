import React, {HTMLAttributes, useContext, useEffect, useState} from 'react';
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

export interface CheckboxProps {
    colorScheme?: 'primary' | 'success' | 'warning' | 'danger';
    size?: 'sm' | 'md' | 'lg';
    value?: string | number;
    /**
     *  If true, the checkbox will be checked. You'll need to pass onChange to update its value (since it is now controlled)
     */
    checked?: boolean;
    disabled?: boolean;

    [propName: string]: any;
}

export const Checkbox = ({
                             colorScheme = 'primary',
                             size = 'md',
                             children = '',
                             disabled = false,
                             checked: propChecked = undefined,
                             inputType = 'checkbox',
                             onChange,
                             value = '',
                             className,
                             ...props
                         }: CheckboxProps) => {
    const cls = classNames(`${UIPrefix}form-check`, 'checkbox', `size-${size}`, colorScheme, className,)

    return (
        <label className={cls}>
            <input
                type={inputType}
                className={`${UIPrefix}form-check-input`}
                disabled={disabled}
                checked={propChecked}
                onChange={onChange}
                {...props}
            />
            <div className={`${UIPrefix}form-check-label`}>
                {children}
            </div>
        </label>
    )
}
