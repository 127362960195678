import React, {useEffect, useRef} from "react";
import lottie from 'lottie-web';
import LogoAnimation from "../../assets/logo-blink-lottie"
import styles from "./AppLoadingScreen.module.scss"

let anim = null

export default function AppLoadingScreen() {
    const animation = useRef(null);

    useEffect(() => {
        if (anim) return
        anim = lottie.loadAnimation({
            container: animation.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: LogoAnimation,
        });
        anim.setSpeed(.7);
    }, []);

    return (
        <div className={styles.AppLoadingScreen}>
            <div ref={animation} className={styles.animation}/>
        </div>
    )
}