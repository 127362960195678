import React, {HTMLAttributes} from "react";
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

export interface TagProps extends HTMLAttributes<HTMLDivElement> {
    colorScheme?: "gray" | "red" | "green" | "orange" | "yellow" | "blue" | "purple" | "pink" | "indigo" | "teal" | "cyan"
    size?: 'sm' | 'md' | 'lg'
    variant?: 'solid' | 'subtle'
}

export const Tag = ({
                        colorScheme = "gray",
                        size = 'sm',
                        variant = 'subtle',
                        ...props
                    }: TagProps) => {
    const cls = classNames(`${UIPrefix}tag`, `size-${size}`, `${variant}-${colorScheme}`, props.className)

    return (
        <div {...props} className={cls}>
            {props.children}
        </div>
    )
}