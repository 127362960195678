import React from "react";
import {TabBar, TabBarItem, TabPanels, Tabs} from "../../../../stories/components/Tabs/src";

export function DocumentWindow() {
    return (
        <div>
            <Tabs>
                <TabBar>
                    <TabBarItem>访问限制</TabBarItem>
                    <TabBarItem>回收站</TabBarItem>
                </TabBar>
                <TabPanels>

                </TabPanels>
            </Tabs>
        </div>
    )
}