import * as React from "react";
// import {Container, Draggable} from "react-smooth-dnd";
import {Container, Draggable} from "@richardrout/react-smooth-dnd";
import Column from "./Column";
import "./index.scss"
import {BoardViewColumnDragHandleCls, TaskColumnDraggingCls} from "./constants";

const columnNames = ["Lorem", "Ipsum", "Consectetur", "Eiusmod"];
const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;

const cardColors = [
    "azure",
    "beige",
    "bisque",
    "blanchedalmond",
    "burlywood",
    "cornsilk",
    "gainsboro",
    "ghostwhite",
    "ivory",
    "khaki"
];

const pickColor = () => {
    let rand = Math.floor(Math.random() * 10);
    return cardColors[rand];
};

export default function BoardView({...props}) {
    function onColumnDrop() {

    }

    return (
        <div className={'BoardView'}>
            <Container
                orientation="horizontal"
                onDrop={onColumnDrop}
                dragHandleSelector={`.${BoardViewColumnDragHandleCls}`}
                disableScrollOverlapDetection={true}
                dropPlaceholder={{
                    showOnTop: true,
                    className: 'BoardViewColumnDropPlaceholder'
                }}
                dragClass={TaskColumnDraggingCls}
            >
                <Column index={0} label={'000'}/>
                <Column index={1} label={111}/>
                <Column index={2} label={222}/>
                <Column index={3} label={333}/>
                <Column index={4} label={444}/>
            </Container>
        </div>
    )
}