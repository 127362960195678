import React, {useState} from "react";
import styles from "../index.module.scss";
import HelpTipIcon from "../../../HelpTipIcon";
import classNames from "classnames";
import {Button} from "../../../../stories/components/Button/src";
import {Input} from "../../../../stories/components/Input/src";
import {Row} from "../../../../stories/components/Layout/src";
import {useDispatch, useSelector} from "react-redux";
import $api from "../../../../api";
import {toast} from "react-hot-toast";
import {setName} from "../../../../store/reducers/user";

const NAME_LENGTH_LIMIT = 20

export function DisplayNameSection() {
    const [isEditMode, setIsEditMode] = useState(false)
    const [saving, setSaving] = useState(false)
    const {name} = useSelector((state) => state.user);
    const [value, setValue] = useState(name)
    const [error, setError] = useState('')
    const dispatch = useDispatch();

    const onSave = async (event) => {
        event.preventDefault()
        if (!value || !value.trim(' ')) {
            setError('Please enter a name')
            return
        }
        setError('')
        setSaving(true)
        try {
            const {name} = await $api.user.setName(value)
            dispatch(setName(name))
            setIsEditMode(false)
            toast.success('Name modified')
        } catch (e) {
            toast.error('Name modification failed')
        }
        setSaving(false)
    }

    const Preview = (
        <>
            <div>
                <div className={styles.ItemLabel}>
                    Display Name <HelpTipIcon
                    content={'This could be your first name, or a nickname — however you’d like people to refer to you in team.'}/>
                </div>
                <div className={classNames(styles.defaultValue, 'mb-2')}>
                    {name}
                </div>
            </div>
            <Button colorScheme={'secondary'} variant={'outline'} size={'sm'} onClick={() => setIsEditMode(true)}>
                Edit
            </Button>
        </>
    )

    const Form = (
        <div>
            <div className={styles.ItemLabel}>
                Display Name <HelpTipIcon content={'你希望人们在团队中如何称呼你,这可以是姓名或昵称'}/>
            </div>
            <form onSubmit={onSave}>
                <Input value={value} autoFocus maxLength={NAME_LENGTH_LIMIT}
                       onChange={event => setValue(event.target.value)}/>
                {error && (
                    <div className={'py-1 text-sm text-rose-600'}>
                        {error}
                    </div>
                )}
                <Row gap={3} className={'mt-2'}>
                    <Button variant={'outline'} colorScheme={'secondary'} size={'sm'}
                            onClick={() => setIsEditMode(false)}>Cancel</Button>
                    <Button type={'submit'} size={'sm'} loading={saving}>Save</Button>
                </Row>
            </form>
        </div>
    )

    return (
        <div className={styles.ItemRow}>
            {isEditMode ? Form : Preview}
        </div>
    )
}