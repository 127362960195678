import React from "react";
import {Divider} from "../../../../stories/components/Divider/src";
import {DestroyTeamSection, TeamIconSection, TeamNameSection, TeamThemeSection} from "../Section";

export function SettingWindow() {

    return (
        <div className={''}>
            <TeamNameSection/>
            <Divider/>
            <TeamIconSection/>
            <Divider/>
            <TeamThemeSection/>
            <Divider/>
            <DestroyTeamSection/>
        </div>
    )
}