import React from "react";
import classNames from "classnames";
import {AlertProps} from "./Alert";
import {
    BsInfoCircleFill,
    BsExclamationCircleFill,
    BsFillCheckCircleFill,
    BsInfoCircle,
    BsCheckCircle
} from "react-icons/bs";
import {SvgIcon} from "../../SvgIcon/src";
import {UIPrefix} from "../../../constants";

interface AlertIconProps extends AlertProps {
    variant?: 'solid' | 'outline'
}


const Icon = {
    solid: {
        'success': BsFillCheckCircleFill,
        'secondary': BsInfoCircleFill,
        'primary': BsInfoCircleFill,
        'info': BsInfoCircleFill,
        'warning': BsExclamationCircleFill,
        'danger': BsExclamationCircleFill,
    },
    outline: {
        'success': BsCheckCircle,
        'secondary': BsInfoCircleFill,
        'primary': BsInfoCircleFill,
        'info': BsInfoCircleFill,
        'warning': BsExclamationCircleFill,
        'danger': BsExclamationCircleFill,
    }
}

export const AlertIcon = ({
                              variant = 'solid',
                              colorScheme = 'primary',
                              size = 'md',
                              ...props
                          }: AlertIconProps) => {

    const cls = classNames(`${UIPrefix}alertIcon`, `size-${size}`, variant + '-' + colorScheme, props.className)
    const VariantIcon = Icon[variant]
    const icon = VariantIcon[colorScheme as keyof typeof VariantIcon]

    return (
        <div className={cls}>
            <SvgIcon icon={icon}/>
        </div>
    )
}