import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    files: [],
};

const documentSlice = createSlice({
    name: "document",
    initialState,
    reducers: {
        addFile: (state, action) => {
            state.files.push(action.payload);
        },
        deleteFile: (state, action) => {
            state.files = state.files.filter((file) => file.id !== action.payload.id);
        },
    },
});

export const { addFile, deleteFile } = documentSlice.actions;
export default documentSlice.reducer;