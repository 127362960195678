import React, {Fragment, useMemo} from 'react';
import {Text} from "./Text";

type Chunk = {
    text: string
    match: boolean
}

type Options = {
    text: string
    query: string | string[]
}
const escapeRegexp = (term: string): string =>
    term.replace(/[|\\{}()[\]^$+*?.-]/g, (char: string) => `\\${char}`)

function buildRegex(query: string[]) {
    const _query = query
        .filter((text) => text.length !== 0)
        .map((text) => escapeRegexp(text.trim()))
    if (!_query.length) {
        return null
    }

    return new RegExp(`(${_query.join("|")})`, "ig")
}

function highlightWords({text, query}: Options): Chunk[] {
    const regex = buildRegex(Array.isArray(query) ? query : [query])
    if (!regex) {
        return []
    }
    const result = text.split(regex).filter(Boolean)
    return result.map((str) => ({text: str, match: regex.test(str)}))
}

export function useHighlight(props: Options) {
    const {text, query} = props
    return useMemo(() => highlightWords({text, query}), [text, query])
}

interface MarkProps extends React.HTMLAttributes<HTMLElement> {
    children?: any;
    query?: string;

    [propName: string]: any;
}

export const Mark = ({
                         children = '',
                         query = '',
                         ...props
                     }: MarkProps) => {
    const chunks = useHighlight({query: query, text: children})

    if (typeof children !== "string") {
        return null
    }
    if (!query) {
        return (
            <Text {...props}>{children}</Text>
        )
    }

    return (
        <Text {...props}>
            {chunks.map((chunk, index) => {
                return chunk.match ? (
                    <mark key={index}>{chunk.text}</mark>
                ) : (
                    <Fragment key={index}>{chunk.text}</Fragment>
                )
            })}
        </Text>
    )

}
