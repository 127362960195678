import axiosInstance from "../lib/axios";

export const updatePassword = async (form) => {
    return await axiosInstance.put('/v1/user/password', form)
}

export const setName = async (name) => {
    return await axiosInstance.post('/v1/user/set-name', {name})
}

export const getSystemAvatars = async () => {
    return await axiosInstance.get('/v1/user/sys-avatars')
}

export const setSystemAvatar = async (key) => {
    return await axiosInstance.post('/v1/user/set-sys-avatar', {key})
}

export const uploadAvatar = async (formData) => {
    return await axiosInstance.post('/v1/user/upload-avatar', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const logout = async () => {
    return await axiosInstance.post('/logout')
}

export const getInfo = async () => {
    return await axiosInstance.get('/v1/user/info')
}

export const enableTwoFactorAuthentication = async () => {
    return await axiosInstance.post('/user/two-factor-authentication')
}

export const challengeTwoFactorAuthentication = async (code) => {
    return await axiosInstance.post('/two-factor-challenge', {code})
}

export const confirmTwoFactorAuthentication = async (code) => {
    return await axiosInstance.post('/user/confirmed-two-factor-authentication', {code})
}

export const disableTwoFactorAuthentication = async () => {
    return await axiosInstance.delete('/user/two-factor-authentication')
}

export const confirmPassword = async (password) => {
    return await axiosInstance.post('/user/confirm-password', {password})
}

export const getTwoFactorAuthenticationQrCode = async () => {
    return await axiosInstance.get('/user/two-factor-qr-code')
}

export const getTwoFactorAuthenticationRecoveryCodes = async () => {
    return await axiosInstance.get('/user/two-factor-recovery-codes')
}