import React, {useState} from "react";
import styles from "../index.module.scss";
import HelpTipIcon from "../../../HelpTipIcon";
import classNames from "classnames";
import {Button} from "../../../../stories/components/Button/src";
import {Input} from "../../../../stories/components/Input/src";
import {Row} from "../../../../stories/components/Layout/src";
import Select from 'react-select'
import Dropdown from "rc-dropdown/es";
import Menu, {Item as MenuItem} from "rc-menu/es";
import {SvgIcon} from "../../../../stories/components/SvgIcon/src";
import {IoChevronDown} from "react-icons/io5";

const Options = [
    {value: 'auto', label: 'Use system setting'},
    {value: 'light', label: 'Light'},
    {value: 'dark', label: 'Dark'}
]

export function ThemeSection() {

    function onMenuClick({key}) {

        document.documentElement.setAttribute('data-theme', key==='dark'?'dark':'light')
    }

    return (
        <div className={styles.ItemRow}>
            <div>
                <div className={styles.ItemLabel}>
                    Theme
                </div>
                <div className={styles.SubLabel}>
                    Change the appearance used in the user interface.
                </div>
            </div>
            <Dropdown
                placement={'bottomRight'}
                trigger={['click']}
                overlay={(
                    <Menu onClick={onMenuClick}>
                        {Options.map((item, index) => (
                            <MenuItem key={index} eventKey={item.value}>
                                <div className={'label'}>{item.label}</div>
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            >
                <Button colorScheme={'secondary'} variant={'outline'} size={'sm'}
                        renderSuffix={<SvgIcon icon={IoChevronDown} size={18} color={'#999'}/>}
                >
                    <div className={'pl-sm'}>Auto</div>
                </Button>
            </Dropdown>
        </div>
    )
}