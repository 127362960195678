import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import {UIPrefix} from "../../../constants";
import {Spinner} from "../../Spinner/src";

export interface SwitchProps {
    colorScheme?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'indigo' | 'teal' | 'light' | 'yale' | 'sky';
    size?: 'xs' | 'sm' | 'md' | 'lg';
    defaultChecked?: boolean;
    onCheckedChange?: (checked: boolean) => void;
    checked?: boolean
    disabled?: boolean
    loading?: boolean

    [propName: string]: any
}

export const Switch = ({
                           loading = false,
                           colorScheme = 'primary',
                           size = 'md',
                           disabled = false,
                           defaultChecked = false,
                           onCheckedChange,
                           ...props
                       }: SwitchProps) => {
    const [checked, setChecked] = useState(defaultChecked)
    const cls = classNames(`${UIPrefix}form-switch`, colorScheme, `size-${size}`)
    const existCheckedProp = Object.keys(props).indexOf('checked') > -1

    function onChange() {
        if (existCheckedProp) {
            onCheckedChange && onCheckedChange(!props.checked)
        } else {
            setChecked(!checked)
        }
    }

    return (
        <label className={cls}>
            <input
                className={`${UIPrefix}form-check-input`}
                type="checkbox"
                role="switch"
                disabled={loading || disabled}
                checked={existCheckedProp ? props.checked : checked}
                onChange={onChange}
            />
        </label>
    )
}
