import React from "react";
import {LoginActivity,} from "../Section";
import {Divider} from "../../../../stories/components/Divider/src";

export function SecurityWindow() {
    return (
        <div>
            <LoginActivity/>
            <Divider className={'my-4'}/>
        </div>
    )
}