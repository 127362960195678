import React, {useState} from "react";
import AppNavBar from "./index";
import styles from "./index.module.scss";
import {IconButton} from "../../stories/components/Button/src";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import {BsList} from "react-icons/bs";
import {Row} from "../../stories/components/Layout/src";
import ProfileSection from "./ProfileSection";
import NavBarDrawer from "./NavBarDrawer";
import {useLocation} from 'react-router-dom';

export default function TopNavBar() {
    const [openDrawer, setOpenDrawer] = useState(false)

    function onChangeDrawerStatus() {
        setOpenDrawer(!openDrawer)
    }

    const location = useLocation()
    React.useEffect(() => {
        setOpenDrawer(false)
    }, [location])

    return (
        <>
            <AppNavBar className={styles.TopNavBar}>
                <IconButton variant={'ghost'}
                            colorScheme={'secondary'}
                            className={styles.ToggleBtn}
                            onClick={onChangeDrawerStatus}
                >
                    <SvgIcon icon={BsList} size={22}/>
                </IconButton>
                <ProfileSection onClickCallback={()=>setOpenDrawer(false)}/>
            </AppNavBar>
            <NavBarDrawer
                isOpen={openDrawer}
                onRequestClose={onChangeDrawerStatus}
            />
        </>
    )
}