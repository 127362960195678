import React from "react";
import classNames from "classnames";
import {BsInfoCircle} from "react-icons/bs";
import {AlertIcon} from "./AlertIcon";
import {UIPrefix} from "../../../constants";

export interface AlertProps extends React.HTMLAttributes<HTMLElement> {
    variant?: 'outline' | 'solid'
    colorScheme?: 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'danger'
    size?: 'sm' | 'md' | 'lg';
    showIcon?: boolean
}

export const Alert = ({
                          variant = 'solid',
                          colorScheme = 'info',
                          size = 'md',
                          children,
                          className,
                          showIcon = true,
                          ...props
                      }: AlertProps) => {
    if (!children) return null
    const cls = classNames(`${UIPrefix}alert`, `size-${size}`, variant + '-' + colorScheme, className)

    return (
        <div className={cls}>
            {showIcon &&
            <AlertIcon colorScheme={colorScheme} variant={'solid'} size={size}/>
            }
            {children}
        </div>
    )
}