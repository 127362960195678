import React from "react";
import classNames from "classnames";
import Modal from "react-modal";
import styles from "./index.module.scss"

const TransitionDuration = 200    //  ms

export interface BaseModalProps extends Modal.Props {
    overlayClassName?: string | undefined
    isOverlay?: boolean
    contentCenter?: boolean
    transition?: '' | 'ease-in-out',
    onRequestClose: () => void
}

const BaseModal = ({
                       isOpen = false,
                       onAfterOpen,
                       onRequestClose,
                       isOverlay = true,
                       contentCenter = true,
                       className,
                       overlayClassName,
                       transition = 'ease-in-out',
                       children,
                       ...props
                   }: BaseModalProps) => {

    const overlayCls = classNames(styles.BaseModalOverlay, !isOverlay && styles.transparent, contentCenter && styles.contentCenter, styles[transition], overlayClassName)

    return (
        <>
            <Modal
                isOpen={isOpen}
                onAfterOpen={onAfterOpen}
                onRequestClose={onRequestClose}
                ariaHideApp={false}
                closeTimeoutMS={transition ? TransitionDuration : 0}
                className={classNames(styles.BaseModal, className)}
                overlayClassName={overlayCls}
                {...props}
            >
                {children}
            </Modal>
        </>
    )
}
export default BaseModal