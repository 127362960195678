import classNames from "classnames";
import styles from "./index.module.scss"
import LeftNavBar from "../AppNavBar/LeftNavBar";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import TopNavBar from "../AppNavBar/TopNavBar";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import $api from "../../api";
import {setUserInfo} from "../../store/reducers/user";
import AppLoadingScreen from "../../components/App/AppLoadingScreen";
import AppLoadFailureScreen from "../../components/App/AppLoadFailureScreen";
import {fetchTeam, setTeam} from "../../store/reducers/team";
import {LOAD_STATUS} from "../../constants";

function AppContainer() {

    return (
        <div>
            <TopNavBar/>
            <div className={classNames(styles.AppContainer)}>
                <LeftNavBar/>
                <div className={styles.main}>
                    <Outlet/>
                </div>
            </div>
        </div>

    );
}

export default AppContainer;
