import SimpleBar from "simplebar-react";
import {Props as SimpleBarProps} from "simplebar-react";
import React from "react";
import "./index.scss"

interface Props extends SimpleBarProps {

}

export default function SimpleScroll({
                                         autoHide = false,
                                         ...props
                                     }: Props) {
    return (
        <SimpleBar
            autoHide={autoHide}
            {...props}
        />
    )
}