import React, {useState} from "react";
import styles from "../../UserSettingModal/index.module.scss";
import HelpTipIcon from "../../../HelpTipIcon";
import classNames from "classnames";
import {Button} from "../../../../stories/components/Button/src";
import {Input} from "../../../../stories/components/Input/src";
import {Row} from "../../../../stories/components/Layout/src";
import {useDispatch, useSelector} from "react-redux";
import $api from "../../../../api";
import {toast} from "react-hot-toast";
import {setName as setTeamName} from "../../../../store/reducers/team";

function Label() {
    return (
        <div className={styles.ItemLabel}>
            Team Name <HelpTipIcon content={'You can use your organization or company name. Keep it simple.'}/>
        </div>
    )
}

export function TeamNameSection() {
    const [isEditMode, setIsEditMode] = useState(false)
    const team = useSelector((state) => state.team);
    const [name, setName] = useState(team.name)
    const [submitting, setSubmitting] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const dispatch = useDispatch();

    const onSave = async (e) => {
        e.preventDefault()
        if (!name.trim()) {
            setErrMsg('Please enter a team name')
            return
        }
        setErrMsg('')
        setSubmitting(true)
        try {
            await $api.team.setName({team_id: team.id, name})
            dispatch(setTeamName(name))
            setIsEditMode(false)
            toast.success('Save successfully')
        } catch (e) {
            setErrMsg('Save failed, please try again')
        }
        setSubmitting(false)
    }

    const Preview = (
        <>
            <div>
                <Label/>
                <div className={classNames(styles.defaultValue, 'mb-4')}>
                    {team.name}
                </div>
            </div>
            <Button colorScheme={'secondary'} variant={'outline'} size={'sm'} onClick={() => setIsEditMode(true)}>
                Edit
            </Button>
        </>
    )

    const Form = (
        <form onSubmit={onSave}>
            <Label/>
            <Input defaultValue={name} onChange={e => setName(e.target.value)} className={'my-2'} autoFocus/>
            {errMsg && (
                <div className={'text-sm text-rose-600 mb-3'}>
                    {errMsg}
                </div>
            )}
            <Row className={'my-3'}>
                <Button type={'submit'} className={'me-4'} size={'sm'} loading={submitting}>Save</Button>
                <Button variant={'outline'} colorScheme={'secondary'} size={'sm'}
                        onClick={() => setIsEditMode(false)}>Cancel</Button>
            </Row>
        </form>
    )

    return (
        <div className={styles.ItemRow}>
            {isEditMode ? Form : Preview}
        </div>
    )
}