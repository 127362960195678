import React, {useState} from "react";
import Modal from "react-modal";
import classNames from "classnames";
import UserAvatar from "../../UserAvatar";
import {Heading, Text} from "../../../stories/components/Text/src";
import {BsChevronRight} from "react-icons/bs";
import {ModalHeader} from "../ModalHeader";
import {NavModal} from "../NavModal";
import {FiUsers} from "react-icons/fi";
import {
    BsGear,
    BsCreditCard,
    BsFolder2,
    BsGrid,
    BsCheckLg,
    BsArrowLeft,
    BsPlus,
    BsPlusCircle,
    BsPlusCircleFill
} from "react-icons/bs";
import BaseModal from "../BaseModal";
import styles from "./index.module.scss"
import TeamLogo from "../../TeamLogo";
import {SvgIcon} from "../../../stories/components/SvgIcon/src";
import {useSelector} from "react-redux";

const TeamList = [
    {id: 1, name: 'Mex Accounting', member_count: 12},
    {id: 2, name: 'Google Inc', member_count: 1452},
    {id: 3, name: 'Example Marketing', member_count: 12},
]

export const TeamListModal = ({isOpen = false, onAfterOpen, onRequestClose, ...props}) => {
    const [currentTeamId, setCurrentTeamId] = useState('')
    const user = useSelector(state => state.user)

    return (
        <BaseModal isOpen={isOpen} onRequestClose={onRequestClose}>
            <ModalHeader label={'My Teams'} onRequestClose={onRequestClose}/>
            <div className={'w-[500px] min-h-[300px] rounded-lg px-4'}>
                <div className={'py-2'}>
                    <button
                        className={'flex items-center gap-2 px-4 py-2 text-primary font-semibold bg-primary-50 hover:bg-primary-100 active:bg-primary-200 rounded'}>
                        <BsPlusCircleFill/> Create a new team
                    </button>
                </div>
                <div className={'py-4'}>
                    {user.teams.map((team, index) => (
                            <a
                                key={index}
                                href={`${process.env.REACT_APP_URL}/${team.id}`}
                                className={classNames('flex px-4 py-3 gap-3 items-center hover:bg-primary-50 rounded cursor-pointer')}
                            >
                                <div className={''}>
                                    <TeamLogo name={team.name}/>
                                </div>
                                <div className={'flex-1'}>
                                    <div className={'font-semibold'}>
                                        {team.name}
                                    </div>
                                    <div className={'text-sm text-stone-500'}>
                                        {team.member_count} Members
                                    </div>
                                </div>
                                {currentTeamId && team.id && (
                                    <BsCheckLg style={{fontSize: '16px', marginRight: '5px', color: '#2192f3'}}/>
                                )}
                            </a>
                        )
                    )}
                </div>
            </div>
        </BaseModal>
    )
}