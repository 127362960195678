import React, {useEffect, useState} from "react";
import AppContainer from "../layouts/AppContainer";
import {isElectron} from '../utils';
import {createBrowserHistory, createHashHistory} from "history";
import {
    BrowserRouter,
    Route,
    Routes,
    unstable_HistoryRouter as HistoryRouter,
    useNavigate,
    useParams,
} from "react-router-dom";
import Teammate from "../pages/Teammate";
import Apps from "../pages/Apps";
import Xero from "../pages/Apps/Xero";
import AppWelcome from "../components/AppWelcome";
import NotFound from "../pages/NotFound";
import LaunchTeam from "../pages/LaunchTeam";
import {
    Docs,
    Workspace,
    WorkspaceClient,
    WorkspaceDocs,
    WorkspaceMessage,
    WorkspaceTask,
    WorkspaceWindow,
} from "../pages";
import {LOAD_STATUS} from "../constants";
import {useDispatch, useSelector} from "react-redux";
import $api from "../api";
import {setUserInfo} from "../store/reducers/user";
import {fetchTeam} from "../store/reducers/team";
import AppLoadingScreen from "../components/App/AppLoadingScreen";
import AppLoadFailureScreen from "../components/App/AppLoadFailureScreen";

const history = isElectron() ? createHashHistory({window}) : createBrowserHistory({window});
const Router = isElectron() ? HistoryRouter : BrowserRouter

export const AppRouter = () => {
    const [loadStatus, setLoadStatus] = useState(LOAD_STATUS.LOADING)
    const dispatch = useDispatch()
    const team = useSelector(state => state.team)

    const onInit = async (teamId) => {
        try {
            const user = await $api.user.getInfo()
            dispatch(setUserInfo(user))
        } catch (e) {
            setLoadStatus(LOAD_STATUS.ERROR)
            return
        }

        try {
            await dispatch(fetchTeam(teamId)).unwrap()
            setLoadStatus(LOAD_STATUS.SUCCESS)
        } catch (e) {
            window.location.href = process.env.REACT_APP_WEBSITE_URL
        }
    }

    useEffect(() => {
        const match = /^\/([^/]+)/.exec(window.location.pathname);
        if (match && match.length > 1) {
            onInit(match[1])
        } else {
            window.location.href = process.env.REACT_APP_WEBSITE_URL
        }
    }, [])

    if (loadStatus === LOAD_STATUS.LOADING) {
        return <AppLoadingScreen/>
    }

    if (loadStatus === LOAD_STATUS.ERROR) {
        return <AppLoadFailureScreen/>
    }

    return (
        <Router basename={`/${team.id}`}>
            <Routes>
                {/*<Route path="" element={<LaunchTeam/>}/>*/}
                <Route path="/" element={<AppContainer/>}>
                    <Route path="" element={<AppWelcome/>}/>
                    <Route path="workspace" element={<Workspace/>}>
                        <Route path="" element={<AppWelcome/>}/>
                        <Route path=":workspaceId" element={<WorkspaceWindow/>}>
                            <Route path="" element={<WorkspaceMessage/>}/>
                            <Route path="docs" element={<WorkspaceDocs/>}/>
                            <Route path="task" element={<WorkspaceTask/>}/>
                            <Route path="client" element={<WorkspaceClient/>}/>
                        </Route>
                    </Route>
                    <Route path="teammate" element={<Teammate/>}/>
                    <Route path="docs" element={<Docs/>}/>
                    <Route path="app" element={<Apps/>}>
                        <Route path="xero" element={<Xero/>}/>
                    </Route>
                </Route>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Router>
    )
}
