import React, {useState} from "react";
import styles from "../index.module.scss";
import {Switch} from "../../../../stories/components/Switch/src";
import {Radio, RadioGroup} from "../../../../stories/components/Radio/src";
import classNames from "classnames";
import {Button} from "../../../../stories/components/Button/src";
import {SvgIcon} from "../../../../stories/components/SvgIcon/src";
import {BsBellFill} from "react-icons/bs";
import {Alert} from "../../../../stories/components/Alert/src";
import {Divider} from "../../../../stories/components/Divider/src";

export function DesktopNotificationSection() {
    const [permissionStatus, setPermissionStatus] = useState(Notification.permission)   //  default | granted | denied | requested

    if (!("Notification" in window)) return null    //  "This browser does not support desktop notification"


    function onEnableNotification() {
        setPermissionStatus('requested')
        Notification.requestPermission((status) => {
            setPermissionStatus(status)
        })
    }

    if (permissionStatus === 'granted') return null

    return (
        <>
            <div className={classNames(styles.ItemRow)}>
                <div>
                    <div className={styles.ItemLabel}>
                        Desktop Notifications
                    </div>
                    <div className={styles.SubLabel}>
                        We strongly recommend enabling notifications.
                    </div>
                </div>
                {permissionStatus === 'default' && (
                    <Button colorScheme={'success'} renderPrefix={<SvgIcon icon={BsBellFill} size={16}/>} size={'sm'}
                            onClick={onEnableNotification}>
                        Enable
                    </Button>
                )}
            </div>

            {permissionStatus === 'denied' && (
                <div>
                    <Alert colorScheme={'danger'}>
                        You've disallowed notifications in your browser. You'll need to open your browser preferences to
                        change that.
                    </Alert>

                </div>
            )}
            {permissionStatus === 'requested' && (
                <Alert colorScheme={'primary'}>
                    Click Allow in your browser's pop-up to enable notifications.
                </Alert>
            )}
            <Divider className={'my-4'}/>
            </>
    )
}