import {IconButton} from "../../stories/components/Button/src";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import React from "react";
import {IoEllipsisHorizontalCircleOutline} from "react-icons/io5";
import {Tooltip} from "../../stories/components/Tooltip/src";

export default function RecordBtn() {
    return (
        <div className={'IconBtn'}>
            <Tooltip content={'Message Record'}>
                <IconButton variant={'ghost'} size={'sm'} colorScheme={'secondary'}>
                    <SvgIcon icon={IoEllipsisHorizontalCircleOutline} size={23}/>
                </IconButton>
            </Tooltip>
        </div>
    )
}