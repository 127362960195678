import React, {useState} from "react";
import Modal from "react-modal";
import ReactModal from "react-modal";
import {ModalHeader} from "../ModalHeader";
import {Input} from "../../../stories/components/Input/src";
import {ErrorText} from "../../../stories/components/Text/src";
import classNames from "classnames";
import {Row} from "stories/components/Layout";
import {Button} from "../../../stories/components/Button";
import {toast} from "react-hot-toast";
import BaseModal from "../BaseModal";
import styles from "./index.module.scss"

export default function DocumentSearchModal({
                                              isOpen,
                                              onAfterOpen,
                                              onRequestClose,
                                              style,
                                              ...props
                                          }) {

    const [value, setValue] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onChangeText = (text) => {
        setValue(text)
    }

    const onSubmit = () => {

    }

    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.SearchModal}
            contentCenter
        >
            <ModalHeader label={'Search'} onRequestClose={onRequestClose}/>

            <div className={classNames('px-6','py-2')}>
                <Input
                    autoFocus
                    placeholder={'文件或文件夹名称'}
                    isError={errorMessage !== ''}
                    onChangeText={onChangeText}
                />

            </div>
        </BaseModal>
    )
}