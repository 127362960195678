import React, {useState} from "react";
import styles from "../index.module.scss";
import {Switch} from "../../../../stories/components/Switch/src";
import {Radio, RadioGroup} from "../../../../stories/components/Radio/src";
import classNames from "classnames";

export function EmailPushNotificationSection() {
    const [enable, setEnable] = useState(true)
    const [interval, setInterval] = useState(30)

    return (
        <>
            <div className={classNames(styles.ItemRow, enable && 'pb-none')}>
                <div>
                    <div className={styles.ItemLabel}>
                        Email Notifications
                    </div>
                    <div className={styles.SubLabel}>
                        When you’re busy or not online, Platform can send you email notifications for any new direct
                        messages or mentions of your name.
                    </div>
                </div>
                <Switch
                    defaultValue={enable}
                    onChange={setEnable}
                />
            </div>
            {enable && (
                <div className={'pb-4'}>
                    <RadioGroup defaultValue={interval} onChange={setInterval}>
                        <Radio value={15}>once every 15 minutes</Radio>
                        <Radio value={30}>once every 30 minutes</Radio>
                        <Radio value={60}>once an hour at most</Radio>
                    </RadioGroup>
                </div>
            )}
        </>
    )
}