import React from "react";
import styles from "../index.module.scss";
import {
    AvatarSection,
    DestroyAccountSection,
    DisplayNameSection,
    EmailAddressSection,
    LoginPasswordSection,
    TwoFactorAuthenticationSection
} from "../Section";
import {Divider} from "../../../../stories/components/Divider/src";

const Line = () => <Divider className={'my-4'}/>

export function AccountWindow() {
    return (
        <div>
            <AvatarSection/>
            <Line/>
            <DisplayNameSection/>
            <Line/>
            <EmailAddressSection/>
            <Line/>
            <LoginPasswordSection/>
            <Line/>
            <TwoFactorAuthenticationSection/>
            <Line/>
            <DestroyAccountSection/>
            <div style={{height: '40px'}}/>
        </div>
    )
}