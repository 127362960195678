import BaseModal from "./BaseModal";
import WorkspaceIcon from "../WorkspaceIcon";
import {IconButton} from "../../stories/components/Button/src";
import React from "react";
import {AllWorkspace} from "../../pages";

export function WorkspaceManageModal({
                                         isOpen,
                                         onRequestClose
                                     }) {
    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <div className={'w-[600px]'}>
                <AllWorkspace/>
            </div>
        </BaseModal>
    )
}