import React from "react";
import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu
} from "react-contexify";

import {
    BsCursor,
    BsShare,
    BsArchive,
    BsDownload,
    BsCloudDownload,
    BsInputCursor,
    BsFiles,
    BsTrash,
    BsInfoCircle,
    BsArrowRightCircle,
    BsGear,
    BsFolderPlus,
} from "react-icons/bs";
import {AiOutlineEdit} from "react-icons/ai";

const MENU_DATA = {
    send: {
        label: '发送',
        prefixIcon: BsCursor,
        prefixIconSize: 20,
    },
    share: {
        label: '分享',
        prefixIcon: BsShare,
        prefixIconSize: 17,
    },
    archive: {
        label: '归档',
        prefixIcon: BsArchive,
        prefixIconSize: 17,
    },
    download: {
        label: '下载',
        prefixIcon: BsCloudDownload,
        prefixIconSize: 18,
    },
    // moveTo: {
    //     label: '移动到',
    //     prefixIcon: BsFolderPlus,
    //     prefixIconSize: 18,
    // },
    copyTo: {
        label: '移动或复制',
        prefixIcon: BsFiles,
        prefixIconSize: 18,
    },
    rename: {
        label: '重命名',
        prefixIcon: AiOutlineEdit,
        prefixIconSize: 20,
    },
    setting: {
        label: '设置',
        prefixIcon: BsGear,
        prefixIconSize: 17,
    },
    info: {
        label: '详细信息',
        prefixIcon: BsInfoCircle,
        prefixIconSize: 17,
    },
    delete: {
        label: '删除',
        prefixIcon: BsTrash,
        prefixIconSize: 17,
        separator: true,
    },
}

export default function FileItemContextMenu({id, nodeData}) {

    function handleItemClick({event, props, triggerEvent, data}) {
        console.log(event, props, triggerEvent, data);
    }

    return (
        <Menu id={id}>
            {Object.keys(MENU_DATA).map((itemId, index) => {
                const menu = MENU_DATA[itemId]
                const {prefixIcon: PrefixIcon, prefixIconSize,separator} = menu
                return (
                    <React.Fragment  key={index}>
                        {separator&&(<Separator/>)}
                        <Item onClick={handleItemClick} id={itemId}>
                            <div className={'prefixIcon'}>
                                <PrefixIcon style={{width: prefixIconSize + 'px', height: prefixIconSize + 'px'}}/>
                            </div>
                            {menu.label}
                        </Item>
                    </React.Fragment>
                )
            })}
        </Menu>
    )
}