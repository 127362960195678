import React from "react";
// import {Container, Draggable} from "react-smooth-dnd";
import {Container, Draggable} from "@richardrout/react-smooth-dnd";
import classNames from "classnames";
import AsDoneIcon from "../../AsDoneIcon";
import {Avatar, AvatarGroup} from "../../../stories/components/Avatar/src";
import {Text} from "../../../stories/components/Text/src";
import UserAvatar from "../../UserAvatar";

function TaskItem({isDone, ...props}) {
    return (
        <div className={classNames('TaskItem', isDone && 'isDone')}>
            <AsDoneIcon isDone={isDone}/>
            <div className={'content'}>
                <div className={'title'}>
                    Learn the basics with the easy-to-follow Getting Started Guide
                </div>
                <div className={classNames('participantsRow')}>
                    <Text size={'sm'} className={'createdDate'}>
                        Created by Today 19:00
                    </Text>
                    <AvatarGroup maxCount={4} size={'xs'}>
                        <UserAvatar name={'AAA'} bgColor={'red'}/>
                        <UserAvatar name={'BBB'} bgColor={'green'}/>
                        <UserAvatar name={'CCC'} bgColor={'blue'}/>
                        <UserAvatar name={'black'} bgColor={'black'}/>
                        <UserAvatar name={'grey'} bgColor={'yellow'}/>
                        <UserAvatar name={'grey'} bgColor={'yellow'}/>
                        <UserAvatar name={'grey'} bgColor={'yellow'}/>
                        <UserAvatar name={'grey'} bgColor={'yellow'}/>
                        <UserAvatar name={'grey'} bgColor={'yellow'}/>
                    </AvatarGroup>
                </div>
            </div>
        </div>
    )
}

export default function List({index, label, ...props}) {


    function getCardPayload(index, i) {

    }

    function onCardDrop(index, e) {

    }

    return (
        <div className={'TaskList'}>
            <Container
                {...props}
                groupName="col"
                disableScrollOverlapDetection={true}
                onDragStart={e => console.log("drag started", e)}
                onDragEnd={e => console.log("drag end", e)}
                onDrop={e => onCardDrop(index, e)}
                getChildPayload={i =>
                    getCardPayload(index, i)
                }
                dragClass="TaskItemDragging"
                onDragEnter={() => {
                    console.log("drag enter:", index);
                }}
                onDragLeave={() => {
                    console.log("drag leave:", index);
                }}
                onDropReady={p => console.log('Drop ready: ', p)}
                dropPlaceholder={{
                    animationDuration: 150,
                    showOnTop: true,
                    className: 'drop-preview'
                }}
                // style={{maxHeight:'calc(100vh - 300px)',overflowY:'auto',padding:'5px 20px'}}
            >
                {[0, 1, 2, 3, 4, 5].map((item, index) => {
                    return (
                        <Draggable key={item}>
                            <TaskItem isDone={false}/>
                        </Draggable>
                    );
                })}
            </Container>
        </div>
    )
}