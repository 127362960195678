import Dropdown from "rc-dropdown/es";
import Menu, {Item as MenuItem} from "rc-menu/es";
import {Button} from "../../../stories/components/Button/src";
import classNames from "classnames";
import {BsChevronDown, BsPlus, BsUpload} from "react-icons/bs";
import React from "react";
import {SvgIcon} from "../../../stories/components/SvgIcon/src";


const UploadMenu = () => {

    function onUploadMenuClick({key}: { key: any }) {
        console.log(`${key} selected`);
    }

    return (
        <Menu onSelect={onUploadMenuClick} style={{width: '150px'}}>
            <MenuItem eventKey={'file'}>
                <div className={'label'}>文件</div>
            </MenuItem>
            <MenuItem eventKey={'folder'}>
                <div className={'label'}>文件夹</div>
            </MenuItem>
        </Menu>
    );
}

export default function UploadButton() {
    return (
        <Dropdown
            trigger={['click']}
            overlay={UploadMenu}
        >
            <Button colorScheme={'light'}
                    variant={'outline'}
                    className={classNames('px-4')}
                    renderPrefix={<SvgIcon icon={BsUpload} size={16}/>}
                    renderSuffix={<SvgIcon icon={BsChevronDown} size={16}/>}
            >
                上传
            </Button>
        </Dropdown>
    )
}