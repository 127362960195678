import React, {useEffect, useRef, useState} from "react";
import EmojiPicker from "@emoji-mart/react";
import EmojiData from "@emoji-mart/data";
import "./index.scss"

const EmojiPickerBubbleModal = React.forwardRef(({
                                                     position,
                                                     width: modalWidth = 352,
                                                     height: modalHeight = 435,
                                                     children,
                                                     onEmojiSelect,
                                                     onClose,
                                                     ...props
                                                 }, myRef) => {

    const positionStyles = {
        left: (position.x) + 'px',
        top: position.y - modalHeight + 'px',
        width: modalWidth + 'px',
        height: modalHeight + 'px'
    }
    const onLoadEmojiData = async () => {
        const response = await fetch(
            'https://a.mex-cdn.com/sys/emoji-mart/data',
        )
        return response.json()
    }

    //  处理超出边界问题
    // if (position.x + modalWidth > window.innerWidth) positionStyles.left = position.x - modalWidth + 'px'
    // if (position.y + modalHeight > window.innerHeight) {
    //     const exceedDistance = (position.y + modalHeight) - window.innerHeight
    //     positionStyles.top = position.y - exceedDistance + 'px'
    // }

    return (
        <div className={'EmojiPickerBubbleModal'} ref={myRef} style={positionStyles}>
            <EmojiPicker
                data={EmojiData}
                perLine={8}
                emojiButtonSize={40}
                emojiButtonRadius={'5px'}
                theme={'light'}
                skinTonePosition={'none'}
                searchPosition={'none'}
                previewPosition={'none'}
                navPosition={'bottom'}
                onEmojiSelect={onEmojiSelect}
                set={'native'}
            />
            {/*<div ref={ref} />*/}
        </div>
    )
})

export default EmojiPickerBubbleModal