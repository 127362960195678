import {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import $api from "../api";

export function useTeamMembers(params = {}) {
    const {team} = useSelector(state => state)
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('');

    const [errMsg, setErrMsg] = useState(null)
    const [status, setStatus] = useState('success')
    const [members, setMembers] = useState(team.members)

    const onLoadTeamMembers = async () => {
        setStatus('loading');
        setErrMsg(null);

        try {
            const members = await $api.team.getMembers( {team_id:team.id,search, sortBy, sortOrder})
            setMembers(members);
            setStatus('success')
        } catch (error) {
            setErrMsg(error.message);
            setStatus('error')
        }
    };

    useEffect(() => {
        if (!team.members.length) {
            onLoadTeamMembers()
        }
    }, []);

    return {
        search, setSearch,
        sortBy, setSortBy,
        sortOrder, setSortOrder,
        errMsg,
        status,
        members, setMembers,
        onLoadTeamMembers
    };
};