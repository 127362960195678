import React from "react";
// import {Container, Draggable} from "react-smooth-dnd";
import {Container, Draggable} from "@richardrout/react-smooth-dnd";

import List from "./List";
import classNames from "classnames";
import {Heading} from "../../../stories/components/Text/src";
import {BoardViewColumnDragHandleCls} from "./constants";


export default function Column({index, label, ...props}) {

    return (
        <>
            <Draggable key={index}>
                <div className={'TaskColumn'}>
                    <div className={classNames('Header', BoardViewColumnDragHandleCls)}>
                        <Heading size={'sm'} className={'label'}>
                            {label}
                        </Heading>
                    </div>
                    <List/>
                </div>
            </Draggable>
        </>
    )
}