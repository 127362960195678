import React, {ReactNode} from 'react';
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

interface FlexProps extends React.HtmlHTMLAttributes<HTMLElement> {
    children?: ReactNode;
    gap?: 0 | 1 | 2 | 3 | 4 | 5

    [propName: string]: any
}

export function Row({
                        children = null,
                        gap = 0,
                        justifyContent,
                        alignItems,
                        ...props
                    }: FlexProps) {
    const classes = classNames(`${UIPrefix}row`, gap && `gx-${gap}`, props.className)
    const styles = {justifyContent, alignItems}
    return (
        <div
            {...props}
            className={classes}
        >
            {children}
        </div>
    )
}