import React from "react";
import classNames from "classnames";

export function UnreadBadge({num = 0, className, ...props}) {

    if (!num) return null

    return (
        <div
            className={classNames('bg-rose-600 text-white h-[18px] leading-[18px] px-[8px] text-xs font-bold rounded-2xl', className)}
            style={{fontFamily: '"Salesforce Sans Web","Arial",sans-serif,"Quip Glyphs"'}}
            {...props}
        >
            {num}
        </div>
    )
}