import React from "react";
import styles from "../index.module.scss";
import {LanguageSection, TimeZoneSection} from "../Section";
import {Divider} from "../../../../stories/components/Divider/src";

export function LanguageRegionWindow() {
    return (
        <div>
            <LanguageSection/>
            <Divider className={'my-4'}/>
            <TimeZoneSection/>
            <Divider className={'my-4'}/>
        </div>
    )
}