import {Input} from "../../stories/components/Input/src";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import React, {useState} from "react";
import {BsSearch} from "react-icons/bs";
import {IoClose} from "react-icons/io5";

export function SearchInput({
                                onSearch,
                                onChangeValue,
                                ...props
                            }) {
    function onClear(e) {
        onChangeValue && onChangeValue('')
    }

    function onSubmit(e) {
        e.preventDefault()
        onSearch && onSearch()
    }

    return (
        <form onSubmit={onSubmit}>
            <Input
                renderPrefix={(
                    <div
                        className={'h-full px-3 flex items-center text-stone-500'}>
                        <BsSearch/>
                    </div>
                )}
                renderSuffix={props.value && (
                    <div
                        className={'h-full px-2 flex items-center cursor-pointer text-stone-600 hover:text-black active:text-stone-600'}
                        onClick={onClear}>
                        <IoClose className={'text-xl'}/>
                    </div>
                )}
                {...props}
                onChange={({target: {value}}) => onChangeValue && onChangeValue(value)}
            />
        </form>
    )
}