import React, {HTMLAttributes, useContext, useState} from "react";
import classNames from "classnames";
import {TabsContext} from "./Tabs";
import {UIPrefix} from "../../../constants";

interface TabBarProps {
    colorScheme?: "grey" | 'primary' | 'info' | 'danger' | 'warning'
    size?: 'sm' | 'md' | 'lg'
    variant?: 'line' | 'outline' | 'solid' | 'ghost'
    orientation?: 'horizontal' | 'vertical'
    children?: any;
    selectedValue?: string | number

    /**
     * a tab's state can be controlled. Make sure to include an onChange as well, or else the tabs will not be interactive.
     * @param value
     */
    onChange?: (value: any) => void

    [propName: string]: any
}


export const TabBar = ({
                           colorScheme = "primary",
                           size = 'md',
                           variant = 'outline',
                           orientation = 'horizontal',
                           onChange,
                           selectedValue = '',
                           ...props
                       }: TabBarProps) => {
    const cls = classNames(`${UIPrefix}tab-bar`, `size-${size}`, orientation, `${colorScheme}-${variant}`, props.className)
    const {selectedIndex, setSelectedIndex} = useContext(TabsContext)!

    return (
        <div {...props} className={cls}>
            {
                React.Children.toArray(props.children).map((child: any, index: number) => {
                    const childProps = {
                        ...child.props,
                        key: index,
                        value: Object.keys(child.props).indexOf('value')?child.props.value:index,
                        colorScheme,
                        variant,
                        selected: onChange ? selectedValue === child.props?.value : selectedIndex === index,
                        size,
                        onClick: (e: React.ChangeEvent<HTMLDivElement>) => {
                            setSelectedIndex(index)
                        }
                    }
                    return React.cloneElement(child, childProps)
                })
            }
        </div>
    )
}