import {SiXero} from "react-icons/si";
import React from "react";

export const XeroIcon = ({
                             size = 20,
                             fill = '#13b5ea',
                             ...props
                         }) => {

    return (
        <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 53 53" fill="none">
            <path
                d="M26.4992 0.0527344C11.8623 0.0527344 0 11.7617 0 26.204C0 40.6452 11.8623 52.3554 26.4992 52.3554C41.1318 52.3554 53 40.6452 53 26.204C53 11.7617 41.1318 0.0527344 26.4992 0.0527344Z"
                fill={fill}></path>
            <path
                d="M19.4255 25.1079C19.9011 23.2503 21.5775 21.9536 23.5036 21.9536C25.4396 21.9536 27.108 23.247 27.5858 25.1079H19.4255ZM29.0672 26.2366C29.3402 25.9015 29.4429 25.4631 29.3557 25.001C29.0006 23.3265 28.0873 21.9852 26.7147 21.1221C25.7719 20.5251 24.6717 20.2097 23.5327 20.2097C22.2761 20.2097 21.0798 20.5863 20.0728 21.299C18.499 22.4136 17.5596 24.2343 17.5596 26.1698C17.5596 26.6556 17.6199 27.1379 17.7387 27.6031C18.3436 29.9599 20.3778 31.7424 22.8007 32.0386C23.035 32.0665 23.2696 32.0807 23.4979 32.0807C23.9833 32.0807 24.4556 32.0191 24.9409 31.8933C25.572 31.7423 26.1732 31.4877 26.7284 31.1371C27.2529 30.7986 27.736 30.3425 28.244 29.7064L28.2773 29.6721C28.4464 29.4618 28.5251 29.1915 28.493 28.9307C28.4641 28.6972 28.352 28.4957 28.1774 28.3634C28.0118 28.2363 27.8144 28.1663 27.622 28.1663C27.4343 28.1663 27.1588 28.234 26.9095 28.5576L26.89 28.5834C26.8077 28.6928 26.7225 28.8058 26.6246 28.9179C26.2888 29.2936 25.9028 29.603 25.4783 29.8374C24.8705 30.1602 24.2137 30.3263 23.5279 30.3312C21.3734 30.3077 20.054 28.8796 19.5384 27.5532C19.4575 27.3144 19.3997 27.0964 19.3625 26.8943C19.3619 26.8738 19.3603 26.8523 19.359 26.8313L27.7006 26.8298C28.28 26.8175 28.7654 26.6068 29.0672 26.2366Z" fill={"white"}></path>
            <path
                d="M41.2233 24.5579C40.3858 24.5579 39.7043 25.2348 39.7043 26.0667C39.7043 26.8988 40.3858 27.5758 41.2233 27.5758C42.0593 27.5758 42.7394 26.8988 42.7394 26.0667C42.7394 25.2348 42.0593 24.5579 41.2233 24.5579Z" fill={"white"}></path>
            <path
                d="M35.6896 21.1567C35.6896 20.6828 35.3007 20.2974 34.8238 20.2974L34.58 20.2938C33.84 20.2938 33.1404 20.5197 32.5509 20.9479C32.4382 20.6012 32.1081 20.3574 31.7358 20.3574C31.256 20.3574 30.878 20.7319 30.875 21.2102L30.8778 31.1105C30.881 31.5821 31.2664 31.9658 31.7373 31.9658C32.211 31.9658 32.5966 31.582 32.5966 31.1101V25.0215C32.5966 23.0488 32.7672 22.2242 34.4836 22.0128C34.626 21.9959 34.7788 21.9949 34.8176 21.9949C35.3228 21.9767 35.6896 21.6242 35.6896 21.1567Z" fill={"white"}></path>
            <path
                d="M13.0526 26.1305L17.5041 21.6836C17.6669 21.5245 17.7567 21.3106 17.7567 21.0815C17.7567 20.6084 17.3684 20.2235 16.8914 20.2235C16.6605 20.2235 16.4426 20.3146 16.2784 20.4796L11.8266 24.9041L7.35704 20.4714C7.19408 20.3116 6.97812 20.2235 6.74871 20.2235C6.27397 20.2235 5.88782 20.6084 5.88782 21.0815C5.88782 21.3111 5.97997 21.529 6.14727 21.6947L10.602 26.1269L6.15399 30.5648C5.98236 30.7279 5.88782 30.9469 5.88782 31.1811C5.88782 31.654 6.27397 32.0389 6.74871 32.0389C6.97463 32.0389 7.19059 31.9513 7.35738 31.7914L11.8222 27.3492L16.2677 31.7706C16.4382 31.945 16.6596 32.0411 16.8914 32.0411C17.3684 32.0411 17.7567 31.6553 17.7567 31.1811C17.7567 30.9547 17.667 30.7401 17.5044 30.5765L13.0526 26.1305Z" fill={"white"}></path>
            <path
                d="M41.222 30.216C38.9223 30.216 37.0516 28.3547 37.0516 26.0667C37.0516 23.7762 38.9223 21.9126 41.222 21.9126C43.5192 21.9126 45.388 23.7762 45.388 26.0667C45.388 28.3547 43.5192 30.216 41.222 30.216ZM41.223 20.1443C37.9429 20.1443 35.2742 22.801 35.2742 26.0664C35.2742 29.331 37.9429 31.987 41.223 31.987C44.5016 31.987 47.1689 29.331 47.1689 26.0664C47.1689 22.801 44.5016 20.1443 41.223 20.1443Z" fill={"white"}></path>
        </svg>
    )
}