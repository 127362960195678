import React, {useState} from "react";
import Modal from "react-modal";
import ReactModal from "react-modal";
import {ModalHeader} from "../ModalHeader";
import {TextArea, Input} from "../../../stories/components/Input/src";
import {ErrorText, Heading} from "../../../stories/components/Text/src";
import classNames from "classnames";
import {Row} from "stories/components/Layout";
import {Button, IconButton} from "../../../stories/components/Button";
import {toast} from "react-hot-toast";
import styles from "./index.module.scss"
import {SvgIcon} from "../../../stories/components/SvgIcon/src";
import {BsXLg} from "react-icons/bs";
import {Divider} from "../../../stories/components/Divider/src";

interface Props extends ReactModal.Props {
    onRequestClose: () => void
}

const modalStyles: { content: object; overlay: object } = {
    content: {
        inset: 0,
        position: 'relative',
        width: '80vw',
        minWidth: '600px',
        maxWidth: '600px',
        height: '80vh',
        maxHeight: '800px',
        boxShadow: '0 2px 8px 0 rgb(0 0 0 / 16%)',
        border: 'none',
        borderRadius: '10px',
        padding: '0px',
        backgroundColor: 'white',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 10000000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}

export default function CreateTaskModal({
                                            isOpen,
                                            onAfterOpen,
                                            onRequestClose,
                                            style,
                                            ...props
                                        }: Props) {

    const [value, setValue] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)


    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            ariaHideApp={false}
            style={modalStyles}
            {...props}
        >
            <ModalHeader label={'新建任务'} onRequestClose={onRequestClose}/>

            <div className={classNames(styles.CreateTaskBody)}>
                <TextArea
                    rows={3}
                    className={styles.titleInput}
                    placeholder={'Type a task title'}
                />
                <Divider className={'mx-xs'}/>
                
            </div>
        </Modal>
    )
}