import React from 'react';
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

interface SpinnerProps extends React.HTMLAttributes<HTMLElement> {
    /**
     * If you don’t fancy a border spinner, switch to the grow spinner. While it doesn’t technically spin, it does repeatedly grow!
     */
    variant?: 'border' | 'grow'
    /**
     * colorScheme combinations.
     */
    colorScheme?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'|'white';
    /**
     * The size of the Spinner
     */
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}


/**
 * Primary UI component for user interaction
 */
export const Spinner = ({
                            size = 'xs',
                            variant = 'border',
                            colorScheme = 'primary',
                            className,
                            ...props
                        }: SpinnerProps) => {
    const classes = [`${UIPrefix}spinner`, `size-${size}`, `variant-${variant}`, `text-${colorScheme}`, className]

    return (
        <div className={classNames(classes)} {...props}>
            <span className="visually-hidden">Loading...</span>
        </div>
    );
};
