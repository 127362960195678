import * as Select from "@radix-ui/react-select";
import React, {useState, Fragment, useEffect} from "react";
import {BsCheckLg, BsCaretDownFill, BsCaretUpFill} from "react-icons/bs";
import classNames from "classnames";
import {Listbox, Transition} from '@headlessui/react'
import $api from "../api";
import {useSelector} from "react-redux";
import UserAvatar from "./UserAvatar";
import {Spinner} from "../stories/components/Spinner/src";


export default function SelectTeamMember({
                                             value = null,
                                             onChange,
                                             placeholder = 'Select member',
                                             ...props
                                         }) {
    const {team,} = useSelector(state => state)
    const [loadingMembers, setLoadingMembers] = useState(false)
    const [members, setMembers] = useState(team.members)

    useEffect(() => {
        // onLoadMember()
    }, [])

    return (
        <Listbox value={value} onChange={onChange}>
            {({open}) => (
                <>
                    <div className="relative">
                        <Listbox.Button
                            className={`relative w-full cursor-default rounded bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 sm:leading-6 ${open ? 'outline-none ring-2 ring-primary-600' : 'ring-1 ring-inset ring-gray-300 outline-primary-600'}`}>
                            {value ? (
                                <div className="flex items-center">
                                    <UserAvatar id={value.user_id}
                                                name={value.name}
                                                src={value.avatar_url} size={'xs'}/>
                                    <span className="ml-3 block truncate">{value.name}</span>
                                </div>
                            ) : (
                                <div className={'text-gray-400'}>
                                    {placeholder}
                                </div>
                            )}
                            <div
                                className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-3">
                                <BsCaretDownFill className={`text-gray-400 rotate-${open ? 180 : 0}`}
                                                 aria-hidden="true"/>
                            </div>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            {loadingMembers ? (
                                <div
                                    className={'absolute z-10 mt-1 h-36 w-full overflow-auto rounded-md bg-white border shadow-lg flex items-center justify-center'}>
                                    <Spinner size={'md'}/>
                                </div>
                            ) : (
                                <Listbox.Options
                                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg border outline-none">
                                    {members.map((member, index) => (
                                        <Listbox.Option
                                            key={member.id}
                                            className={({active}) =>
                                                classNames(
                                                    active ? 'bg-primary-600 text-white' : 'text-gray-900',
                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                )
                                            }
                                            value={member}
                                        >
                                            {({selected, active}) => (
                                                <>
                                                    <div className="flex items-center">
                                                        <UserAvatar id={member.user_id} name={member.name}
                                                                    src={member.avatar_url} size={'xs'}/>
                                                        <div
                                                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                            {member.name}
                                                        </div>
                                                    </div>

                                                    {selected && (
                                                        <div
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-primary-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <BsCheckLg className="h-4 w-4" aria-hidden="true"/>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            )}

                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}