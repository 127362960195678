import {IconButton} from "../../stories/components/Button/src";
import React from "react";
import {BsXLg} from "react-icons/bs";

export function CloseButton({
                                ...props
                            }) {
    return (
        <IconButton
            variant={'ghost'}
            colorScheme={'secondary'}
            {...props}>
            <BsXLg/>
        </IconButton>
    )
}