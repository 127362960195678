import React, {useEffect, useState} from "react";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import styles from "../../stylesheets/components/WorkspaceWindow.module.scss";
import classNames from "classnames";
import BoardView from "../../components/Task/BoardView";
import FilterMenuButton from "../Todo/FilterMenuButton";
import DisplayButton from "../Todo/DisplayButton";
import {Row} from "../../stories/components/Layout/src";
import {Button} from "../../stories/components/Button/src";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import {BsFillPlusCircleFill} from "react-icons/bs";
import CreateTaskModal from "../../components/Modal/CreateTaskModal";

export function Task() {
    const [showCreateTaskModal, setShowCreateTaskModal] = useState(false)

    function onSwitchCreateTaskModal() {
        setShowCreateTaskModal(!showCreateTaskModal)
    }
    return (
        <div className={styles.WorkspaceTaskPane}>
            <div className={styles.Header}>
                <Button
                    renderPrefix={<SvgIcon icon={BsFillPlusCircleFill} size={16}/>}
                    onClick={onSwitchCreateTaskModal}
                >
                    新建任务
                </Button>
                <Row>
                    <FilterMenuButton/>
                    <DisplayButton/>
                </Row>
            </div>
            <div className={styles.Body}>
                <BoardView/>
            </div>
            {
                showCreateTaskModal&&(
                    <CreateTaskModal
                        isOpen={showCreateTaskModal}
                        onRequestClose={onSwitchCreateTaskModal}
                    />
                )
            }
        </div>
    )
}