import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 20000,
    maxRedirects: 0, // 不自动重定向
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
    },
});

// 请求拦截器
axiosInstance.interceptors.request.use(async config => {
    // 在请求发送前做一些处理
    return config;
}, error => {
    // 处理请求错误
    return Promise.reject(error);
});

// 响应拦截器
axiosInstance.interceptors.response.use(response => {
    // 在响应成功后做一些处理
    return response.data;
}, error => {   // 处理响应错误
    if (error.response) {   // 服务器响应了错误状态码
        if (error.response.status === 401) {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
            return new Promise(() => {
            }); // 返回一个空的Promise，防止错误继续传递
        }
        if (error.response.status === 419) {
            return Promise.reject({code: 'CSRF_ERROR', message: 'Something went wrong, please try again'});
        }
        if (error.response.data?.code) {
            return Promise.reject({
                code: error.response.data.code,
                message: error.response.data.message,
                data: error.response.data
            });
        }
    } else if (error.request) {     // 请求发出了但没有收到响应,处理请求超时等错误
        return Promise.reject({code: 'TIMEOUT', message: 'Request timed out'});

    } else {    // 在设置请求时发生了一些事情，触发了一个错误,处理其他错误

    }
    return Promise.reject({code: 'ERROR', message: 'Oops, something went wrong'});
});
export default axiosInstance;