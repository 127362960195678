import React, {
    createContext,
    ElementType,
    HTMLAttributes,
    ReactElement,
    ReactNode,
    SetStateAction,
    useMemo,
    useState
} from "react";
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

interface TabsProps extends HTMLAttributes<HTMLDivElement> {
    colorScheme?: "grey" | "red" | "orange" | "yellow" | "green" | "teal" | "blue" | "cyan" | "purple" | "pink" | "primary"
    size?: "sm" | "md" | "lg"
    variant?: 'line'
    orientation?: 'horizontal' | 'vertical'
    onTabChange?: ({index, value}: { index: number; value: any }) => void

    [propName: string]: any
}

export const TabsContext = createContext<{
    selectedIndex: number,
    setSelectedIndex: (color: number) => void,
} | null>(null);

export const Tabs = ({
                         colorScheme = "primary",
                         size = 'md',
                         orientation = 'horizontal',
                         variant = 'line',
                         defaultIndex = 0,
                         ...props
                     }: TabsProps) => {
    const cls = classNames(`${UIPrefix}tabs`, `size-${size}`, orientation, `${colorScheme}-${variant}`, props.className)
    const [selectedIndex, setSelectedIndex] = useState<number>(defaultIndex);
    const context = useMemo(() => ({selectedIndex, setSelectedIndex}), [selectedIndex, setSelectedIndex]);

    return (
        <div {...props} className={cls}>
            <TabsContext.Provider value={context}>
                {props.children}
            </TabsContext.Provider>
        </div>
    )
}