import React, {useEffect, useRef, useState} from "react";
import {IconButton} from "../../stories/components/Button/src";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import EmojiPickerBubbleModal from "../BubbleModal/EmojiPickerBubbleModal";
import {BsEmojiSmile, BsFolderPlus, BsCursorFill} from "react-icons/bs";
import {Tooltip} from "../../stories/components/Tooltip/src";
import axios from "axios";

export default function EmojiBtn({onSelect}) {
    const [modalPosition, setModalPosition] = useState(null)
    const modalRef = useRef(null);

    function onOpenModal(event) {
        event.stopPropagation()
        setModalPosition({x: 0, y: -1})
    }

    function onEmojiSelect(emoji) {
        onSelect && onSelect(emoji)
        setModalPosition(null)
    }

    useEffect(() => {
        if (modalPosition) {
            document.addEventListener("click", eleClickCallback, false);
            return () => {
                document.removeEventListener("click", eleClickCallback, false);
            };
        }
    }, [modalPosition]);

    function eleClickCallback(event) {
        if (modalRef.current.contains(event.target)) return
        setModalPosition(null);
    }

    return (
        <div className={'IconBtn'}>
          <Tooltip content={'Emoji'}>
              <IconButton variant={'ghost'} size={'sm'} colorScheme={'secondary'} onClick={onOpenModal}>
                  <SvgIcon icon={BsEmojiSmile}/>
              </IconButton>
          </Tooltip>
            {modalPosition && (
                <EmojiPickerBubbleModal
                    position={modalPosition}
                    ref={modalRef}
                    onEmojiSelect={onEmojiSelect}
                />
            )}
        </div>
    )
}