import React, {useState} from "react";
import "./ListHeader.scss"
import {Col, Row} from "../../stories/components/Layout/src";
import classNames from "classnames";
import {Resizable} from "re-resizable";

const resizeDefaultProps = {
    enable: {
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
    },
    defaultSize: {
        width: 320,
    },
    className: 'resizable',
    handleClasses: {right: 'rightResizerHandler'},
}

export function ListHeader({colsWidth, onChangeColsWidth, ...props}) {
    const [nameCellWidth, setNameCellWidth] = useState(0)

    function onNameCellResize(event, direction, elementRef, delta) {
        onChangeColsWidth({...colsWidth, name: elementRef.offsetWidth})
    }

    function onModifiedCellResize(event, direction, elementRef, delta) {
        onChangeColsWidth({...colsWidth, modified_at: elementRef.offsetWidth})
    }

    function onSizeCellResize(event, direction, elementRef, delta) {
        onChangeColsWidth({...colsWidth, size: elementRef.offsetWidth})
    }

    const lastResizeProps = {...resizeDefaultProps, enable: {...resizeDefaultProps.enable, right: false}}

    return (
        <Row className={'ResizableListHeader'}>
            <Resizable {...resizeDefaultProps}
                       minWidth={'300px'}
                       defaultSize={{width: colsWidth.name + 'px'}}
                       onResize={onNameCellResize}>
                <Col className={classNames('nameCol')}>
                    名称
                </Col>
            </Resizable>
            <Resizable {...resizeDefaultProps}
                       minWidth={'150px'}
                       defaultSize={{width: colsWidth.modified_at + 'px'}}
                       onResize={onModifiedCellResize}>
                <Col>
                    修改时间
                </Col>
            </Resizable>
            <Resizable {...lastResizeProps}
                       onResize={onSizeCellResize}
                       minWidth={'150px'}
                       defaultSize={{width: colsWidth.size + 'px'}}
            >
                <Col>
                    大小
                </Col>
            </Resizable>
        </Row>
    )
}