import React, {ReactNode} from "react";
import BaseModal, {BaseModalProps} from "../Modal/BaseModal";
import classNames from "classnames";
import {Button} from "../../stories/components/Button/src";
import Balancer from 'react-wrap-balancer'

interface ConfirmAlertProps extends BaseModalProps {
    label?: string
    onConfirm?: () => void
    cancelText?: string
    confirmText?: string
    confirmColorScheme?: 'primary' | 'danger'
    showHeader?: boolean
    confirming?: boolean
}

export default function ConfirmAlert({
                                         label,
                                         confirmText = 'Confirm',
                                         confirmColorScheme = 'primary',
                                         onRequestClose,
                                         confirming = false,
                                         onConfirm,
                                         children,
                                         className,
                                         ...props
                                     }: ConfirmAlertProps) {
    return (
        <BaseModal className={'w-[360px]'} onRequestClose={onRequestClose} {...props}>
            <div className={'flex flex-col items-center p-10 gap-4 text-center'}>
                {label && (
                    <Balancer className={classNames('text-lg font-semibold truncate mb-2')}>{label}</Balancer>
                )}
                <Balancer className={''}>{children}</Balancer>
                <div className={'flex justify-center gap-4 w-full mt-4'}>
                    <Button
                        colorScheme={'secondary'}
                        onClick={onRequestClose}>
                        Cancel
                    </Button>
                    <Button
                        colorScheme={confirmColorScheme}
                        loading={confirming}
                        onClick={onConfirm}>
                        {confirmText}
                    </Button>
                </div>
            </div>
        </BaseModal>
    )
}