import React from "react";
import {Avatar, AvatarProps} from "../../stories/components/Avatar/src";

interface UserAvatarProps extends AvatarProps {
    id?: string | number
}

export default function UserAvatar({
                                       id = 'id',
                                       badgeCount = 0,
                                       ...props
                                   }: UserAvatarProps) {

    const randomColor = require('randomcolor');
    const bgColor = randomColor({luminosity: 'dark', seed: id,});

    return (
        <Avatar
            {...props}
            bgColor={bgColor}
        />
    )
}
