import React, {ReactElement, ReactNode, useEffect, useState} from 'react';
import classNames from "classnames";
import {Radio} from "./Radio";
import {CheckboxGroupProps} from "../../Checkbox/src";
import {UIPrefix} from "../../../constants";

export interface RadioGroupProps extends Omit<CheckboxGroupProps, 'value' | 'defaultValue' | 'onChange'> {
    value?: string | number
    defaultValue?: string | number
    onChange?: (value: any) => void
    children?: ReactNode | ReactNode[]
}

export const RadioGroup = ({
                               direction = 'vertical',
                               onChange,
                               defaultValue,
                               ...props
                           }: RadioGroupProps) => {
    const [value, setValue] = useState(defaultValue)
    const stateValue = props.value || value
    const cls = classNames(`${UIPrefix}checkbox-group`, direction,)

    return (
        <div className={cls}>
            {
                React.Children.toArray(props.children).map((item: any, index: number) => {
                    const isChecked = stateValue === item.props.value
                    return (
                        <Radio key={index}
                               value={item.props.value}
                               checked={isChecked}
                               inputType={'radio'}
                               onChange={() => {
                                   !props.value && setValue(item.props.value)
                                   onChange && onChange(item.props.value)
                               }}
                        >
                            {item.props.children}
                        </Radio>
                    )
                })
            }
        </div>
    )
}
