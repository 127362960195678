import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {FixedSizeList as List} from 'react-window';
import WorkspaceIcon from "../components/WorkspaceIcon";
import {Button, IconButton} from "../stories/components/Button/src";
import SimpleScroll from "../components/SimpleScroll";
import {useWindowSize} from "react-use";
import {SearchInput} from "../components/ui";
import {CreateWorkspaceModal} from "../components/Modal";
import {useDispatch, useSelector} from "react-redux";
import {fetchWorkspaces, setSearch} from "../store/reducers/workspace";
import ContentLoader from "react-content-loader";
import {NavLink} from "react-router-dom";

const MODAL = {
    CREATE_WORKSPACE: 'CREATE_WORKSPACE',
}

function Empty() {
    return (
        <div className={'flex flex-col gap-2 items-center py-16'}>
            <div className={'text-lg font-semibold'}>No results</div>
            <div>You may want to try adjusting your filters.</div>
        </div>
    )
}

function ListSkeleton() {
    const containerWidth = 320

    return (
        <div className={'px-6'}>
            {
                Array.from({length: 6}).map((_, index) => (
                    <div key={index} className={'h-16 px-4 border-b'}>
                        <ContentLoader viewBox={`0 0 ${containerWidth} 64`}
                                       height={64}
                                       width={containerWidth}>
                            <rect y="14" cx="10" cy="20" rx="5" width={160} height="12"/>
                            <rect y="38" cx="10" cy="20" rx="5" width="260" height="12"/>
                        </ContentLoader>
                    </div>
                ))
            }
        </div>
    )
}

const ListItem = ({index, style}) => {
    const {data} = useSelector(state => state.workspace)
    const workspace = data[index]

    return (
        <div className={'px-6'} style={style}>
            <NavLink to={`/workspace/${workspace.id}`}
                     className={({isActive}) => `h-full w-full block flex items-center transition-colors border-b px-4 ${isActive ? 'bg-stone-200 dark:bg-stone-800' : 'hover:bg-stone-100 hover:dark:bg-stone-800'}`}>
                <div>
                    <div className={'font-semibold flex items-center gap-2'}>
                        <WorkspaceIcon/>{workspace.name}
                    </div>
                    <div className={'flex items-center gap-6 text-sm px-6 text-gray-500 leading-1 mt-1'}>
                        {workspace.member_count} members
                        <div className={''}>
                            {workspace.description}
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
}

export function AllWorkspace() {
    const {height: windowHeight} = useWindowSize();
    const [showModal, setShowModal] = useState()
    const {data: workspaces, status, errMsg, pagination, search} = useSelector(state => state.workspace)
    const dispatch = useDispatch()

    const onSearchInputChange = (value) => {
        dispatch(setSearch(value))
        if (!value) {
            dispatch(fetchWorkspaces())
        }
    }

    const onSearch = () => {
        dispatch(fetchWorkspaces())
    }

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchWorkspaces())
        }
    }, [])

    return (
        <>
            <div className={'h-full w-full'}>
                <div className={'mx-6 border-b'}>
                    <div
                        className={classNames(`h-16 flex items-center justify-between`)}>
                        <div className={'font-semibold flex items-center'}>
                            All workspaces
                        </div>
                        <div>
                            <Button onClick={() => setShowModal(MODAL.CREATE_WORKSPACE)}>
                                Create workspace
                            </Button>
                        </div>
                    </div>
                    <div className={'pt-2 pb-6'}>
                        <SearchInput
                            variant={'filled'}
                            value={search}
                            onSearch={onSearch}
                            onChangeValue={onSearchInputChange}
                            placeholder={'Search workspaces'}
                        />
                    </div>
                </div>

                {status === 'loading' && (<ListSkeleton/>)}
                {status === 'success' && (!workspaces.length ? (<Empty/>) : (
                    <SimpleScroll className={''}
                                  style={{height: '400px'}}>
                        {({scrollableNodeRef, contentNodeRef}) => (
                            <List
                                height={windowHeight - 64}
                                outerRef={scrollableNodeRef}
                                innerRef={contentNodeRef}
                                itemCount={workspaces.length}
                                itemSize={64}
                                className={classNames('simplebar-content-wrapper')}>
                                {ListItem}
                            </List>
                        )}
                    </SimpleScroll>
                ))}
            </div>

            {showModal === MODAL.CREATE_WORKSPACE && (
                <CreateWorkspaceModal
                    isOpen={true}
                    onRequestClose={() => setShowModal('')}
                />
            )}
        </>
    )
}