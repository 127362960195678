import Tippy, {TippyProps} from "@tippyjs/react";
import React from "react";
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

interface TooltipProps extends TippyProps {
    colorScheme?: 'dark' | 'secondary'
}

export function Tooltip({content = '', colorScheme = 'dark', className, ...props}: TooltipProps) {
    const cls = classNames(`${UIPrefix}tooltip`, colorScheme, className)
    return (
        <Tippy content={content} delay={[500, null]} animation={''} {...props} className={cls}>
           <div>
               {props.children}
           </div>
        </Tippy>
    )
}