import React from "react";
import styles from "./index.module.scss"
import UserAvatar from "../UserAvatar";
import classNames from "classnames";

export default function ChatMessageItem({
                                            directory = 'ltr',
                                            showName = false,
                                            content,
                                            ...props
                                        }) {
    return (
        <div className={classNames(styles.ChatMessageItem, styles[directory])}>
            <UserAvatar id={'UFH278BF'} name={'Fred'} className={styles.avatar} size={'sm'}/>
            <div className={'px-2'}>
                {directory === 'ltr' && (
                    <div className={styles.name}>
                        Fred Xu
                    </div>
                )}

                <div className={styles.content}>
                    What else is the whole life of mortals but a sort of comedy, in which the various actors, disguised
                    by various costumes and masks, walk on and play each one his part, until the manager waves them off
                    the stage
                </div>

                <div className={styles.attachment}>

                </div>
            </div>
        </div>
    )
}