import React, {useState} from "react";
import styles from "../index.module.scss";
import HelpTipIcon from "../../../HelpTipIcon";
import classNames from "classnames";
import {Button} from "../../../../stories/components/Button/src";
import {Input} from "../../../../stories/components/Input/src";
import {Row} from "../../../../stories/components/Layout/src";
import {useDispatch, useSelector} from "react-redux";

export function EmailAddressSection() {
    const [isEditMode, setIsEditMode] = useState(false)
    const {email} = useSelector((state) => state.user);

    const Preview = (
        <>
            <div>
                <div className={styles.ItemLabel}>
                    Email Address
                </div>
                {/*<Row>*/}
                {/*    Your now email address is*/}
                {/*    <div className={classNames(styles.defaultValue,'ms-xs')}>*/}
                {/*        fred.xu@mexgroup.com.au*/}
                {/*    </div>*/}
                {/*</Row>*/}
                <div className={classNames(styles.defaultValue, 'mb-2')}>
                    {email}
                </div>
            </div>
            <Button
                tip={'Modification is not yet supported'}
                colorScheme={'secondary'}
                variant={'outline'}
                size={'sm'}
                onClick={() => setIsEditMode(true)}
                disabled
            >
                Change Email
            </Button>
        </>
    )

    const Form = (
        <div>
            <div className={styles.ItemLabel}>
                Email Address
            </div>
            <Input defaultValue={'Fred Xu'} className={'mb-4'} autoFocus/>
            <Row>
                <Button className={'me-4'} size={'sm'}>Save</Button>
                <Button variant={'outline'} colorScheme={'secondary'} size={'sm'}
                        onClick={() => setIsEditMode(false)}>Cancel</Button>
            </Row>
        </div>
    )

    return (
        <div className={styles.ItemRow}>
            {isEditMode ? Form : Preview}
        </div>
    )
}