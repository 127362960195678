import React, {forwardRef, ReactNode} from 'react';
import classNames from "classnames";
import {Spinner} from "../../Spinner/src";
import {Row} from "../../Layout/src";
import {Tooltip} from "../../Tooltip/src";
import {UIPrefix} from "../../../constants";

export interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
    /**
     * One or more button colorScheme combinations.
     */
    colorScheme?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'indigo' | 'teal' | 'light' | 'yale' | 'sky';
    /**
     * One or more button variant combinations.
     */
    variant?: 'solid' | 'outline' | 'ghost';
    /**
     * Defines HTML button type attribute.
     */
    type?: 'button' | 'submit' | 'reset';
    /**
     * How large should the button be?
     */
    size?: 'sm' | 'md' | 'lg';
    /**
     * Is round ?
     */
    tip?: string | undefined;
    /**
     * Defines HTML button disabled attribute.
     */
    disabled?: boolean;
    /**
     * Is round ?
     */
    rounded?: boolean;
    /**
     * Is loading ?
     */
    loading?: boolean;
    isBlock?: boolean;
    /**
     * Button contents
     */
    children?: any;
    renderPrefix?: ReactNode
    renderSuffix?: ReactNode

    /**
     * Optional click handler
     */
    onClick?(event: React.MouseEvent | React.KeyboardEvent): void;

    [propName: string]: any;
}


/**
 * Primary UI component for user interaction
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
                                                                      colorScheme = 'primary',
                                                                      variant = 'solid',
                                                                      type = 'button',
                                                                      disabled = false,
                                                                      rounded = false,
                                                                      loading = false,
                                                                      isBlock = false,
                                                                      renderPrefix = null,
                                                                      renderSuffix = null,
                                                                      size = 'md',
                                                                      tip = '',
                                                                      className = '',
                                                                      children,
                                                                      ...props
                                                                  }, ref) => {
    const isDisabled = (disabled || loading)
    const classes = [`${UIPrefix}btn`, variant + '-' + colorScheme, `size-${size}`, rounded && 'circle', isBlock && 'isBlock', isDisabled]

    return (
        <button
            type={type}
            disabled={isDisabled}
            className={classNames(classes, className)}
            ref={ref}
            {...props}
        >
            {loading && <Spinner size={'xs'} colorScheme={'white'}/>}
            {!loading && renderPrefix}
            <Row className={classNames((loading || renderPrefix) && 'ps-2', renderSuffix && 'pe-2')}>
                {children}
            </Row>
            {renderSuffix}
        </button>
    );
});
