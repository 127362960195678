import React from "react";
import UserAvatar from "./UserAvatar";
import {useSelector} from "react-redux";

export default function MyAvatar({...props}) {
    const {id,name,avatar_url} = useSelector((state) => state.user);

    return (
        <UserAvatar id={id} name={name} src={avatar_url} {...props}/>
    )
}