import {ReactComponent as FolderSvg} from "../../assets/file-icon/folder.svg";
import React from "react";
import { IconSizes } from ".";

interface Props extends React.HTMLAttributes<HTMLElement> {
    isExpand?: boolean
    size?: keyof typeof IconSizes

    [propName: string]: any
}

export default function FolderIcon({
                                       isExpand = false,
                                       size = 'md',
                                       style,
                                   }: Props) {
    let styles = {width: IconSizes[size], height: IconSizes[size]}

    return <FolderSvg style={{...styles,...style}}/>
}