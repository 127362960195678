import React from 'react';
import classNames from "classnames";
import {UIPrefix} from "../../../constants";

interface HeadingProps extends React.HTMLAttributes<HTMLElement> {
    children?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

    [propName: string]: any;
}

export const Heading = ({
                            children = '',
                            size = 'md',
                            ...props
                        }: HeadingProps) => {
    const cls = classNames(`${UIPrefix}heading`, `fs-${size}`, props.className)

    return (
        <div className={cls}>
            {children}
        </div>
    )
}
