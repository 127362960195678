import {Outlet} from "react-router-dom";
import DocumentSpaceActionRow from "../components/DocumentSpaceActionRow";
import FileList from "../components/FileList";
import React from "react";
import classNames from "classnames";

export function Docs() {
    return (
        <div className={classNames('')}>
            <div className={''}>
                <DocumentSpaceActionRow className={''}/>
            </div>
            <FileList/>
        </div>
    )
}