import React from "react";
import {IconButton} from "../../stories/components/Button/src";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import {BsFolderPlus} from "react-icons/bs";
import {Tooltip} from "../../stories/components/Tooltip/src";

export default function FileBtn() {
    return (
        <div className={'IconBtn'}>
            <Tooltip content={'Attachments'}>
                <IconButton variant={'ghost'} size={'sm'} colorScheme={'secondary'}>
                    <SvgIcon icon={BsFolderPlus}/>
                </IconButton>
            </Tooltip>
        </div>
    )
}