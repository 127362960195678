import React from "react"
import classNames from "classnames";
import {Text, TextProps} from "./Text";

export const ErrorText = ({
                              children = '',
                              size = 'sm',
                              ...props
                          }: TextProps) => {
    const cls = classNames('py-2', 'text-danger', props.className)
    return (
        <Text {...props} size={size} className={cls}>
            {children}
        </Text>
    )
}
