import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {FixedSizeList as List} from 'react-window';
import WorkspaceIcon from "../components/WorkspaceIcon";
import {Button, IconButton} from "../stories/components/Button/src";
import SimpleScroll from "../components/SimpleScroll";
import {useWindowSize} from "react-use";
import {SearchInput} from "../components/ui";
import {CreateWorkspaceModal, WorkspaceManageModal} from "../components/Modal";
import {useDispatch, useSelector} from "react-redux";
import {fetchWorkspaces, setSearch} from "../store/reducers/workspace";
import ContentLoader from "react-content-loader";
import {NavLink, Outlet} from "react-router-dom";
import {IoAddCircleOutline, IoSettingsOutline} from "react-icons/io5";

const MODAL = {
    MANAGE_WORKSPACE: 'MANAGE_WORKSPACE',
    CREATE_WORKSPACE: 'CREATE_WORKSPACE',
}

function Empty() {
    return (
        <div className={'flex flex-col gap-2 items-center py-16'}>
            <div className={'text-lg font-semibold'}>No results</div>
            <div>You may want to try adjusting your filters.</div>
        </div>
    )
}

function ListSkeleton() {
    const containerWidth = 300

    return (
        <div className={'px-6'}>
            {Array.from({length: 6}).map((_, index) => (
                <div key={index} className={'h-16 pl-4'}>
                    <ContentLoader viewBox={`0 0 ${containerWidth} 64`}
                                   height={64}
                                   width={containerWidth}>
                        <rect y="14" cx="10" cy="20" rx="5" width={120} height="12"/>
                        <rect y="38" cx="10" cy="20" rx="5" width="220" height="12"/>
                    </ContentLoader>
                </div>
            ))}
        </div>
    )
}

const ListItem = ({index, style}) => {
    const {data} = useSelector(state => state.workspace)
    const workspace = data[index]

    return (
        <div className={'px-4'} style={style}>
            <NavLink to={`/workspace/${workspace.id}`}
                     className={({isActive}) => `h-full w-full block flex items-center transition-colors rounded px-4 ${isActive ? 'bg-stone-200 dark:bg-stone-800' : 'hover:bg-stone-100 hover:dark:bg-stone-800'}`}>
                <div>
                    <div className={'font-semibold flex items-center gap-2'}>
                        <WorkspaceIcon/>{workspace.name}
                    </div>
                    <div className={'flex items-center gap-6 text-sm px-6 text-gray-500 leading-1 mt-1'}>
                        {workspace.member_count} members
                        <div className={''}>
                            {workspace.description}
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
}

export function Workspace() {
    const {height: windowHeight} = useWindowSize();
    const [showModal, setShowModal] = useState()
    const {data: workspaces, status, errMsg, pagination, search} = useSelector(state => state.workspace)
    const dispatch = useDispatch()

    const onSearchInputChange = (value) => {
        dispatch(setSearch(value))
        if (!value) {
            dispatch(fetchWorkspaces())
        }
    }

    const onSearch = () => {
        dispatch(fetchWorkspaces())
    }

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchWorkspaces())
        }
    }, [])

    return (
        <div className={'h-full w-full flex'}>
            <div className={classNames('w-96 border-r')}>
                <div className={''}>
                    <div
                        className={classNames(`px-4 pt-4 flex items-center justify-between`)}>
                        <div className={'font-semibold flex items-center pl-2'}>
                            Workspaces
                        </div>
                        <div className={'flex items-center gap-2'}>
                            <IconButton colorScheme={'secondary'} variant={'ghost'} size={'sm'}
                                        onClick={() => setShowModal(MODAL.MANAGE_WORKSPACE)}>
                                <IoSettingsOutline className={'text-lg'}/>
                            </IconButton>
                            <IconButton colorScheme={'secondary'} variant={'ghost'} size={'sm'}
                                        onClick={() => setShowModal(MODAL.CREATE_WORKSPACE)}>
                                <IoAddCircleOutline className={'text-xl'}/>
                            </IconButton>
                        </div>
                    </div>
                    <div className={'pt-4 pb-4 px-4'}>
                        <SearchInput
                            variant={'filled'}
                            value={search}
                            onSearch={onSearch}
                            onChangeValue={onSearchInputChange}
                            placeholder={'Search workspaces'}
                        />
                    </div>
                </div>
                {status === 'loading' && (<ListSkeleton/>)}
                {status === 'success' && (!workspaces.length ? (<Empty/>) : (
                    <SimpleScroll className={''}
                                  style={{height: 'calc(100vh - 134px)'}}>
                        {({scrollableNodeRef, contentNodeRef}) => (
                            <List
                                height={windowHeight - 64}
                                outerRef={scrollableNodeRef}
                                innerRef={contentNodeRef}
                                itemCount={workspaces.length}
                                itemSize={64}
                                className={classNames('simplebar-content-wrapper')}>
                                {ListItem}
                            </List>
                        )}
                    </SimpleScroll>
                ))}
            </div>
            <div className={'flex-1'}>
                <Outlet/>
            </div>

            {showModal === MODAL.CREATE_WORKSPACE && (
                <CreateWorkspaceModal
                    isOpen={true}
                    onRequestClose={() => setShowModal('')}
                />
            )}
            {showModal === MODAL.MANAGE_WORKSPACE && (
                <WorkspaceManageModal
                    isOpen={true}
                    onRequestClose={() => setShowModal('')}
                />
            )}
        </div>
    )
}