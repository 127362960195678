import React, {memo, useEffect} from "react";
import styles from "./../../stylesheets/components/WorkspaceWindow.module.scss";
import ChatMessageInputer from "../../components/ChatMessageInputer";
import ChatMessageItem from "../../components/ChatMessageItem";
import {useDispatch, useSelector} from "react-redux";
import {fetchWorkspaceInfo, fetchWorkspaceMessages} from "../../store/reducers/persistence";
import {useParams} from "react-router";
import ContentLoader from "react-content-loader";
import {LOAD_STATUS} from "../../constants";
import {IoWarning} from "react-icons/io5";

function MessageSkeleton() {
    return (
        <div>
            {Array.from({length: 3}).map((_, index) => (
                <div key={index} className={'py-4'}>
                    <ContentLoader
                        viewBox={'0 0 300 38'}
                        height={38}
                        width={300}
                        backgroundColor={'#ddd'}
                        foregroundColor={'#ccc'}
                    >
                        <rect x={0} rx="5" width={32} height={32}/>
                        <rect x={46} y={2} rx="3" width="100" height="12"/>
                        <rect x={46} y={26} rx="3" width="220" height="12"/>
                    </ContentLoader>
                </div>
            ))}
        </div>
    )
}

export function Message() {
    const dispatch = useDispatch()
    let {workspaceId} = useParams()

    const loadStatus = useSelector(state => state.loadStatus.messages[workspaceId])

    const onLoadMessages = async () => {
        await dispatch(fetchWorkspaceMessages({workspace_id: workspaceId}))
    }

    useEffect(() => {
        onLoadMessages()
    }, [workspaceId])

    return (
        <div className={styles.ChatMessagePane}>
            <div className={styles.Body}>
                <div className={styles.MessageList}>
                    {loadStatus === LOAD_STATUS.LOADING && <MessageSkeleton/>}
                    {loadStatus === LOAD_STATUS.ERROR && (
                        <div className={'flex items-center justify-center py-1'}>
                            <IoWarning className={'text-danger text-lg mr-2'}/>Something went wrong with loading
                        </div>
                    )}
                    {loadStatus === LOAD_STATUS.SUCCESS && (
                        <>
                            <ChatMessageItem/>
                        </>
                    )}
                </div>
            </div>

            <div className={styles.Footer}>
                <ChatMessageInputer/>
            </div>
        </div>
    )
}