import React, {HTMLAttributes, useEffect, useState} from 'react';
import classNames from "classnames";
import {SvgIcon} from "../../SvgIcon/src";
import {BsFillRecordCircleFill, BsCircle} from 'react-icons/bs';
import {Checkbox, CheckboxProps} from "../../Checkbox/src";

export interface RadioProps extends CheckboxProps {
    value: string | number;
    checked?: boolean;
    disabled?: boolean;
    children?: any;
    size?: 'sm' | 'md' | 'lg'

    [propName: string]: any;
}

export const Radio = ({
                          ...props
                      }: RadioProps) => {
    const cls = classNames('radio', props.className)
    return (
        <Checkbox {...props} className={cls} inputType={'radio'}/>
    )
}
