import classNames from "classnames";
import React, {useState} from "react";
import {UIPrefix} from "../../../constants";

export type AvatarSizes='2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export interface AvatarProps {
    /**
     * One http url
     */
    src?: string;

    /**
     * The name of the person in the avatar. - if src has loaded, the name will be used as the alt attribute of the img - If src is not loaded, the name will be used to create the initials
     */
    name?: string | number;
    alt?: string
    loading?: 'lazy' | 'eager'
    /**
     * The size of the Avatar
     */
    size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'custom'
    /**
     * Is fully rounded
     */
    rounded?: boolean;
    bgColor?: string;
    onClick?: () => void;

    [propName: string]: any;
}

export const Avatar = ({
                           src = '',
                           loading = 'eager',
                           name = '',
                           size = 'md',
                           rounded = false,
                           className,
                           bgColor: backgroundColor = 'green',
                           style,
                           ...props
                       }: AvatarProps) => {

    let cls = [`${UIPrefix}avatar`, `size-${size}`, className]

    // let sizeStyle = isNumberSize ? {width: size, height: size, borderRadius: size as number / 7} : {}
    const styles = {
        borderRadius: rounded && '9999px',
        ...style,
    }
    const onImageError = () => {

    }

    if (src !== '') {
        return (
            <img
                src={src}
                className={classNames(cls)}
                loading={loading}
                style={styles}
                onError={onImageError}
            />
        )
    } else {
        return (
            <div
                className={classNames(cls, 'text')}
                style={{
                    backgroundColor,
                    color: props.color,
                    ...styles
                }}>
                {name && (name + '')?.slice(0, 1).toLocaleUpperCase()}
            </div>
        )
    }
}
