import React from "react";
import {NavModal} from "../NavModal";
import {BsPerson, BsGlobe, BsBell, BsShieldCheck,BsMoon} from "react-icons/bs";
import {SvgIcon} from "../../../stories/components/SvgIcon/src";
import {AccountWindow, AppearanceWindow, LanguageRegionWindow, NotificationWindow, SecurityWindow} from "./Window";

const NavData = [
    {
        label: '',
        list: [
            {
                label: 'Account',
                icon: <SvgIcon icon={BsPerson} size={22}/>,
                window: <AccountWindow/>,
            },
            {
                label: 'Appearance',
                icon: <SvgIcon icon={BsMoon} size={17}/>,
                window: <AppearanceWindow/>,
            },
            {
                label: 'Language & Region',
                icon: <SvgIcon icon={BsGlobe} size={17}/>,
                window: <LanguageRegionWindow/>,
            },
            {
                label: 'Notifications',
                icon: <SvgIcon icon={BsBell} size={19}/>,
                window: <NotificationWindow/>,
            },
            {
                label: 'Security',
                icon: <SvgIcon icon={BsShieldCheck} size={18}/>,
                window: <SecurityWindow/>,
            },
        ]
    },
]

export const UserSettingModal = ({isOpen = false, onAfterOpen, onRequestClose, ...props}) => {
    return (
        <>
            <NavModal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                label={'Settings'}
                navData={NavData}
            />
        </>
    )
}