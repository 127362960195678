import React, {useEffect, useRef} from "react";
import lottie from 'lottie-web';
import LogoAnimation from "../../assets/logo-blink-lottie"
import styles from "./AppLoadFailureScreen.module.scss"
import Logo from "../Logo";

export default function AppLoadFailureScreen() {

    return (
        <div className={styles.AppLoadFailureScreen}>
            <Logo width={150} bg={'#eee'}/>
            <div className={'py-4 text-lg'}>
                Loading error, please
                <a href="" className={'ml-1 text-sky-600 hover:underline'}>try again</a>
            </div>
        </div>
    )
}