import {configureStore, createSlice} from '@reduxjs/toolkit'
import user from "./reducers/user";
import team from "./reducers/team";
import document from "./reducers/document";
import teamGroup from "./reducers/teamGroup";
import workspace from "./reducers/workspace";
import teammate from "./reducers/teammate";
import persistence from "./reducers/persistence";
import loadStatus from "./reducers/loadStatus";

const store = configureStore({
    reducer: {
        user,
        team,
        teamGroup,
        document,
        workspace,
        teammate,
        persistence,
        loadStatus,
    }
})

export default store