import React from "react";
import {TabBar, TabBarItem, TabPanel, TabPanels, Tabs} from "../../../../stories/components/Tabs/src";
import styles from "../index.module.scss"
import SimpleScroll from "../../../SimpleScroll";
import classNames from "classnames";
import {TeamGroupPanel, TeamMemberPanel, TeamRolePanel} from "../Panel";

export function MemberManageWindow() {

    return (
        <div className={classNames('')}>
            <Tabs>
                <TabBar className={classNames('px-6')}>
                    <TabBarItem>Members</TabBarItem>
                    <TabBarItem>Groups</TabBarItem>
                    <TabBarItem>Roles</TabBarItem>
                </TabBar>
                <TabPanels>
                    <TeamMemberPanel/>
                    <TeamGroupPanel/>
                    <TeamRolePanel/>
                </TabPanels>
            </Tabs>
        </div>
    )
}