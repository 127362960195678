import Tippy from '@tippyjs/react';
import React from "react";
import {SvgIcon} from "../../stories/components/SvgIcon/src";
import {BsQuestionCircle} from "react-icons/bs";

export default function HelpTipIcon({content = 'Help info',iconSize=14,iconColor='#666666'}) {
    return (
        <>
            <Tippy content={content} delay={[500, null]} animation={''} maxWidth={240}>
                <div className={'mx-1'} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <SvgIcon icon={BsQuestionCircle} size={iconSize} color={iconColor}/>
                </div>
            </Tippy>
        </>
    )
}